var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ori-sidebar" }, [
    _c(
      "div",
      { staticClass: "ori-sidebar-owners" },
      [
        _c("p", { staticClass: "ori-sidebar-owners-header" }, [
          _c("span", [_vm._v("Owners")]),
          _vm.canEdit && !_vm.disableActions
            ? _c(
                "span",
                {
                  staticClass: "ori-sidebar-owners-header-edit",
                  on: {
                    click: function($event) {
                      return _vm.$emit("editOwners")
                    }
                  }
                },
                [_vm._v("Edit")]
              )
            : _vm._e()
        ]),
        !_vm.visibleOwners.length
          ? _c("p", { staticClass: "ori-sidebar-owners-empty" }, [
              _vm._v(" No owners selected ")
            ])
          : _vm._e(),
        _vm._l(_vm.visibleOwners, function(owner) {
          return _c(
            "div",
            { key: owner.uuid, staticClass: "ori-sidebar-owners-owner" },
            [
              _c("Avatar", { attrs: { user: owner, size: "s" } }),
              _vm._v(" " + _vm._s(_vm.$umodel.full_name(owner)) + " ")
            ],
            1
          )
        })
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "ori-sidebar-overview" },
      _vm._l(_vm.overview, function(item, idx) {
        return _c(
          "p",
          {
            key: item,
            staticClass: "ori-sidebar-overview-item",
            on: {
              click: function($event) {
                return _vm.$emit("overviewClick", idx)
              }
            }
          },
          [_vm._v(" " + _vm._s(item) + " ")]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }