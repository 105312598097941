<template>
  <div class="portfolio-tab">
    <div class="portfolio-tab-top-wrapper">
      <div v-if="filters.length" class="portfolio-tab-top">
        <FilterDropdown
          v-for="filter in filters"
          :key="filter.id"
          :filter="filter"
          :selected="activeFilters[filter.id] || []"
          empty-text="Unknown"
          max-height="35vh"
          @submit="(selection) => selectFilter(filter.id, selection)"
        >
          <template #item="{ item }">
            <div
              v-if="filter.id === 'owners'"
              class="portfolio-tab-top-filter-item"
            >
              <Avatar v-if="item.key" :user="item.key" />
              <div class="portfolio-tab-top-filter-item-info">
                {{ item.value }}
                <p
                  v-if="item.key && item.key.role"
                  class="portfolio-tab-top-filter-item-info-role"
                >
                  {{ item.key.role }}
                </p>
              </div>
            </div>
            <div
              v-else-if="filter.id === 'parents'"
              class="portfolio-tab-top-filter-item"
            >
              <Avatar
                v-if="item.key"
                :user="{ avatar: item.key.image }"
                fallback-icon="globe"
              />
              {{ item.value }}
            </div>
          </template>
        </FilterDropdown>
      </div>
      <div v-else-if="consumptionLoading" class="portfolio-tab-top">
        <b-skeleton
          v-for="idx in 4"
          :key="`portfolio-tab-filter-loading-${idx}`"
          height="2.5rem"
          width="10rem"
        />
      </div>
      <Button
        v-if="canWriteOri"
        text="Export to CSV"
        :disabled="consumptionLoading"
        :loading="exportLoading"
        class="portfolio-tab-top-export"
        @click="handleExport"
      />
    </div>
    <p class="portfolio-tab-count">
      {{ consumptionLoading ? ' ' : `${paginatorDataCount} results` }}
    </p>
    <div
      :id="`portfolio-table-wrapper-${type}`"
      ref="portfoliotablewrapper"
      class="portfolio-tab-table"
      :style="{ ...maxHeightStyle, ...maxWidthStyle }"
      @wheel="() => handleScroll('wheel')"
      @scroll="() => handleScroll('scroll')"
    >
      <Table
        :key="tableKey"
        :items="ori"
        :headers="headers"
        :show-header="!!ori.length"
        :sortable="sortableHeaders"
        :default-sort-by="sorting.replace('-', '')"
        :default-sort-desc="sorting.startsWith('-')"
        :emit-sort="true"
        @sort="handleSort"
      >
        <template #item="{ header, item }">
          <PortfolioItem
            :header="header"
            :item="item"
            :type="type"
            @edit="handleItemEdit"
          />
        </template>
        <template #header="{ header }">
          <PortfolioColumnDropdown
            v-if="header.id === 'actions'"
            :options="availableHeaders"
            :selected="selectedHeaders"
            :loading="consumptionLoading"
            @select="handleColumnSelection"
          />
        </template>
      </Table>
      <div v-if="consumptionLoading" class="portfolio-tab-loading">
        <b-loading active :is-full-page="false" />
      </div>
      <div v-else-if="!ori.length" class="portfolio-tab-empty">
        <div class="portfolio-tab-empty-icon">
          <img
            src="@/assets/icons/bulb.svg"
            alt=""
            class="portfolio-tab-empty-icon-img"
          />
        </div>
        <p class="portfolio-tab-empty-header">{{ oriEmptyTitle }}</p>
        <p class="portfolio-tab-empty-sub">{{ oriEmptySub }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { PaginatorConsumer } from '@/mixins/PaginatorConsumer'
import Table from '@c/library/Table.vue'
import FilterDropdown from '@c/library/FilterDropdown.vue'
import Button from '@c/library/Button.vue'
import { mapGetters, mapActions } from 'vuex'
import PortfolioItem from './PortfolioItem.vue'
import { formatDate, relativeTime } from '@/util'
import { MaxHeightMixin } from '@/mixins/MaxHeightMixin'
import { MaxWidthMixin } from '@/mixins/MaxWidthMixin'
import Avatar from '@c/library/Avatar.vue'
import PortfolioColumnDropdown from './PortfolioColumnDropdown.vue'

const defaultHeaders = ['owners', 'date_updated', 'content']

export default {
  name: 'PortfolioTableTab',
  components: {
    PortfolioItem,
    FilterDropdown,
    Avatar,
    Table,
    Button,
    PortfolioColumnDropdown
  },
  mixins: [PaginatorConsumer, MaxHeightMixin, MaxWidthMixin],
  props: {
    type: {
      type: String,
      default: 'offerings',
      validator: (value) =>
        ['offerings', 'references', 'inspirations'].includes(value)
    }
  },
  data: () => ({
    query: '',
    filtersInitialized: false,
    activeFilters: {},
    aggregations: {},
    attributes: [],
    selectedHeaders: [...defaultHeaders],
    exportLoading: false,
    sorting: 'name',
    tableKey: 0,
    scrollType: ''
  }),
  computed: {
    ...mapGetters(['canWriteOri', 'currentWorkspaceMember']),
    canEdit() {
      return (
        this.canWriteOri ||
        (this.aggregations?.owners || []).some(
          (o) => this.currentWorkspaceMember?.uuid === o?.uuid
        )
      )
    },
    ori() {
      return this.paginatorData || []
    },
    typeName() {
      return {
        offerings: { s: 'offering', p: 'offerings' },
        references: { s: 'case', p: 'cases' },
        inspirations: { s: 'inspirational content', p: 'inspirational content' }
      }[this.type]
    },
    availableHeaders() {
      return [
        ...(this.type === 'offerings'
          ? [
              {
                id: 'parent',
                title: 'Parent',
                key: () => '',
                sortable: true
              }
            ]
          : []),
        {
          id: 'owners',
          title: 'Owners',
          key: () => '',
          sortable: true
        },
        {
          id: 'date_created',
          title: 'Created',
          key: (item) => formatDate(item.date_created),
          sortable: true
        },
        {
          id: 'date_updated',
          title: 'Last update',
          key: (item) => relativeTime(item.date_updated),
          sortable: true
        },
        ...(this.type === 'references'
          ? [
              {
                id: 'confidentiality',
                title: 'Confidentiality',
                key: (item) => item.confidentiality,
                sortable: true
              }
            ]
          : []),
        {
          id: 'content',
          title: 'Key content',
          key: (item) => item.resources?.length || 0,
          sortable: true
        },
        ...(this.type === 'offerings'
          ? [
              {
                id: 'references',
                title: 'Cases',
                key: (item) => item.references?.length || 0,
                sortable: true
              },
              {
                id: 'inspirations',
                title: 'Inspirations',
                key: (item) => item.inspirations?.length || 0,
                sortable: true
              }
            ]
          : [
              {
                id: 'offerings',
                title: 'Offerings',
                key: (item) => item.offerings?.length || 0,
                sortable: true
              }
            ]),
        ...(this.canEdit
          ? [
              {
                id: 'status',
                title: 'Status',
                key: () => '',
                sortable: true
              }
            ]
          : []),
        ...(this.attributes || []).map((a) => ({
          id: `attribute--${a.uuid}`,
          title: a.name,
          key: (item) =>
            item.attributes
              .find((attr) => attr.uuid === a.uuid)
              .values.map((v) => v.value)
              .join(', '),
          sortable: false
        }))
      ]
    },
    headers() {
      let headers = this.selectedHeaders.map((h) =>
        this.availableHeaders.find((ah) => ah.id === h)
      )
      headers.unshift({
        id: 'name',
        title: 'Name',
        key: (item) => item.name,
        sortable: true
      })
      headers.push({
        id: 'actions',
        title: '',
        key: () => '',
        sortable: false
      })
      return headers
    },
    sortableHeaders() {
      return this.headers.filter((h) => h.sortable).map((h) => h.id)
    },
    aggregationsKeys() {
      return Object.keys(this.aggregations)
    },
    filters() {
      return this.aggregationsKeys
        .filter((key) => !!this.aggregations[key].length)
        .map((key) => ({
          id: key,
          title: [].includes(key)
            ? {}[key]
            : key.replaceAll('_', ' ').capitalize(),
          options: this.aggregations[key].map((a) => ({
            key: a,
            value:
              key === 'owners'
                ? a
                  ? this.$umodel.full_name(a)
                  : 'None'
                : key === 'parents'
                ? a?.name || 'None'
                : key === 'statuses'
                ? a || 'No content'
                : a.is_attribute
                ? a.value
                : a,
            id:
              ['owners', 'parents'].includes(key) || a.is_attribute
                ? a?.uuid || null
                : a
          })),
          capitalize: ['statuses', 'confidentiality'].includes(key),
          multiselect: ![
            'has_offerings',
            'has_references',
            'has_inspirations',
            'confidentiality'
          ].includes(key)
        }))
    },
    queryFromRoute() {
      return this.$route.query?.query || ''
    },
    isSearch() {
      return !!this.queryFromRoute || Object.keys(this.activeFilters)?.length
    },
    oriEmptyTitle() {
      return this.isSearch
        ? `No ${this.typeName.p} found`
        : `No ${this.typeName.p} yet`
    },
    oriEmptySub() {
      return this.isSearch
        ? 'Please try rephrasing your search with keywords, or disabling some filters.'
        : `When your workspace admin adds ${this.typeName.p}, they will appear here.`
    }
  },
  watch: {
    ori(val, old) {
      if ((val.length || 0) > (old.length || 0))
        this.checkMaxHeightExtended(true)
    },
    canEdit(val, oldVal) {
      if (val && !oldVal && !this.selectedHeaders.includes('status'))
        this.addToSelected('status')
    },
    queryFromRoute(val) {
      if (val !== this.query) this.searchByQuery(val)
    },
    consumptionLoading(val, oldVal) {
      if (!val && oldVal) this.loadLocalColumnSelection()
    }
  },
  created() {
    if (this.type === 'offerings') {
      this.addToSelected('parent')
      this.addToSelected('references')
      this.addToSelected('inspirations')
    } else {
      this.addToSelected('offerings')
    }
    if (this.canEdit && !this.selectedHeaders.includes('status'))
      this.addToSelected('status')
  },
  methods: {
    ...mapActions([
      'getSearchOfferingsExtendedPaginator',
      'getSearchReferencesExtendedPaginator',
      'getSearchInspirationsExtendedPaginator',
      'searchOfferingsExtended',
      'searchReferencesExtended',
      'searchInspirationsExtended'
    ]),
    getMaxHeightElement() {
      return this.$refs.portfoliotablewrapper
    },
    getMaxWidthElement() {
      return this.$refs.portfoliotablewrapper
    },
    loadLocalColumnSelection() {
      let selectedHeaders = localStorage.getItem('portfolioHeaders')
      if (!selectedHeaders) return
      selectedHeaders = JSON.parse(selectedHeaders)
      if (
        Array.isArray(selectedHeaders[this.type]) &&
        selectedHeaders[this.type]?.length
      ) {
        const availableHeaderIds = this.availableHeaders.map((ah) => ah.id)
        this.selectedHeaders = selectedHeaders[this.type].filter((h) =>
          availableHeaderIds.includes(h)
        )
      }
    },
    async setupPaginatorConsumer() {
      try {
        this.loadingError = false
        this.consumptionLoading = true
        if (!this.filtersInitialized) {
          this.loadFiltersFromQuery()
          this.filtersInitialized = true
        }
        this.paginator = await this.getPaginator()
        this.pages = await this.getPages()
        await this.loadPage(true)
        this.paginatorDataCount = await this.getCount()
      } catch (e) {
        this.loadingError = true
        this.$console.warning('Failed to setup paginator consumer')
        this.$console.debug('Error loading portfolio', e)
        this.$toast.error(e, 'trying to load portfolio')
      } finally {
        this.consumptionLoading = false
      }
    },
    getPaginator() {
      const paginator = {
        offerings: 'getSearchOfferingsExtendedPaginator',
        references: 'getSearchReferencesExtendedPaginator',
        inspirations: 'getSearchInspirationsExtendedPaginator'
      }[this.type]
      return this[paginator]({
        workspace_id: this.$route.params.workspace_id,
        query: this.query,
        page_size: 20,
        callback: (res) => {
          this.aggregations = this.expandAggregations(res.data.aggregations)
        },
        sort_by: [this.sorting.replace('content', 'resources')],
        ...Object.keys(this.activeFilters).reduce(
          (acc, key) => ({
            ...acc,
            ...(this.activeFilters[key]?.length
              ? {
                  [key === 'owners'
                    ? 'owner_ids'
                    : key === 'parents'
                    ? 'parent_ids'
                    : key]: [
                    'has_offerings',
                    'has_references',
                    'has_inspirations',
                    'confidentiality'
                  ].includes(key)
                    ? this.activeFilters[key].join(',')
                    : this.activeFilters[key]
                }
              : {})
          }),
          {}
        )
      })
    },
    expandAggregations(aggregations) {
      const aggs = { ...aggregations }
      const attributes = aggs.attributes || []
      this.attributes = attributes
      if (aggs.attributes) delete aggs.attributes
      return {
        ...aggs,
        ...attributes.reduce(
          (acc, attr) => ({
            ...acc,
            [attr.name]: attr.values.map((v) => ({ ...v, is_attribute: true }))
          }),
          {}
        )
      }
    },
    addToSelected(id) {
      this.selectedHeaders = this.availableHeaders.reduce((acc, curr) => {
        if (curr.id === id) return [...acc, curr.id]
        return this.selectedHeaders.includes(curr.id) ? [...acc, curr.id] : acc
      }, [])
    },
    handleColumnSelection(selection) {
      this.selectedHeaders = selection
      let storedHeaders = localStorage.getItem('portfolioHeaders')
      if (storedHeaders) storedHeaders = JSON.parse(storedHeaders)
      else storedHeaders = {}
      localStorage.setItem(
        'portfolioHeaders',
        JSON.stringify({
          ...storedHeaders,
          [this.type]: selection
        })
      )
    },
    searchByQuery(query = undefined) {
      if (query !== undefined) this.query = query
      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          query: this.query || undefined
        }
      })
      this.resetPaginatorConsumer()
    },
    selectFilter(id, options) {
      this.activeFilters[id] = options
      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          ...this.activeFilters
        }
      })
      this.resetPaginatorConsumer()
    },
    handleSort(header) {
      this.sorting =
        header === this.sorting.replace('-', '')
          ? `-${this.sorting}`.replace('--', '')
          : header
      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          sorting: this.sorting
        }
      })
      this.tableKey++
      this.resetPaginatorConsumer()
    },
    loadFiltersFromQuery() {
      const query = { ...this.$route.query }
      this.query = query.query || ''
      delete query.query
      this.sorting = query.sorting || 'name'
      delete query.sorting
      delete query.view
      this.activeFilters = {
        ...Object.keys(query).reduce(
          (acc, key) => ({
            ...acc,
            [key]: Array.isArray(query[key]) ? query[key] : [query[key]]
          }),
          {}
        )
      }
    },
    handleItemEdit(item) {
      this.paginatorData = this.paginatorData.map((i) =>
        i.uuid === item.uuid ? item : i
      )
    },
    checkMaxHeightExtended(check_scroll = false) {
      this.checkMaxHeight()
      this.checkMaxWidth()
      if (check_scroll) setTimeout(() => this.handleScroll(), 100)
    },
    handleScroll(type = '') {
      if (!this.scrollType) this.scrollType = type
      if (this.scrollType !== type) return
      const el = document.getElementById(`portfolio-table-wrapper-${this.type}`)
      if (
        el.scrollHeight - el.offsetHeight - el.scrollTop <
          window.innerHeight / 6.66 &&
        !this.consumptionLoading &&
        !this.isDone
      ) {
        this.loadPage()
      }
    },
    async handleExport() {
      this.exportLoading = true
      try {
        const exportFunction = {
          offerings: 'searchOfferingsExtended',
          references: 'searchReferencesExtended',
          inspirations: 'searchInspirationsExtended'
        }[this.type]
        const data = await this[exportFunction]({
          workspace_id: this.$route.params.workspace_id,
          export_filetype: 'csv',
          query: this.query,
          ...Object.keys(this.activeFilters).reduce(
            (acc, key) => ({
              ...acc,
              ...(this.activeFilters[key]?.length
                ? {
                    [key === 'owners'
                      ? 'owner_ids'
                      : key === 'parents'
                      ? 'parent_ids'
                      : key]: [
                      'has_offerings',
                      'has_references',
                      'has_inspirations'
                    ].includes(key)
                      ? this.activeFilters[key].join(',')
                      : this.activeFilters[key]
                  }
                : {})
            }),
            {}
          )
        })
        const blob = new Blob([data], { type: 'text/csv' })
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        const m = this.$moment(new Date())
        a.download = `uman_${this.typeName.p.replace(' ', '_')}_${m.format(
          'DD-MM-YYYY-HH-mm-ss'
        )}.csv`
        a.click()
      } catch (e) {
        this.$console.debug(`${this.typeName.p} export error`, e)
        this.$toast.error(e, 'exporting data')
      } finally {
        this.exportLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.portfolio-tab {
  padding: 1.5rem 0;

  &-top {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 0.5rem;

    &-wrapper {
      display: flex;
      flex-flow: row nowrap;
      gap: 0.5rem;
      padding: 0 2.5rem 1.5rem;
    }

    &-export {
      margin-left: auto;
    }

    &-filter {
      &-item {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 0.5rem;

        &-info {
          display: flex;
          flex-flow: column nowrap;

          &-role {
            font-size: 0.85rem;
            color: #60666b;
          }
        }
      }
    }
  }

  &-table {
    overflow: auto;
    padding: 0 2.5rem;
  }

  &-loading {
    position: relative;
    min-height: 10rem;
  }

  &-count {
    color: #60666b;
    padding: 0 2.5rem 0.5rem;
    white-space: pre;
  }

  &-empty {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;

    &-icon {
      background: #e9ebed;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 4rem;
      width: 4rem;
      margin-bottom: 1rem;

      &-img {
        height: 3rem;
      }
    }

    &-header {
      font-size: 1.25rem;
    }

    &-sub {
      color: #60666b;
      width: min(40rem, 90vw);
      text-align: center;
    }
  }
}

::v-deep .b-skeleton {
  width: fit-content;
  margin: 0 !important;
}
</style>
