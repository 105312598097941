<template>
  <div
    ref="overviewcontent"
    class="overview-content"
    :class="{ 'has-sidebar': assignedOfferings.length }"
    :style="maxHeightStyle"
  >
    <div ref="overviewwrapper" class="overview-wrapper">
      <div class="overview-top">
        <ORIOverviewPills
          v-if="!offeringsLoading"
          :items="searchResults"
          :fetch-options="!isSearch"
          type="offering"
          class="overview-pills"
        />
        <div class="overview-top-btns">
          <Button
            v-if="!offeringsLoading && canWriteOri"
            text="Manage labels"
            type="white"
            @click="handleManageAttributes"
          />
          <Button
            v-if="
              !offeringsLoading && canWriteOri && (!offeringsEmpty || isSearch)
            "
            text="Add offering category"
            @click="openAddCategory"
          />
        </div>
      </div>
      <p
        v-if="isSearch && !offeringsLoading && searchCount"
        class="overview-count"
      >
        {{ searchCount }} results
      </p>
      <div v-if="offeringsLoading" class="overview-loading">
        <b-loading :is-full-page="false" active />
      </div>
      <div v-else-if="offeringsEmpty" class="overview-empty">
        <div class="overview-empty-icon">
          <img
            src="@/assets/icons/read.svg"
            alt=""
            class="overview-empty-icon-img"
          />
        </div>
        <p class="overview-empty-header">{{ offeringsEmptyTitle }}</p>
        <p class="overview-empty-sub">{{ offeringsEmptySub }}</p>
        <Button
          v-if="canWriteOri"
          text="Add offering category"
          class="overview-empty-btn"
          @click="openAddCategory"
        />
      </div>
      <div v-else class="overview">
        <div
          v-for="(category, idx) in isSearch ? [{}] : categories"
          :key="idx"
          class="overview-item"
        >
          <div
            class="overview-item-dropzone-anchor"
            :class="{ active: dragOverIdx === idx }"
          >
            <div
              v-if="dragging"
              :id="`overview-category-${idx}`"
              class="overview-item-dropzone"
            />
          </div>
          <OfferingCategory
            :offerings="isSearch ? searchResults : category.offerings"
            :category="category"
            :is-search="isSearch"
            :can-edit="canWriteOri"
            :draggable="!isSearch && canWriteOri"
            :dragging="dragging === category.uuid"
            @edit="openEditCategory(category)"
            @remove="openDeleteCategory(category)"
            @add="openAddOffering(category)"
            @reorder="(o) => handleOrderChange(o, idx)"
            @startDrag="() => (dragging = category.uuid)"
            @endDrag="(e) => endDrag(category, e)"
            @moving="handleMouseMove"
          />
        </div>
        <div
          class="overview-item-dropzone-anchor"
          :class="{ active: dragOverIdx === categories.length }"
        >
          <div
            v-if="dragging"
            :id="`overview-category-${categories.length}`"
            class="overview-item-dropzone"
          />
        </div>
      </div>
      <ORIInit
        :visible="showOfferingAdd"
        type="offering"
        :parent="{
          ...modalCategory,
          type: 'category'
        }"
        @close="closeAddOffering"
      />
      <OfferingCategoryModal
        :visible="showCategoryModal"
        @close="showCategoryModal = false"
        @submit="handleAddCategory"
      />
      <OfferingCategoryModal
        :visible="showEditCategoryModal"
        :category="modalCategory"
        @close="showEditCategoryModal = false"
        @submit="handleEditCategory"
      />
      <b-modal v-model="showDeleteCategoryModal">
        <RemoveORIModal
          :name="modalCategory.name"
          type="category"
          :uuid="modalCategory.uuid"
          @remove="handleDeleteCategory"
          @close="showDeleteCategoryModal = false"
        />
      </b-modal>
      <b-modal v-model="endDragLoading" :can-cancel="false">
        <div class="overview-item-drop-loading">
          <img
            src="@/assets/icons/ai.svg"
            alt=""
            class="overview-item-drop-loading-icon"
          />
          Changing position...
        </div>
      </b-modal>
      <b-modal v-model="showManageAttributes">
        <AttributesModal />
      </b-modal>
    </div>
    <AssignedORI
      v-if="assignedOfferings.length"
      :items="assignedOfferings"
      type="offerings"
      class="overview-sidebar"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import OfferingCategoryModal from './OfferingCategoryModal.vue'
import RemoveORIModal from '../ori/RemoveORIModal.vue'
import ORIOverviewPills from '../ori/ORIOverviewPills.vue'
import OfferingCategory from './OfferingCategory.vue'
import Button from '@c/library/Button.vue'
import AttributesModal from './AttributesModal.vue'
import ORIInit from '../ori/ORIInit.vue'
import AssignedORI from '../ori/AssignedORI.vue'
import { isEqual } from 'lodash'
import { MaxHeightMixin } from '@/mixins/MaxHeightMixin'

const contains2D = (mouse, el) => {
  return (
    mouse.x > el.x &&
    mouse.x < el.x + el.width &&
    mouse.y > el.y &&
    mouse.y < el.y + el.height
  )
}

export default {
  name: 'OfferingsOverview',
  components: {
    OfferingCategoryModal,
    RemoveORIModal,
    ORIOverviewPills,
    OfferingCategory,
    Button,
    AttributesModal,
    ORIInit,
    AssignedORI
  },
  mixins: [MaxHeightMixin],
  data: () => ({
    categories: [],
    searchResults: [],
    searchCount: 0,
    assignedOfferings: [],
    assignedOfferingsLoading: false,
    categoriesLoading: false,
    showCategoryModal: false,
    modalCategory: {},
    showOfferingAdd: false,
    showEditCategoryModal: false,
    showDeleteCategoryModal: false,
    addOfferingLoading: false,
    dragging: '',
    dragOverIdx: -1,
    scrollInterval: undefined,
    endDragLoading: false,
    showManageAttributes: false
  }),
  computed: {
    ...mapGetters(['currentWorkspaceMember', 'canWriteOri', 'attributes']),
    offeringsLoading() {
      return this.categoriesLoading || this.assignedOfferingsLoading
    },
    offeringsEmpty() {
      return (
        !this.offeringsLoading &&
        !(this.isSearch ? this.searchResults : this.categories).length
      )
    },
    offeringsEmptyTitle() {
      return this.isSearch ? 'No offerings found' : 'No offerings yet'
    },
    offeringsEmptySub() {
      return this.isSearch
        ? 'Please try rephrasing your search with keywords, or disabling some filters.'
        : this.canWriteOri
        ? "You should start by creating an offering category. Then, you'll be able to create offerings in that category. Click the button below to get started."
        : 'When your workspace admin adds offerings, they will appear here.'
    },
    queryFromRoute() {
      return this.$route.query?.query || ''
    },
    filterFromRoute() {
      return this.$route.query?.filter
        ? Array.isArray(this.$route.query?.filter)
          ? this.$route.query?.filter
          : [this.$route.query?.filter]
        : []
    },
    isSearch() {
      return !!this.queryFromRoute || !!this.filterFromRoute?.length
    }
  },
  watch: {
    queryFromRoute() {
      this.loadOfferings()
    },
    filterFromRoute(newVal, oldVal) {
      if (!isEqual(newVal, oldVal)) this.loadOfferings()
    },
    attributes() {
      if (this.filterFromRoute?.length) this.loadOfferings()
    }
  },
  created() {
    this.loadOfferings()
  },
  methods: {
    ...mapActions([
      'getOfferingCategories',
      'searchOfferings',
      'setOfferingPosition',
      'setOfferingCategoryPosition'
    ]),
    getMaxHeightElement() {
      return this.$refs.overviewcontent
    },
    async loadOfferings() {
      try {
        this.loadAssignedOfferings()
        if (this.filterFromRoute?.length && !this.attributes?.length) return
        this.categoriesLoading = true
        if (this.isSearch) {
          this.searchResults = await this.searchOfferings({
            workspace_id: this.$route.params.workspace_id,
            search: this.queryFromRoute,
            attribute_value_ids: this.filterFromRoute,
            callback: (res) => (this.searchCount = res.data?.count || 0)
          })
        } else {
          this.categories = await this.getOfferingCategories({
            workspace_id: this.$route.params.workspace_id
          })
        }
        this.$emit('load')
      } catch (e) {
        this.$console.debug('Offering retrieval failed', e)
        this.$toast.error(e, 'retrieving your offerings')
      } finally {
        this.categoriesLoading = false
      }
    },
    async loadAssignedOfferings() {
      try {
        this.assignedOfferingsLoading = true
        this.assignedOfferings = await this.searchOfferings({
          workspace_id: this.$route.params.workspace_id,
          search: this.queryFromRoute,
          owner_id: this.currentWorkspaceMember.uuid
        })
      } catch (e) {
        this.$console.debug('Assigned offerings retrieval failed', e)
      } finally {
        this.assignedOfferingsLoading = false
      }
    },
    openAddCategory() {
      this.showCategoryModal = true
    },
    handleAddCategory(category) {
      this.categories.push({
        ...category,
        offerings: []
      })
      this.showCategoryModal = false
    },
    openEditCategory(category) {
      this.modalCategory = category
      this.showEditCategoryModal = true
    },
    async handleEditCategory({ name, description }) {
      try {
        let category = this.modalCategory
        category = {
          ...category,
          name: name || category.name,
          description: description || category.description
        }
        this.categories = this.categories.map((c) =>
          c.uuid === category.uuid
            ? { ...category, offerings: this.modalCategory.offerings }
            : c
        )
        this.showEditCategoryModal = false
        this.$toast.success('Offering category successfully edited')
      } catch (e) {
        this.$console.debug('Offering category edit failed', e)
        this.$toast.error(e, 'editing your offering category')
      }
    },
    openDeleteCategory(category) {
      this.modalCategory = category
      this.showDeleteCategoryModal = true
    },
    async handleDeleteCategory() {
      this.categories = this.categories.filter(
        (c) => c.uuid !== this.modalCategory.uuid
      )
      this.showDeleteCategoryModal = false
      this.$toast.success('Offering category successfully deleted')
    },
    openAddOffering(category) {
      this.modalCategory = category
      this.showOfferingAdd = true
    },
    closeAddOffering() {
      this.showOfferingAdd = false
      this.modalCategory = {}
    },
    async handleAddOffering(offering) {
      const categoryIdx = this.categories.findIndex(
        (c) => c.uuid === this.modalCategory.uuid
      )
      this.categories[categoryIdx].offerings.push(offering)
      this.closeAddOffering()
    },
    async handleOrderChange({ to, from }, categoryIdx) {
      try {
        const items = this.categories[categoryIdx].offerings
        const item = items[from]
        await this.setOfferingPosition({
          workspace_id: this.$route.params.workspace_id,
          ori_id: item.uuid,
          position: to + 1
        })
        this.categories[categoryIdx].offerings = [
          ...items
            .slice(0, to > from ? to + 1 : to)
            .filter((t) => t.uuid !== item.uuid),
          item,
          ...items
            .slice(to > from ? to + 1 : to)
            .filter((t) => t.uuid !== item.uuid)
        ]
      } catch (e) {
        this.$console.debug('Offering position change failed', e)
        this.$toast.error(e, 'changing your offering position')
      }
    },
    handleMouseMove(e) {
      const coords = {
        x: e.pageX,
        y: e.pageY
      }

      let overlaps = false
      for (let index = 0; index < this.categories.length + 1; index++) {
        const dropzone = document
          .getElementById(`overview-category-${index}`)
          ?.getBoundingClientRect()
        if (dropzone && contains2D(coords, dropzone)) {
          this.dragOverIdx = index
          overlaps = true
          break
        }
      }
      if (!overlaps) this.dragOverIdx = -1
      const scrollTrigger = window.innerHeight / 5
      if (
        e.pageY < scrollTrigger ||
        e.pageY > window.innerHeight - scrollTrigger
      ) {
        if (!this.scrollInterval)
          this.scrollInterval = setInterval(() => {
            this.scroll(e.pageY < scrollTrigger ? -1 : 1)
          }, 100)
      } else {
        this.clearScrollInterval()
      }
    },
    clearScrollInterval() {
      clearInterval(this.scrollInterval)
      this.scrollInterval = undefined
    },
    async endDrag(category, doChange) {
      try {
        const oldIndex = this.categories.findIndex(
          (el) => el.uuid === category.uuid
        )
        this.endDragLoading = true
        if (
          doChange &&
          this.dragging &&
          this.dragOverIdx !== -1 &&
          oldIndex !== -1 &&
          this.dragOverIdx !== oldIndex + 1
        ) {
          await this.setOfferingCategoryPosition({
            workspace_id: this.$route.params.workspace_id,
            category_id: category.uuid,
            position: this.dragOverIdx + 1
          })
          const newCategories = [...this.categories]
          const oldIndex = newCategories.findIndex(
            (el) => el.uuid === category.uuid
          )
          const element = newCategories.splice(oldIndex, 1)[0]
          newCategories.splice(this.dragOverIdx, 0, element)
          this.categories = newCategories
        }
      } catch (e) {
        this.$console.debug('Offering category position change failed', e)
        this.$toast.error(e, 'changing your offering category position')
      } finally {
        this.dragging = ''
        this.dragOverIdx = -1
        this.clearScrollInterval()
        this.endDragLoading = false
      }
    },
    scroll(direction) {
      this.$refs.overviewcontent.scrollBy({
        top: direction * 50,
        behavior: 'smooth'
      })
    },
    handleManageAttributes() {
      this.showManageAttributes = true
    }
  }
}
</script>

<style scoped lang="scss">
.overview {
  display: flex;
  flex-flow: column nowrap;
  gap: 1.5rem;

  &-content {
    padding: 1.5rem 2.5rem 4rem !important;
    overflow-y: auto;
    position: relative;

    &.has-sidebar {
      display: grid;
      grid-template-columns: 1fr min(20rem, 17.5vw);
      gap: 3rem;
    }
  }

  &-sidebar {
    position: sticky;
    top: 0;
  }

  &-count {
    color: #60666b;
    padding: 1.5rem 0 0;
  }

  &-empty {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    padding-top: 2rem;

    &-icon {
      background: #e9ebed;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 4rem;
      width: 4rem;
      margin-bottom: 1rem;

      &-img {
        height: 3rem;
      }
    }

    &-header {
      font-size: 1.25rem;
    }

    &-sub {
      color: #60666b;
      width: min(40rem, 90vw);
      text-align: center;
    }

    &-btn {
      margin-top: 1rem;
    }
  }

  &-wrapper {
    position: relative;
  }

  &-header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 1rem;
    padding: 1.15rem 0;

    &-btns {
      margin-left: auto;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 0.5rem;
    }
  }

  &-top {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    &-btns {
      margin-left: auto;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: flex-end;
      gap: 0.5rem;
    }

    &:has(.overview-pills:empty):has(.overview-top-btns:empty) {
      display: none;
    }
  }

  &-loading {
    height: 10rem;
    position: relative;
  }

  &-item {
    &-dropzone {
      position: absolute;
      top: -5rem;
      bottom: -5rem;
      left: 0;
      right: 0;

      &-anchor {
        position: relative;
        background: transparent;
        transition: background 0.2s ease;
        width: 100%;
        height: 2px;
        border-radius: 999rem;
        margin: 0.5rem 0;

        &.active {
          background: $primary;
        }
      }
    }

    &-drop-loading {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      font-weight: 600;
      font-size: 1.25rem;
      background: white;
      padding: 1.75rem 1.25rem;
      margin-top: 30vh;
      border-radius: 16px;

      &-icon {
        animation: spin 1s linear infinite;
        height: 2rem;
      }
    }
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
</style>
