<template>
  <div class="conf-edit">
    <div class="conf-edit-content">
      <p v-if="title" class="conf-edit-title">{{ title }}</p>
      <SegmentedButton
        :text-left="buttonInfo.left.text"
        :icon-left="buttonInfo.left.icon"
        :text-right="buttonInfo.right.text"
        :icon-right="buttonInfo.right.icon"
        :type-right="buttonInfo.right.style"
        :type-left="buttonInfo.left.style"
        @click-left="() => handleClick(buttonInfo.left.value)"
        @click-right="() => handleClick(buttonInfo.right.value)"
      />
    </div>
    <p class="conf-edit-subtitle">
      <img
        src="@/assets/icons/check-warning.svg"
        alt=""
        class="conf-edit-subtitle-icon"
      />
      This setting is permanent and cannot be changed later. The generated key
      content will have the account information
      {{
        confidentiality === 'internal'
          ? 'hidden. Always review if the account information is correctly hidden in the generated documents.'
          : 'visible.'
      }}
    </p>
  </div>
</template>

<script>
import SegmentedButton from '@c/library/SegmentedButton.vue'

export default {
  name: 'ORIConfidentialityEdit',
  components: { SegmentedButton },
  props: {
    title: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: 'external',
      validator: (val) => ['internal', 'external'].includes(val)
    }
  },
  data: () => ({
    confidentiality: 'external'
  }),
  computed: {
    buttonInfo() {
      return {
        left: {
          value: 'internal',
          text: 'Internal',
          icon: 'eye-close',
          style: this.confidentiality === 'internal' ? 'light-solid' : 'white'
        },
        right: {
          value: 'external',
          text: 'External',
          icon: 'eye-open',
          style: this.confidentiality === 'external' ? 'light-solid' : 'white'
        }
      }
    }
  },
  watch: {
    value(val) {
      this.confidentiality = val
    }
  },
  created() {
    if (this.confidentiality) this.confidentiality = this.value
  },
  methods: {
    handleClick(val) {
      this.confidentiality = val
      this.$emit('submit', val)
    }
  }
}
</script>

<style lang="scss" scoped>
.conf-edit {
  display: flex;
  flex-flow: column nowrap;
  gap: 0.25rem;

  &-content {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 1rem;
  }

  &-title {
    font-weight: 600;
  }

  &-subtitle {
    color: #60666b;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.75rem;

    &-icon {
      height: 1.2rem;
    }
  }
}
</style>
