var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "confirm" }, [
    _c("div", { staticClass: "confirm-header" }, [
      _vm._v("Remove " + _vm._s(_vm.typeName))
    ]),
    _c("div", { staticClass: "confirm-body" }, [
      _c("p", { staticClass: "confirm-body-header" }, [
        _vm._v('Remove "' + _vm._s(_vm.name) + '"')
      ]),
      _c("p", { staticClass: "confirm-body-text" }, [
        _vm._v(
          " Are you sure you want to remove this " +
            _vm._s(_vm.typeName) +
            "? This action cannot be undone. "
        )
      ])
    ]),
    _c(
      "div",
      { staticClass: "confirm-btns" },
      [
        _c("Button", {
          attrs: { text: "Cancel", type: "white", disabled: _vm.loading },
          on: { click: _vm.handleCancel }
        }),
        _c("Button", {
          attrs: { text: "Remove " + _vm.typeName, loading: _vm.loading },
          on: { click: _vm.handleRemove }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }