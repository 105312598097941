var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ORILoading", {
    attrs: {
      loading: !!_vm.loading,
      header: _vm.loadingHeader,
      text: _vm.loadingText
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }