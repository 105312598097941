var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ori-pills" },
    [
      _vm._l(_vm.extraFilters, function(option) {
        return _c(
          "div",
          { key: option.uuid, staticClass: "ori-pills-section" },
          [
            _c("p", { staticClass: "ori-pills-section-title" }, [
              _vm._v(_vm._s(option.name))
            ]),
            _vm._l(option.values, function(value) {
              return _c("Button", {
                key: Array.isArray(value.uuid) ? value.uuid.join() : value.uuid,
                attrs: {
                  text: value.value,
                  type: (Array.isArray(value.uuid)
                  ? value.uuid.every(function(u) {
                      return _vm.selected.includes(u)
                    })
                  : _vm.selected.includes(value.uuid))
                    ? "light-solid"
                    : "white",
                  size: "xs"
                },
                on: {
                  click: function($event) {
                    return _vm.handleExtraPillSelect(option, value)
                  }
                }
              })
            })
          ],
          2
        )
      }),
      _vm._l(_vm.sortedOptions, function(option) {
        return _c(
          "div",
          { key: option.uuid, staticClass: "ori-pills-section" },
          [
            _c("p", { staticClass: "ori-pills-section-title" }, [
              _vm._v(_vm._s(option.name))
            ]),
            _vm._l(option.values, function(value) {
              return _c("Button", {
                key: value.uuid,
                attrs: {
                  text: value.value,
                  type: _vm.selected.includes(value.uuid)
                    ? "light-solid"
                    : "white",
                  size: "xs"
                },
                on: {
                  click: function($event) {
                    return _vm.handlePillSelect(value)
                  }
                }
              })
            })
          ],
          2
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }