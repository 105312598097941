<template>
  <div class="portfolio-resources">
    <b-modal v-if="showModal" v-model="showModal">
      <div class="portfolio-resources-list-wrapper">
        <div class="portfolio-resources-list-header">
          <p class="portfolio-resources-list-header-title">{{ title }}</p>
          <Button
            icon="close"
            type="grey"
            size="xs"
            @click="() => (showModal = false)"
          />
        </div>
        <div class="portfolio-resources-list">
          <component
            :is="cardComponent"
            v-for="(i, idx) in items"
            :key="`inspect-modal-${i.uuid}`"
            v-bind="cardProps(i, idx)"
          />
        </div>
      </div>
    </b-modal>
    <Button
      v-if="count !== -1"
      :text="`${count} ${header.id === 'content' ? 'files' : 'items'}`"
      type="white"
      class="portfolio-resources-trigger"
      :class="{ 'is-disabled': !count }"
      @click="() => (showModal = true)"
    />
  </div>
</template>

<script>
import Button from '@c/library/Button.vue'
import ResourceCard from '@c/shared/molecules/object-visualisations/resource/ResourceCard.vue'
import OfferingCard from '../offerings/OfferingCard.vue'
import ReferenceCard from '../references/ReferenceCard.vue'
import InspirationCard from '../inspirations/InspirationCard.vue'

export default {
  name: 'PortfolioItemResources',
  components: { Button },
  props: {
    item: {
      type: Object,
      required: true
    },
    header: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    showModal: false
  }),
  computed: {
    count() {
      return this.header.key(this.item)
    },
    title() {
      return {
        content: 'Key content',
        offerings: 'Linked offerings',
        references: 'Linked cases',
        inspirations: 'Linked inspirational content'
      }[this.header.id]
    },
    items() {
      const key =
        {
          content: 'resources'
        }[this.header.id] || this.header.id
      return this.item[key]
    },
    cardComponent() {
      return {
        content: ResourceCard,
        offerings: OfferingCard,
        references: ReferenceCard,
        inspirations: InspirationCard
      }[this.header.id]
    }
  },
  methods: {
    cardProps(item, idx) {
      const oriProps = {
        clickable: true,
        openExternal: true
      }
      switch (this.header.id) {
        case 'offerings':
          return {
            offering: item,
            ...oriProps
          }
        case 'inspirations':
          return {
            inspiration: item,
            ...oriProps
          }
        case 'references':
          return {
            reference: item,
            ...oriProps
          }
        case 'content':
          return {
            resource: item,
            rank: idx + 1,
            location: `Portfolio table content inspect`,
            clickable: true,
            hasPreviewHover: ['video'].includes(item.type),
            highlight: item.highlight
          }
        default:
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.portfolio-resources {
  &-trigger {
    &.is-disabled {
      pointer-events: none;
    }
  }

  &-list {
    padding: 1.75rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    max-height: 60vh;
    overflow-y: auto;

    &-wrapper {
      background: white;
      border-radius: 8px;
    }

    &-header {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      padding: 1.75rem;
      border-bottom: 1px solid rgba(#000, 0.08);

      &-title {
        font-size: 1.25rem;
        font-weight: 700;
      }
    }
  }
}
</style>
