var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.progress < 100 || !_vm.doneIcon,
          expression: "progress < 100 || !doneIcon"
        }
      ],
      staticClass: "progress-circle",
      class:
        ((_obj = {}),
        (_obj[_vm.size] = true),
        (_obj["below-half"] = _vm.progress < 50),
        _obj),
      style: "--bar-color: " + _vm.color + "; --progress: " + _vm.progress + ";"
    }),
    _vm.doneIcon
      ? _c("div", { staticClass: "progress-circle-done" }, [
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.progress === 100,
                expression: "progress === 100"
              }
            ],
            staticClass: "progress-circle-done-icon",
            attrs: {
              src: require("@/assets/icons/" + _vm.doneIcon + ".svg"),
              alt: ""
            }
          })
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }