<template>
  <div class="addcontent-wrapper">
    <div class="addcontent">
      <p class="addcontent-header">
        <span>Add content to {{ ori.name }}</span>
        <span class="addcontent-header-sub"
          ><img
            src="@/assets/icons/check-circle-filled.svg"
            alt=""
            class="addcontent-header-icon"
            :class="{ filled: selectedUUIDs.length }"
          />{{ selectedUUIDs.length }} items selected</span
        >
      </p>
      <SearchBar
        v-model="searchQuery"
        class="addcontent-searchbar"
        placeholder="Search..."
        :rounded="false"
        @keyup.native.enter.exact="search"
        @historyItemClick="search"
        @autocompleteItemClick="search"
        @clear="handleClear"
      />
      <div class="addcontent-pills">
        <Tag
          v-for="pill in pills"
          :key="pill.key"
          :text="pill.text"
          :type="pill.key === selectedPill ? 'light' : 'white'"
          class="addcontent-pills-pill"
          @click.native="handlePillSelect(pill.key)"
        />
      </div>
      <div
        v-infinite-scroll="handleInfiniteScroll"
        class="addcontent-list"
        :infinite-scroll-disabled="loadingOrDone"
        infinite-scroll-distance="100"
        infinite-scroll-throttle-delay="500"
      >
        <SelectableResource
          v-for="(resource, idx) in resources"
          :key="resource.uuid"
          :resource="resource"
          :rank="idx + 1"
          :show-slide-collect="false"
          :title-clickable="false"
          :location="`${type} resource management modal`"
          class="addcontent-list-item"
          :selected="selectedUUIDs.includes(resource.uuid)"
          @click.native="handleResourceClick(resource)"
        />
        <div v-if="consumptionLoading" class="addcontent-loading">
          <b-loading active :is-full-page="false" />
        </div>
      </div>
    </div>
    <div class="addcontent-btns">
      <Button text="Cancel" type="white" @click="$emit('close')" />
      <Button
        text="Add resources"
        :disabled="selectedUUIDs.length === 0"
        :loading="loading"
        @click="handleSubmit"
      />
    </div>
  </div>
</template>

<script>
import { PaginatorConsumer } from '@/mixins/PaginatorConsumer'
import Button from '@c/library/Button.vue'
import Tag from '@c/library/Tag.vue'
import { Mimetypes, typeToMimetype } from '@c/mimetypes'
import { ORDERING_MAP, SORT_TYPES_ENUM } from '@c/models/SortingFiltering'
import SearchBar from '@c/shared/molecules/inputs/SearchBar.vue'
import SelectableResource from '@c/shared/molecules/object-visualisations/resource/SelectableResource.vue'
import { SearchFacet } from 'umanai-vuex/src/store/modules/resources.js'
import { mapActions } from 'vuex'

export default {
  name: 'AddResourceToORIModal',
  components: { Button, SearchBar, SelectableResource, Tag },
  mixins: [PaginatorConsumer],
  props: {
    ori: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  data: () => ({
    searchSessionStarted: false,
    searchQuery: '',
    selectedResources: [],
    selectedPill: 'all',
    loading: false
  }),
  computed: {
    selectedUUIDs() {
      return this.selectedResources.map((r) => r.uuid)
    },
    loadingOrDone() {
      return this.consumptionLoading || this.isDone
    },
    resources() {
      return [
        ...this.selectedResources.filter((r) =>
          (this.paginatorData || []).every((p) => p.uuid !== r.uuid)
        ),
        ...(this.paginatorData || [])
      ]
    },
    pills() {
      return [
        {
          key: 'all',
          text: 'All',
          filter: {
            mimetypes: []
          }
        },
        {
          key: 'presentation',
          text: 'Presentations',
          filter: {
            mimetypes: [Mimetypes.PPTX, Mimetypes.GOOGLE_SLIDES, Mimetypes.PPT]
          }
        },
        {
          key: 'document',
          text: 'Documents',
          filter: {
            mimetypes: [Mimetypes.DOC, Mimetypes.GOOGLE_DOCS, Mimetypes.PDF]
          }
        },
        {
          key: 'sheet',
          text: 'Spreadsheets',
          filter: {
            mimetypes: [Mimetypes.XLSX, Mimetypes.XLS, Mimetypes.GOOGLE_SHEETS]
          }
        },
        {
          key: 'video',
          text: 'Videos',
          filter: {
            mimetypes: [Mimetypes.VIDEO]
          }
        }
      ]
    }
  },
  mounted() {
    this.search()
  },
  methods: {
    ...mapActions([
      'getSearchResourcesPaginator',
      'editOfferingResourceLink',
      'editReferenceResourceLink',
      'editInspirationResourceLink'
    ]),
    async getPaginator() {
      return await this.getSearchResourcesPaginator({
        data: {
          sort_by: ORDERING_MAP[SORT_TYPES_ENUM.RELEVANCE],
          query: this.searchQuery,
          mimetypes: this.pills
            .find((p) => p.key === this.selectedPill)
            .filter.mimetypes.map((m) => typeToMimetype[m])
        },
        extraParams: {
          act_as: this.$route.query.act_as,
          page_size: 10,
          workspace: this.$route.params.workspace_id,
          included_facets: [
            SearchFacet.types,
            SearchFacet.content_classes,
            SearchFacet.topics,
            SearchFacet.organisations,
            SearchFacet.industries,
            SearchFacet.created_dates,
            SearchFacet.modified_dates,
            SearchFacet.languages,
            SearchFacet.authors,
            SearchFacet.mimetypes,
            SearchFacet.labels,
            SearchFacet.bookmarks,
            SearchFacet.editors,
            SearchFacet.file_locations
          ].join(' ')
        },
        resourceType: 'all'
      })
    },
    async setupPaginatorConsumer() {
      if (!this.searchSessionStarted) return
      try {
        this.loadingError = false
        if (this.consumptionLoading) return
        this.consumptionLoading = true
        this.paginator = await this.getPaginator()
        this.pages = await this.getPages()
        await this.loadPage(true)
        this.paginatorDataCount = await this.getCount()
        this.consumptionLoading = false
      } catch (e) {
        this.$console.debug('Error loading search results', e)
        this.$toast.error(e, 'loading search results')
        this.consumptionLoading = false
        this.searchSessionStarted = false
      }
    },
    handleInfiniteScroll() {
      if (!this.searchSessionStarted) return
      this.loadPage()
    },
    handleClear() {
      this.searchQuery = ''
      this.search()
    },
    search() {
      if (!this.searchSessionStarted) {
        this.searchSessionStarted = true
        this.setupPaginatorConsumer()
      } else {
        this.resetPaginatorConsumer()
      }
    },
    handleResourceClick(resource) {
      if (this.selectedUUIDs.includes(resource.uuid)) {
        this.selectedResources = this.selectedResources.filter(
          (r) => r.uuid !== resource.uuid
        )
      } else {
        this.selectedResources.push(resource)
      }
    },
    handlePillSelect(key) {
      this.selectedPill = key
      this.resetPaginatorConsumer()
    },
    async handleSubmit() {
      try {
        this.loading = true
        const submitFunction = {
          offering: this.editOfferingResourceLink,
          reference: this.editReferenceResourceLink,
          inspiration: this.editInspirationResourceLink
        }[this.type]
        await Promise.all(
          this.selectedResources.map((resource) =>
            submitFunction({
              workspace_id: this.$route.params.workspace_id,
              ori_id: this.ori.uuid,
              resource_id: resource.uuid,
              act_as: this.$route.query.act_as
            })
          )
        )
        this.$toast.success(
          `Resource${
            this.selectedResources.length === 1 ? '' : 's'
          } successfully linked`
        )
        this.$emit('add', this.selectedResources)
      } catch (e) {
        this.$console.debug('Linking resource(s) failed', e)
        this.$toast.error(e, 'linking your resource(s)')
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.addcontent {
  padding: 2rem 0 0;
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  align-items: center;
  margin: unset;

  &-wrapper {
    background: white;
    border-radius: 8px;
  }

  &-pills {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-flow: row nowrap;
    gap: 0.5rem;
    align-items: center;
    overflow-x: auto;
    scrollbar-width: none;
    padding: 0 5rem;

    &::-webkit-scrollbar {
      display: none;
    }

    &-pill {
      cursor: pointer;
      margin: 0 !important;
    }
  }

  &-header {
    font-weight: 700;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    gap: 0.5rem;

    &-sub {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 0.5rem;
      color: #60666b;
      font-weight: 400;
    }

    &-icon {
      height: 1.2rem;
      transition: all 0.4s ease;
      &.filled {
        filter: brightness(0) saturate(100%) invert(25%) sepia(95%)
          saturate(2915%) hue-rotate(217deg) brightness(97%) contrast(103%);
      }
    }
  }

  &-searchbar {
    width: 85%;
  }

  &-list {
    max-height: 60vh;
    overflow: auto;
    padding: 0 5rem;
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    gap: 1rem;

    &-item {
      padding: 0.5rem;
      border-radius: 8px;
      cursor: pointer;

      &:hover {
        box-shadow: 0px 4px 8px 0px rgba(#000, 10%);
      }
    }
  }

  &-loading {
    height: 5rem;
    position: relative;

    &-icon {
      filter: brightness(0.1);
    }
  }

  &-btns {
    display: flex;
    justify-content: flex-end;
    padding: 1rem 0.5rem;
    gap: 1rem;
  }
}
</style>
