<template>
  <b-modal :active="visible" @close="() => $emit('close')">
    <div class="category">
      <div class="category-wrapper">
        <p class="category-header">
          {{ category ? 'Edit' : 'Add' }} offering category
        </p>
        <TextInput
          v-model="name"
          placeholder="Enter category name"
          title="Name"
          :max-length="50"
        />
        <TextArea
          v-model="description"
          placeholder="Enter category description"
          title="Description"
          min-height="350px"
        />
      </div>
      <div class="category-btns">
        <Button text="Cancel" type="white" @click="$emit('close')" />
        <Button
          text="Save category"
          :disabled="submitDisabled"
          :loading="loading"
          @click="handleSubmit"
        />
      </div>
    </div>
  </b-modal>
</template>

<script>
import Button from '@c/library/Button.vue'
import TextArea from '@c/library/TextArea.vue'
import TextInput from '@c/library/TextInput.vue'
import { mapActions } from 'vuex'

export default {
  name: 'OfferingCategoryModal',
  components: {
    Button,
    TextInput,
    TextArea
  },
  props: {
    category: {
      type: Object,
      default: () => undefined
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    name: '',
    description: '',
    loading: false
  }),
  computed: {
    submitDisabled() {
      return (
        !this.name ||
        (!!this.category &&
          this.category.name === this.name &&
          this.category.description === this.description)
      )
    }
  },
  watch: {
    visible(val) {
      if (!val) {
        this.name = ''
        this.description = ''
      } else {
        this.initState()
      }
    }
  },
  created() {
    this.initState()
  },
  methods: {
    ...mapActions(['createOfferingCategory', 'editOfferingCategory']),
    initState() {
      if (this.category) {
        this.name = this.category.name || ''
        this.description = this.category.description || ''
      }
    },
    async handleSubmit() {
      try {
        this.loading = true
        const editFunction = this.category
          ? this.editOfferingCategory
          : this.createOfferingCategory
        const category = await editFunction({
          workspace_id: this.$route.params.workspace_id,
          ...(this.category ? { category_id: this.category.uuid } : {}),
          ...(!this.category || this.category.name !== this.name
            ? { name: this.name }
            : {}),
          ...(!this.category || this.category.description !== this.description
            ? { description: this.description }
            : {})
        })
        this.$toast.success(
          `Offering category ${this.category ? 'edited' : 'created'}`,
          `Your offering category has been succesfully ${
            this.category ? 'edited' : 'created'
          }.`
        )
        this.$emit('submit', category)
      } catch (e) {
        this.$console.debug(
          `Offering category ${this.category ? 'editing' : 'creation'} failed`,
          e
        )
        this.$toast.error(
          e,
          `${this.category ? 'editing' : 'creating'} your offering category`
        )
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.category {
  background: white;
  border-radius: 8px;
  width: min(40rem, 95vw);

  &-wrapper {
    padding: 2.25rem;
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;
  }

  &-header {
    font-weight: 700;
    font-size: 1.5rem;
  }

  &-btns {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    padding: 1.5rem 2.25rem;
    border-top: 1px solid rgba(#000, 8%);
  }
}

::v-deep .modal-content {
  width: unset !important;
}
</style>
