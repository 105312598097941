<template>
  <div class="ori-props-bar" :class="{ 'contents-full': contentLength > 3 }">
    <div v-if="hasOrganisation" class="ori-props-bar-org">
      <Avatar
        :user="{
          avatar: organisation.icon || organisation.logo,
          username: organisation.name
        }"
        size="l"
      />
      <div class="ori-props-bar-info">
        <span class="ori-props-bar-info-title">{{ organisationTitle }}</span>
        <b-tooltip
          :label="shortenedOrganisationDomain"
          position="is-top"
          type="is-dark"
          :active="!!organisation.domain"
        >
          <span
            class="ori-props-bar-info-name"
            :class="{ unclickable: !organisation.domain }"
            @click="openExternal(organisation.domain)"
            >{{ organisation.name }}</span
          >
        </b-tooltip>
      </div>
    </div>
    <div v-if="hasUrl" class="ori-props-bar-info">
      <span class="ori-props-bar-info-title">{{ urlTitle }}</span>
      <b-tooltip :label="shortenedUrl" position="is-top" type="is-dark">
        <span class="ori-props-bar-info-name" @click="openExternal(url)"
          >Visit website</span
        >
      </b-tooltip>
    </div>
    <div v-if="hasConfidentiality" class="ori-props-bar-info">
      <span class="ori-props-bar-info-title">{{ confidentialityTitle }}</span>
      <Tag
        :text="confidentiality.capitalize()"
        :type="confidentialityButtonType"
        :icon="confidentialityIcon"
        :icon-left="true"
        size="xxs"
      />
    </div>
  </div>
</template>

<script>
import { isEmpty } from 'lodash'
import Avatar from '@c/library/Avatar.vue'
import Tag from '@c/library/Tag.vue'

export default {
  name: 'ORIPropsBar',
  components: { Avatar, Tag },
  props: {
    account: {
      type: Object,
      default: () => ({})
    },
    url: {
      type: String,
      default: ''
    },
    confidentiality: {
      type: String,
      default: ''
    },
    titleMapping: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    hasOrganisation() {
      return !isEmpty(this.account?.organisation || {})
    },
    organisation() {
      return this.account?.organisation || {}
    },
    shortenedOrganisationDomain() {
      return this.hasOrganisation
        ? this.organisation.domain.replace(/(^\w+:|^)\/\//, '').split('/')[0]
        : ''
    },
    organisationTitle() {
      return this.titleMapping?.organisation || 'Account'
    },
    hasUrl() {
      return !!this.url
    },
    shortenedUrl() {
      return this.hasUrl
        ? this.url.replace(/(^\w+:|^)\/\//, '').split('/')[0]
        : ''
    },
    urlTitle() {
      return this.titleMapping?.url || 'Source'
    },
    hasConfidentiality() {
      return !!this.confidentiality
    },
    confidentialityTitle() {
      return this.titleMapping?.confidentiality || 'Confidentiality'
    },
    confidentialityIcon() {
      return {
        internal: 'eye-close',
        external: 'eye-open'
      }[this.confidentiality]
    },
    confidentialityButtonType() {
      return {
        internal: 'black',
        external: 'light-solid'
      }[this.confidentiality]
    },
    contentLength() {
      return ['organisation', 'url', 'confidentiality'].reduce((acc, key) => {
        if (this[`has${key.capitalize()}`]) acc++
        return acc
      }, 0)
    }
  },
  methods: {
    openExternal(url) {
      if (!url) {
        this.$toast.danger(`Link couldn't be opened`)
        return
      }
      if (!url.startsWith('http')) url = `https://${url}`.replace(/\/$/, '')
      window.open(url, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.ori-props-bar {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  gap: 1.75rem;

  &.contents-full {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  &-org {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 1rem;
  }

  &-info {
    display: flex;
    flex-flow: column nowrap;
    gap: 0.15rem;
    width: fit-content;

    &-title {
      color: #60666b;
    }

    &-name {
      color: $primary;
      cursor: pointer;
      width: fit-content;

      &.unclickable {
        cursor: default;
      }
    }
  }
}

::v-deep .tooltip-trigger {
  width: fit-content;
}
</style>
