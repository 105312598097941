<template>
  <b-modal v-if="loading" active :can-cancel="false">
    <div class="ori-loading">
      <img
        src="@/assets/ori_draft_loading.svg"
        alt=""
        class="ori-loading-image"
      />
      <div class="ori-loading-content">
        <p class="ori-loading-header">{{ header }}</p>
        <p class="ori-loading-text">{{ text }}</p>
        <div class="ori-loading-loader">
          <b-loading :is-full-page="false" active />
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'ORILoading',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    header: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.ori-loading {
  margin-top: 10vh;
  width: min(25rem, 90vw);
  background: white;
  border-radius: 6px;
  overflow: hidden;

  &-icon {
    width: 100%;
  }

  &-content {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    gap: 1rem;
    padding: 2.25rem;
  }

  &-header {
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
  }

  &-text {
    text-align: center;
    color: #60666b;
  }

  &-loader {
    position: relative;
    min-height: 5rem;
    min-width: 5rem;
  }
}

::v-deep .modal-content {
  width: unset !important;
}
</style>
