var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ori-edit" },
    _vm._l(_vm.visibleKeys, function(key) {
      return _c(
        "div",
        { key: key, staticClass: "ori-edit-item" },
        [
          key === "name"
            ? _c("TextInput", {
                attrs: {
                  title: _vm.names[key].capitalize(),
                  placeholder: _vm.placeholders[key]
                },
                model: {
                  value: _vm.name,
                  callback: function($$v) {
                    _vm.name = $$v
                  },
                  expression: "name"
                }
              })
            : key === "custom_id"
            ? _c("ORICustomIdEdit", {
                model: {
                  value: _vm.custom_id,
                  callback: function($$v) {
                    _vm.custom_id = $$v
                  },
                  expression: "custom_id"
                }
              })
            : key === "url"
            ? _c("TextInput", {
                attrs: {
                  title: _vm.names[key].capitalize(),
                  placeholder: _vm.placeholders[key]
                },
                model: {
                  value: _vm.url,
                  callback: function($$v) {
                    _vm.url = $$v
                  },
                  expression: "url"
                }
              })
            : key === "image"
            ? _c("AvatarUpload", {
                attrs: { image: _vm.image, width: 512, "max-size": 5 },
                on: {
                  input: function(url, img) {
                    _vm.image = url
                    _vm.imageBlob = img
                  }
                }
              })
            : key === "owners"
            ? _c("ORIOwnersEdit", {
                attrs: { title: "Owners", item: _vm.item },
                on: {
                  submit: function($event) {
                    _vm.owners = $event
                  }
                }
              })
            : key === "parent"
            ? _c("OfferingParentEdit", {
                key:
                  _vm.parentType === "offering"
                    ? _vm.parent.uuid
                    : _vm.category.uuid,
                attrs: {
                  "offering-id": _vm.item.uuid,
                  "parent-id":
                    _vm.parentType === "offering" ? _vm.parent.uuid : "",
                  category:
                    _vm.parentType === "category" ? _vm.category : undefined
                },
                on: { select: _vm.handleParentSelect }
              })
            : _vm._e()
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }