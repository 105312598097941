<template>
  <b-modal
    v-if="visible"
    :active="visible"
    :can-cancel="['escape', 'outside']"
    @close="() => $emit('close')"
  >
    <div class="upload-resource">
      <div class="upload-resource-header">
        <p class="upload-resource-header-title">{{ header }}</p>
        <Button
          icon="close"
          type="grey"
          size="xs"
          @click="() => $emit('close')"
        />
      </div>
      <div class="upload-resource-content">
        <UploadDropzone
          ref="uploaddropzone"
          :disabled="loading"
          min-height="40vh"
          :filetypes="[
            'ppt',
            'pptx',
            'doc',
            'docx',
            'xls',
            'xlsx',
            'pdf',
            'mp4',
            'mov',
            'qt'
          ]"
          :max-size="maxSize"
          :show-delete="(f) => !uploadingNames.includes(f.name)"
          @upload="handleUpload"
          @remove="removeFile"
        >
          <template #action="{ file }">
            <ProgressCircle
              v-if="uploadingNames.includes(file.name)"
              :progress="uploading[file.name]"
              done-icon="check-circle"
              size="xxs"
            />
          </template>
        </UploadDropzone>
      </div>
      <div class="upload-resource-footer">
        <Button
          text="Cancel"
          type="white"
          :disabled="loading"
          @click="() => $emit('close')"
        />
        <Button
          text="Upload"
          :disabled="!(files.length || ifiles.length)"
          :loading="loading"
          @click="handleSubmit"
        />
      </div>
    </div>
  </b-modal>
</template>

<script>
import Button from '@c/library/Button.vue'
import UploadDropzone from '@c/library/UploadDropzone.vue'
import ProgressCircle from '@c/library/ProgressCircle.vue'

export default {
  name: 'ORIUploadResourceModal',
  components: { Button, UploadDropzone, ProgressCircle },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    header: {
      type: String,
      default: 'Upload additional content'
    },
    propsCall: {
      type: Function,
      default: async () => ({})
    },
    callback: {
      type: Function,
      default: async () => {}
    },
    maxSize: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    loading: false,
    files: [],
    ifiles: [],
    uploading: {},
    progressKey: 0
  }),
  computed: {
    uploadingNames() {
      return Object.keys(this.uploading)
    }
  },
  methods: {
    showError(e) {
      this.$console.debug('Error uploading file', e)
      this.$toast.error(e, 'uploading file')
    },
    handleUpload(files, isDrive) {
      if (isDrive) this.ifiles = [...this.ifiles, ...files]
      else this.files = [...this.files, ...Array.from(files)]
    },
    removeFile(file, isDrive = false) {
      const key = `${isDrive ? 'i' : ''}files`
      const index = this[key].findIndex((f) => f.name === file.name)
      if (index > -1) this[key].splice(index, 1)
    },
    async handleSubmit() {
      let done = 0
      this.loading = true
      const checkDone = () => {
        if (done === this.files.length + this.ifiles.length) {
          this.loading = false
          this.$toast.success(
            'Upload complete, processing has started',
            'You will receive an email when your files have been processed'
          )
          this.$emit('done')
        }
      }
      this.files.forEach(async (file) => {
        this.uploading[file.name] = 0
        this.progressKey++
        await this.propsCall(file)
          .then(async ({ upload_url, state }) => {
            this.uploading[file.name] = 20
            this.progressKey++
            await this.uploadFile(file, upload_url).then(async () => {
              await this.callback({ state })
              this.uploading[file.name] = 100
              this.progressKey++
              done++
              checkDone()
            })
          })
          .catch((e) => {
            delete this.uploading[file.name]
            this.progressKey++
            this.showError(e)
          })
      })
      this.ifiles.forEach(async (file) => {
        this.uploading[file.name] = 20
        await this.callback({ integration_file_id: file.uuid })
        this.uploading[file.name] = 100
        this.progressKey++
        done++
        checkDone()
      })
    },
    async uploadFile(file, url) {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest()
        const name = file.name
        xhr.open('PUT', url, true)
        xhr.onload = (e) => {
          if (xhr.status === 200) {
            this.uploading[name] = 80
            this.progressKey++
            resolve()
          } else {
            delete this.uploading[file.name]
            this.progressKey++
            this.showError(e)
            reject()
          }
        }

        xhr.upload.onprogress = (e) => {
          var fileSize = file.size
          this.uploading[name] = 20 + Math.round((e.loaded / fileSize) * 60)
          this.progressKey++
        }

        xhr.onerror = (e) => {
          delete this.uploading[file.name]
          this.progressKey++
          this.showError(e)
          reject()
        }
        xhr.setRequestHeader('Content-Type', file.type)
        xhr.send(file)
        this.progressKey++
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.upload-resource {
  border-radius: 8px;
  background: white;

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 2.25rem;
    border-bottom: 1px solid rgba(#000, 0.08);

    &-title {
      font-size: 1.25rem;
      font-weight: 700;
    }
  }

  &-content {
    padding: 1.5rem 2.25rem;
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;
    min-height: 40vh;
  }

  &-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    padding: 1.5rem 2.25rem;
    border-top: 1px solid rgba(#000, 0.08);
  }
}
</style>
