<template>
  <component :is="clickable ? 'router-link' : 'div'" :to="route">
    <div class="offering" :class="{ clickable }">
      <img
        v-if="image && !imageErrored"
        class="offering-image"
        :src="image"
        alt=""
      />
      <div
        v-else
        class="offering-image"
        :style="{ background: $umodel.user_color(offering) }"
      >
        {{ $umodel.initials(offering.name) }}
      </div>
      <div class="offering-content">
        <div class="offering-name">
          {{ offering.name }}
        </div>
        <ORITagEdit
          v-if="attributes.length"
          :selected="attributes"
          :editable="false"
        />
        <p v-if="clickable" class="offering-link">Read more →</p>
      </div>
    </div>
  </component>
</template>

<script>
import ORITagEdit from '../ori/ORITagEdit.vue'

export default {
  name: 'OfferingListItem',
  components: { ORITagEdit },
  props: {
    offering: {
      type: Object,
      required: true
    },
    clickable: {
      type: Boolean,
      default: false
    },
    openExternal: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    imageErrored: false
  }),
  computed: {
    image() {
      return this.offering.image || ''
    },
    attributes() {
      return this.offering?.attributes || []
    },
    route() {
      if (!this.clickable) return
      return {
        name: 'portfolio',
        params: {
          workspace_id: this.$route.params.workspace_id,
          ori_type: 'offerings',
          ori_id: this.offering.uuid
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.offering {
  display: flex;
  flex-flow: row nowrap;
  gap: 1.75rem;
  padding: 1.15rem 1.75rem;
  border-radius: 8px;
  border: 1px solid rgba(#000, 8%);

  &.clickable {
    cursor: pointer;

    &:hover {
      border: 1px solid rgba(#000, 16%);
      box-shadow: 0px 4px 8px 0px rgba(#000, 10%);
    }
  }

  &-image {
    width: 4rem;
    min-width: 4rem;
    max-width: 4rem;
    height: 4rem;
    min-height: 4rem;
    max-height: 4rem;
    border-radius: 999rem;
    object-fit: cover;
    overflow: hidden;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 1.5rem;
  }

  &-content {
    display: flex;
    flex-flow: column nowrap;
    gap: 0.85rem;
  }

  &-name {
    font-size: 1.25rem;
    font-weight: 600;
  }

  &-link {
    color: $primary;
    cursor: pointer;
    width: fit-content;

    &:hover {
      text-decoration: underline;
    }
  }

  &-conf {
    margin-left: auto;
  }
}
</style>
