<template>
  <div class="confirm">
    <div class="confirm-header">Remove {{ typeName }}</div>
    <div class="confirm-body">
      <p class="confirm-body-header">Remove "{{ name }}"</p>
      <p class="confirm-body-text">
        Are you sure you want to remove this {{ typeName }}? This action cannot
        be undone.
      </p>
    </div>
    <div class="confirm-btns">
      <Button
        text="Cancel"
        type="white"
        :disabled="loading"
        @click="handleCancel"
      />
      <Button
        :text="`Remove ${typeName}`"
        :loading="loading"
        @click="handleRemove"
      />
    </div>
  </div>
</template>

<script>
import Button from '@c/library/Button.vue'
import { mapActions } from 'vuex'

export default {
  name: 'RemoveORIModal',
  components: { Button },
  props: {
    name: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true,
      validator: (value) =>
        ['category', 'offering', 'reference', 'inspiration'].includes(value)
    },
    uuid: {
      type: String,
      required: true
    }
  },
  data: () => ({
    loading: false
  }),
  computed: {
    typeName() {
      return {
        category: 'category',
        offering: 'offering',
        reference: 'case',
        inspiration: 'inspirational content'
      }[this.type]
    }
  },
  methods: {
    ...mapActions([
      'deleteOfferingCategory',
      'deleteOffering',
      'deleteReference',
      'deleteInspiration'
    ]),
    handleCancel() {
      this.$emit('close')
    },
    async handleRemove() {
      try {
        this.loading = true
        const removalFunction = {
          category: this.deleteOfferingCategory,
          offering: this.deleteOffering,
          reference: this.deleteReference,
          inspiration: this.deleteInspiration
        }[this.type]
        const idProp = this.type === 'category' ? 'category_id' : 'ori_id'
        await removalFunction({
          workspace_id: this.$route.params.workspace_id,
          [idProp]: this.uuid
        })
        this.$toast.success(
          `${this.typeName} removed`,
          `${this.typeName} "${this.name}" has been removed.`
        )
        this.$emit('remove', this.uuid)
      } catch (e) {
        this.$console.debug('Error when removing', e)
        this.$toast.error(e, `removing ${this.typeName}`)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.confirm {
  background: white;
  border: 1px solid rgba(#000, 8%);
  border-radius: 4px;

  &-header {
    padding: 1rem 2rem;
    border-bottom: 1px solid rgba(#000, 8%);
    font-size: 1.2rem;
    font-weight: 700;
    color: #fc0d1b;
  }

  &-body {
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;
    padding: 1rem 2rem;

    &-header {
      font-size: 1.2rem;
      font-weight: 700;
    }

    &-text {
      color: #9098ae;
    }
  }

  &-btns {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    gap: 1rem;
    padding: 1rem 2rem;
  }
}
</style>
