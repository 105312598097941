<template>
  <div class="ori-container">
    <div class="ori-bar">
      <div class="ori-bar-breadcrumbs">
        <component
          :is="
            breadcrumbRoute(breadcrumb.path, breadcrumb.params)
              ? 'router-link'
              : 'div'
          "
          v-for="breadcrumb in breadcrumbs"
          :key="breadcrumb.name"
          :to="breadcrumbRoute(breadcrumb.path, breadcrumb.params)"
          class="ori-bar-breadcrumbs-item"
        >
          {{ breadcrumb.name }}
        </component>
      </div>
    </div>
    <div class="ori-sidebar-container">
      <div class="scrollitem ori-header-block" :data-title="name">
        <ORIHeader
          :ori="ori"
          :can-edit="canWriteOri || isOwner"
          :disable-actions="disableActions"
          :type-name="typeName"
          :attribute-edit-loading="attributeEditLoading"
          v-on="$listeners"
          @publish="handlePublish"
          @editImage="imageModalOpen = true"
        />
        <ORIPropsBar
          v-show="showPropsBar"
          :account="account"
          :url="url"
          :confidentiality="confidentiality"
          :title-mapping="titleMapping"
        />
      </div>
      <ORISidebar
        :overview="overviewItems"
        :owners="owners"
        :can-edit="canWriteOri || isOwner"
        :disable-actions="disableActions"
        class="ori-sidebar"
        @editOwners="ownersModalOpen = true"
        @overviewClick="handleOverviewItemClick"
      />
      <div class="ori-wrapper">
        <div class="ori-divider"></div>
        <div class="ori-sections">
          <div
            v-if="
              !oriManagementEnabled ||
              !draftOriContentEnabled ||
              isDraftingWithContent ||
              isPublished
            "
            class="ori-wrapper"
          >
            <div
              v-if="hasResourcesKey"
              class="scrollitem"
              :data-title="resourceTitle"
            >
              <ORISection
                type="resources"
                :name="resourceTitle"
                :data-title="resourceTitle"
                :items="resources"
                :parent-type="type"
                :is-owner="isOwner"
                :ori="ori"
                @remove="(item) => $emit('removeResource', item)"
                @add="$emit('linkResource')"
                @editAttributes="
                  (att, item) => $emit('editResourceAttributes', att, item)
                "
                @reorder="(pos) => $emit('resourceOrderChange', pos)"
                @published="(item) => $emit('publishResource', item)"
                @refresh="() => $emit('refresh')"
              />
            </div>
          </div>
          <ORIStatusBlock
            v-else-if="isEmpty || isDrafting"
            :type="type"
            :status="ori.status || ''"
            :can-edit="canWriteOri || isOwner"
            v-on="$listeners"
          />
          <div class="scrollitem" :data-title="offeringsTitle">
            <ORISection
              type="offerings"
              :name="offeringsTitle"
              :data-title="offeringsTitle"
              :items="offerings"
              :has-show-more="type !== 'offering'"
              :removable="type !== 'offering'"
              :parent-type="type"
              :is-owner="isOwner"
              :ori="ori"
              @remove="(item) => $emit('removeOffering', item)"
              @add="$emit('linkOffering')"
              @reorder="(pos) => $emit('offeringOrderChange', pos)"
            />
          </div>
          <div
            v-if="type === 'offering'"
            class="scrollitem"
            :data-title="referencesTitle"
          >
            <ORISection
              type="references"
              :name="referencesTitle"
              :data-title="referencesTitle"
              :items="references"
              :has-show-more="true"
              :parent-type="type"
              :is-owner="isOwner"
              :ori="ori"
              @remove="(item) => $emit('removeReference', item)"
              @add="$emit('linkReference')"
            />
          </div>
          <div
            v-if="type === 'offering'"
            class="scrollitem"
            :data-title="inspirationTitle"
          >
            <ORISection
              type="inspiration"
              :name="inspirationTitle"
              :data-title="inspirationTitle"
              :items="inspiration"
              :has-show-more="true"
              :parent-type="type"
              :is-owner="isOwner"
              :ori="ori"
              @remove="(item) => $emit('removeInspiration', item)"
              @add="$emit('linkInspiration')"
            />
          </div>
        </div>
      </div>
    </div>
    <ORIImageModal
      :item="ori"
      :type="type"
      :visible="imageModalOpen"
      @edit="handleModalOutput"
      @close="imageModalOpen = false"
    />
    <ORIOwnersModal
      :item="ori"
      :type="type"
      :visible="ownersModalOpen"
      @edit="handleModalOutput"
      @close="ownersModalOpen = false"
    />
  </div>
</template>

<script>
import { isEmpty } from 'lodash'
import { mapGetters, mapActions } from 'vuex'
import ORIPropsBar from './ORIPropsBar.vue'
import ORISection from './ORISection.vue'
import ORIHeader from './ORIHeader.vue'
import ORISidebar from './ORISidebar.vue'
import ORIImageModal from './ORIImageModal.vue'
import ORIOwnersModal from './ORIOwnersModal.vue'
import ORIStatusBlock from './ORIStatusBlock.vue'

const flattenBreadcrumbs = (item) => {
  if (item.parent) return [...flattenBreadcrumbs(item.parent), item]
  return [item]
}

const defaultState = {
  name: '',
  account: undefined,
  url: '',
  image: '',
  owners: [],
  confidentiality: '',
  attributes: [],
  offerings: [],
  references: [],
  inspiration: [],
  resources: [],
  keys: [],
  overviewItems: [],
  imageModalOpen: false,
  ownersModalOpen: false
}

export default {
  name: 'ORI',
  components: {
    ORIPropsBar,
    ORISection,
    ORIHeader,
    ORISidebar,
    ORIImageModal,
    ORIOwnersModal,
    ORIStatusBlock
  },
  props: {
    ori: {
      type: Object,
      default: () => ({})
    },
    type: {
      type: String,
      default: 'offering',
      validator: (value) => {
        return ['offering', 'inspiration', 'reference'].includes(value)
      }
    },
    keyMapping: {
      type: Object,
      default: () => ({})
    },
    titleMapping: {
      type: Object,
      default: () => ({})
    },
    attributeEditLoading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    ...defaultState
  }),
  computed: {
    ...mapGetters([
      'currentWorkspaceMember',
      'canWriteOri',
      'canReadOri',
      'oriManagementEnabled',
      'draftOriContentEnabled'
    ]),
    isOwner() {
      return (this.owners || []).some(
        (owner) => owner.uuid === this.currentWorkspaceMember.uuid
      )
    },
    typeName() {
      return {
        offering: 'offering',
        reference: 'case',
        inspiration: 'inspirational content'
      }[this.type]
    },
    publishStatus() {
      return this.ori?.status || ''
    },
    isEmpty() {
      return !this.publishStatus
    },
    isDrafting() {
      return this.publishStatus === 'draft'
    },
    isDraftingWithContent() {
      return (
        this.isDrafting &&
        (!this.oriManagementEnabled || !!this.ori?.resources?.length)
      )
    },
    isPublished() {
      return this.publishStatus === 'published'
    },
    disableActions() {
      return (
        this.oriManagementEnabled &&
        this.draftOriContentEnabled &&
        this.isDrafting &&
        !this.isDraftingWithContent
      )
    },
    showPropsBar() {
      return !isEmpty(this.account) || !!this.url || !!this.confidentiality
    },
    hasAttributes() {
      return (this.attributes || []).length > 0
    },
    hasAttributesKey() {
      return this.keys.includes('attributes')
    },
    offeringsTitle() {
      return this.titleMapping?.offerings || 'Related offerings'
    },
    inspirationTitle() {
      return this.titleMapping?.inspiration || 'Related inspirational content'
    },
    referencesTitle() {
      return this.titleMapping?.references || 'Cases'
    },
    hasResourcesKey() {
      return this.keys.includes('resources')
    },
    resourceTitle() {
      return this.titleMapping?.resources || 'Key content'
    },
    breadcrumbs() {
      const baseName = {
        offering: 'Offerings',
        inspiration: 'Inspirational content',
        reference: 'Cases'
      }[this.type]
      const oriType = {
        offering: 'offerings',
        inspiration: 'inspirations',
        reference: 'references'
      }[this.type]
      return [
        { name: baseName, path: 'portfolio', params: { ori_type: oriType } },
        ...flattenBreadcrumbs(this.ori).map((item) => ({
          name: item.name,
          path: 'portfolio',
          params: { ori_type: oriType, ori_id: item.uuid }
        }))
      ]
    }
  },
  watch: {
    ori: {
      handler() {
        this.initialize()
      },
      deep: true
    }
  },
  created() {
    this.initialize()
  },
  methods: {
    ...mapActions([
      'setOfferingStatus',
      'setReferenceStatus',
      'setInspirationStatus'
    ]),
    initialize() {
      Object.keys(this.ori).forEach((key) => {
        const mappedKey = this.keyMapping[key] || key
        this[mappedKey] = this.ori[key] || defaultState[mappedKey]
      })
      this.keys = Object.keys(this.ori).map(
        (key) => this.keyMapping[key] || key
      )
      this.$nextTick(this.initOverview)
      this.checkPermissions()
    },
    checkPermissions() {
      if (
        this.isPublished ||
        this.canWriteOri ||
        this.canReadOri ||
        this.isOwner
      )
        return
      this.$router.push({
        name: this.$route.name.split('-')[0],
        params: { workspace_id: this.$route.params.workspace_id }
      })
    },
    breadcrumbRoute(name, params) {
      const route = this.$router.resolve({
        name,
        params,
        query: {
          ...(this.$route.query?.view ? { view: this.$route.query?.view } : {})
        }
      }).route
      if (route.fullPath === this.$route.fullPath) return
      return route
    },
    initOverview() {
      this.overviewItems = Array.from(
        document.getElementsByClassName('scrollitem')
      ).map((el) => el.dataset.title)
    },
    handleOverviewItemClick(idx) {
      if (idx === 0) {
        this.$el.scrollIntoView({ behavior: 'smooth' })
        return
      }
      const el = document.getElementsByClassName('scrollitem')[idx]
      el.scrollIntoView({ behavior: 'smooth' })
    },
    async handlePublish() {
      const un = this.isPublished ? 'un' : ''
      try {
        const statusFunction = {
          offering: this.setOfferingStatus,
          reference: this.setReferenceStatus,
          inspiration: this.setInspirationStatus
        }[this.type]
        const res = await statusFunction({
          workspace_id: this.$route.params.workspace_id,
          ori_id: this.ori.uuid,
          status: this.isPublished ? 'draft' : 'published'
        })
        this.$emit('publish', res)
        this.$toast.success(`Successfully ${un}published ${this.typeName}`)
      } catch (e) {
        this.$console.debug(`Something went wrong while ${un}publishing`, e)
        this.$toast.error(e, `${un}publishing this ${this.typeName}`)
      }
    },
    handleModalOutput(res) {
      this.$emit('edit', res)
      this.imageModalOpen = false
      this.ownersModalOpen = false
    }
  }
}
</script>

<style scoped lang="scss">
.ori {
  &-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    gap: 1.75rem;
  }

  &-wrapper {
    display: flex;
    flex-flow: column nowrap;
    gap: 1.75rem;
    width: 100%;
    grid-area: content;
  }

  &-bar {
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;
    align-self: flex-start;

    &-breadcrumbs {
      display: flex;
      flex-flow: row wrap;
      gap: 2.5rem;
      align-items: center;

      &-item {
        cursor: pointer;

        &:first-child {
          color: #8f9399;
          transition: color 0.2s ease;
        }

        &:not(:first-child) {
          color: #60666b;
          position: relative;

          &::before {
            content: '/';
            color: #8f9399;
            position: absolute;
            left: -1.5rem;
            top: 50%;
            transform: translateY(-50%);
          }
        }

        &:last-child {
          cursor: default;

          &:first-child {
            &:hover {
              color: #8f9399;
            }
          }
          &:hover {
            color: #60666b;
          }
        }

        &:hover {
          color: $primary;
        }
      }
    }
  }

  &-header-block {
    grid-area: title;
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;
  }

  &-image {
    width: 100%;
    border-radius: 4px;
  }

  &-divider {
    border-bottom: 1px solid rgba(#000, 8%);
  }

  &-sections {
    display: flex;
    flex-flow: column nowrap;
    gap: 2.5rem;
  }

  &-sidebar {
    grid-area: sidebar;

    &-container {
      display: grid;
      grid-template-columns: 65vw 1fr;
      grid-template-rows: 1fr auto;
      grid-template-areas:
        'title sidebar'
        'content sidebar';
      gap: 1rem 3rem;
      width: 100%;
    }
  }
}

@media only screen and (max-width: 80rem) {
  .ori-sidebar-container {
    display: flex;
    flex-flow: column nowrap;
  }
}
</style>
