var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "custom-id" }, [
    !_vm.open
      ? _c(
          "div",
          {
            staticClass: "custom-id-open",
            on: {
              click: function() {
                return (_vm.open = true)
              }
            }
          },
          [_vm._v(" Add custom identifier ")]
        )
      : _c(
          "div",
          { staticClass: "custom-id-edit" },
          [
            _c("TextInput", {
              attrs: {
                title: "Custom identifier",
                placeholder: "Add custom identifier"
              },
              model: {
                value: _vm.customId,
                callback: function($$v) {
                  _vm.customId = $$v
                },
                expression: "customId"
              }
            })
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }