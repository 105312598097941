var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "overview-category" },
    [
      !_vm.isSearch
        ? _c("div", { staticClass: "overview-category-header" }, [
            _c("div", { staticClass: "overview-category-header-wrapper" }, [
              _vm.draggable
                ? _c("img", {
                    staticClass: "overview-category-header-drag",
                    attrs: { src: require("@/assets/icons/drag.svg"), alt: "" },
                    on: {
                      dragstart: function($event) {
                        $event.preventDefault()
                      },
                      mousedown: _vm.startDrag,
                      click: function() {
                        return _vm.endDrag(false)
                      }
                    }
                  })
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "overview-category-header-collapse",
                  on: {
                    click: function($event) {
                      _vm.collapsed = !_vm.collapsed
                    }
                  }
                },
                [
                  _c("img", {
                    staticClass: "overview-category-header-collapse-icon",
                    class: { collapsed: _vm.collapsed },
                    attrs: {
                      src: require("@/assets/icons/chevron-down-medium.svg"),
                      alt: ""
                    }
                  }),
                  _c("p", { staticClass: "overview-category-header-text" }, [
                    _vm._v(" " + _vm._s(_vm.category.name) + " ")
                  ])
                ]
              ),
              _vm.canEdit
                ? _c(
                    "div",
                    { staticClass: "overview-category-header-btns" },
                    [
                      _c(
                        "b-tooltip",
                        {
                          attrs: {
                            label: "Edit offering category",
                            position: "is-top",
                            type: "is-dark"
                          }
                        },
                        [
                          _c("Button", {
                            attrs: { icon: "edit", type: "white", size: "s" },
                            on: {
                              click: function($event) {
                                return _vm.$emit("edit")
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-tooltip",
                        {
                          attrs: {
                            label: "Delete offering category",
                            position: "is-top",
                            type: "is-dark"
                          }
                        },
                        [
                          _c("Button", {
                            attrs: { icon: "bin", type: "white", size: "s" },
                            on: {
                              click: function($event) {
                                return _vm.$emit("remove")
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c("Button", {
                        attrs: { text: "Add offering", size: "s" },
                        on: {
                          click: function($event) {
                            return _vm.$emit("add")
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ]),
            _vm.category.description
              ? _c("p", { staticClass: "overview-category-header-subtext" }, [
                  _vm._v(" " + _vm._s(_vm.category.description) + " ")
                ])
              : _vm._e(),
            _vm.dragging
              ? _c(
                  "div",
                  {
                    staticClass: "overview-category-drag-preview",
                    style: Object.assign({}, _vm.draggedItemOffset)
                  },
                  [
                    _c("img", {
                      staticClass: "overview-category-drag-preview-handle",
                      attrs: {
                        src: require("@/assets/icons/drag.svg"),
                        alt: ""
                      },
                      on: {
                        mouseup: function() {
                          return _vm.endDrag(true)
                        },
                        dragstart: function($event) {
                          $event.preventDefault()
                        },
                        mousemove: function($event) {
                          return _vm.$emit("moving", $event)
                        }
                      }
                    }),
                    _c("img", {
                      staticClass: "overview-category-header-collapse-icon",
                      class: { collapsed: _vm.collapsed },
                      attrs: {
                        src: require("@/assets/icons/chevron-down-medium.svg"),
                        alt: ""
                      }
                    }),
                    _c("p", { staticClass: "overview-category-header-text" }, [
                      _vm._v(" " + _vm._s(_vm.category.name) + " ")
                    ])
                  ]
                )
              : _vm._e()
          ])
        : _vm._e(),
      !_vm.collapsed
        ? _c("DraggableList", {
            staticClass: "overview-category-list",
            class: { "grid-faded": _vm.dragging },
            attrs: {
              items: _vm.offerings,
              "item-key": _vm.category.uuid,
              draggable: !_vm.isSearch && _vm.canEdit,
              "handle-bordered": true,
              "handle-overlaps": true,
              "item-style": { padding: 0, borderRadius: "8px" }
            },
            on: {
              reorder: function(o) {
                return _vm.$emit("reorder", o)
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("OfferingCard", {
                        key: item.name,
                        staticClass: "overview-category-list-item",
                        attrs: {
                          offering: item,
                          clickable: true,
                          "show-status": _vm.canEdit
                        }
                      })
                    ]
                  }
                }
              ],
              null,
              false,
              3089533699
            )
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }