var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: { active: _vm.visible },
      on: {
        close: function() {
          return _vm.$emit("close")
        }
      }
    },
    [
      _vm.visible
        ? _c("div", { staticClass: "ori-parent-modal" }, [
            _c(
              "div",
              { staticClass: "ori-parent-modal-content" },
              [
                _c("p", { staticClass: "ori-parent-modal-title" }, [
                  _vm._v("Change offering parent")
                ]),
                _c("OfferingParentEdit", {
                  key: _vm.parent ? _vm.parent.uuid : _vm.category.uuid,
                  attrs: {
                    "offering-id": _vm.item.uuid,
                    "parent-id": _vm.parent ? _vm.parent.uuid : "",
                    category: _vm.parent ? undefined : _vm.category,
                    "align-top": false
                  },
                  on: { select: _vm.handleParentSelect }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "ori-parent-modal-footer" },
              [
                _c("Button", {
                  attrs: {
                    text: "Cancel",
                    type: "white",
                    disabled: _vm.loading
                  },
                  on: {
                    click: function() {
                      return _vm.$emit("close")
                    }
                  }
                }),
                _c("Button", {
                  attrs: {
                    text: "Save",
                    loading: _vm.loading,
                    disabled: !_vm.parentChanged
                  },
                  on: { click: _vm.saveParent }
                })
              ],
              1
            )
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }