var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: { active: _vm.visible },
      on: {
        close: function() {
          return _vm.$emit("close")
        }
      }
    },
    [
      _vm.visible
        ? _c("div", { staticClass: "ori-img-modal" }, [
            _c(
              "div",
              { staticClass: "ori-img-modal-content" },
              [
                _c("p", { staticClass: "ori-img-modal-title" }, [
                  _vm._v("Change image")
                ]),
                _c("AvatarUpload", {
                  attrs: { image: _vm.image, width: 512, "max-size": 5 },
                  on: {
                    input: function(url, img) {
                      _vm.image = url
                      _vm.imageBlob = img
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "ori-img-modal-footer" },
              [
                _c("Button", {
                  attrs: {
                    text: "Cancel",
                    type: "white",
                    disabled: _vm.loading
                  },
                  on: {
                    click: function() {
                      return _vm.$emit("close")
                    }
                  }
                }),
                _c("Button", {
                  attrs: {
                    text: "Save",
                    loading: _vm.loading,
                    disabled: !_vm.imageChanged
                  },
                  on: { click: _vm.saveImage }
                })
              ],
              1
            )
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }