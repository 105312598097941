<template>
  <component :is="clickable ? 'router-link' : 'div'" :to="route">
    <div class="inspiration" :class="{ clickable }">
      <img
        v-if="image && !imageErrored"
        class="inspiration-image"
        :src="image"
        alt=""
      />
      <div
        v-else
        class="inspiration-image"
        :style="{ background: $umodel.user_color(inspiration) }"
      >
        {{ $umodel.initials(inspiration.name) }}
      </div>
      <div class="inspiration-content">
        <div class="inspiration-name-wrapper">
          <p class="inspiration-name">
            {{ inspiration.name }}
          </p>
          <StatusOrb
            v-if="showStatus"
            :type="
              inspiration.status
                ? inspiration.status === 'published'
                  ? 'green'
                  : 'orange'
                : 'grey'
            "
            size="xs"
            :text="inspiration.status.capitalize() || 'No content'"
            class="inspiration-name-status"
          />
        </div>
        <ORITagEdit
          v-if="attributes.length"
          :selected="attributes"
          :editable="false"
          :overflowing="true"
          tag-size="xxs"
        />
        <p v-if="clickable" class="inspiration-link">Read more →</p>
      </div>
    </div>
  </component>
</template>

<script>
import StatusOrb from '@c/library/StatusOrb.vue'
import ORITagEdit from '../ori/ORITagEdit.vue'

export default {
  name: 'InspirationCard',
  components: { StatusOrb, ORITagEdit },
  props: {
    inspiration: {
      type: Object,
      required: true
    },
    clickable: {
      type: Boolean,
      default: false
    },
    showStatus: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    imageErrored: false
  }),
  computed: {
    image() {
      return this.inspiration.image || ''
    },
    attributes() {
      return this.inspiration?.attributes || []
    },
    route() {
      if (!this.clickable) return
      return {
        name: 'portfolio',
        params: {
          workspace_id: this.$route.params.workspace_id,
          ori_type: 'inspirations',
          ori_id: this.inspiration.uuid
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.inspiration {
  display: flex;
  flex-flow: row nowrap;
  border-radius: 8px;
  border: 1px solid rgba(#000, 8%);

  &.clickable {
    cursor: pointer;

    &:hover {
      border: 1px solid rgba(#000, 16%);
      box-shadow: 0px 4px 8px 0px rgba(#000, 10%);
    }
  }

  &-image {
    width: 12.5rem;
    min-width: 12.5rem;
    max-width: 12.5rem;
    min-height: 8.5rem;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    object-fit: cover;
    overflow: hidden;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 1.5rem;
  }

  &-content {
    padding: 1.15rem 1.75rem;
    display: flex;
    flex-flow: column nowrap;
    gap: 0.85rem;
  }

  &-name {
    font-size: 1.25rem;
    font-weight: 600;
    display: inline;

    &-status {
      display: inline-flex;
      transform: translateY(2px);
    }
  }

  &-summary {
    color: #60666b;
    white-space: pre-wrap;
  }

  &-link {
    color: $primary;
    cursor: pointer;
    width: fit-content;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
