<template>
  <div
    class="resource"
    :class="{ selected }"
    :style="`--selection-color: ${selectionColor};`"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
  >
    <div class="resource-title-wrapper">
      <div class="resource-title">
        <DocumentTag
          class="resource-title-doctag"
          :mimetype="resource.integrationfile.mimetype"
        />
        <div class="resource-title-text-wrapper" @click="onTitleClick">
          <p class="resource-title-text-container">
            <span
              class="resource-title-text"
              :class="{ clickable: titleClickable }"
              >{{ resource.title }}</span
            >
          </p>
          <img
            v-if="!titleClickable"
            src="@/assets/icons/external-bold.svg"
            alt=""
            class="resource-title-link"
            @click.stop="openResourceUrl"
          />
        </div>
        <div class="resource-title-tags">
          <span v-if="isGenerated" class="resource-title-tag">Generated</span>
          <span v-if="titleTag" class="resource-title-tag">{{ titleTag }}</span>
        </div>
        <UploadDownloadDropdown
          v-if="downloadFilesEnabled"
          :props-call="getDownloadProps"
        />
      </div>
      <div>
        <slot name="buttons"></slot>
      </div>
    </div>
    <ResourceInfo
      class="resource-info"
      :resource-integration="resource.integrationfile"
      :hover="hover"
      @on-path-click="pathClick"
      v-on="$listeners"
    />
    <div class="resource-preview" :class="{ scrimmed: showScrim }">
      <div class="resource-preview-list">
        <div
          v-for="(subresource, idx) in resource.subresources"
          :key="subresource.uuid"
          class="resource-preview-list-item"
          :class="{
            highlighted: highlightSubresources,
            inspectable: !showScrim
          }"
          @click="$emit('click', subresource.page)"
        >
          <img
            :src="subresource.preview"
            alt=""
            class="resource-preview-list-item-img"
            @load="imagesLoaded++"
            @error="imagesErrored++"
          />
          <div class="resource-preview-list-item-btns" @click.stop>
            <CollectSlideButton
              v-if="showSlideCollect"
              :subresource="subresource"
              :rank="idx + 1"
              :emit-collect="true"
              :mimetype="resource.integrationfile.mimetype"
              :resource-trace-id="resource.trace_id"
              :always-show="true"
              @collect="$emit('subresourceCollect', $event)"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="resource-tags">
      <ResourceTagList :tags="resourceTags" />
    </div>
  </div>
</template>

<script>
import {
  eventContext,
  sendFileClickThroughEvent,
  sendFolderClickThroughEvent
} from '@/services/feedbackService'
import { getDocumentDownloadLink } from '@/services/presentationService'
import UploadDownloadDropdown from '@c/library/UploadDownloadDropdown.vue'
import { mapGetters } from 'vuex'
import DocumentTag from '../../tags/DocumentTag.vue'
import CollectSlideButton from './subcomponents/CollectSlideButton.vue'
import ResourceInfo from './subcomponents/ResourceInfo.vue'
import ResourceTagList from './subcomponents/ResourceTagList.vue'

export default {
  name: 'SelectableResource',
  components: {
    ResourceTagList,
    ResourceInfo,
    DocumentTag,
    UploadDownloadDropdown,
    CollectSlideButton
  },
  props: {
    resource: {
      type: Object,
      required: true
    },
    titleTag: {
      type: String,
      default: undefined
    },
    rank: {
      type: Number,
      required: true
    },
    location: {
      type: String,
      default: 'search results'
    },
    selected: {
      type: Boolean,
      default: false
    },
    selectionColor: {
      type: String,
      default: '#115efb'
    },
    highlightSubresources: {
      type: Boolean,
      default: false
    },
    showScrim: {
      type: Boolean,
      default: true
    },
    showSlideCollect: {
      type: Boolean,
      default: true
    },
    titleClickable: {
      default: true,
      type: Boolean
    }
  },
  data: () => ({
    imagesLoaded: 0,
    imagesErrored: 0,
    hover: false
  }),
  computed: {
    ...mapGetters(['downloadFilesEnabled']),
    isSearch() {
      return this.$route.name === 'search'
    },
    allImagesLoaded() {
      return (
        this.imagesLoaded + this.imagesErrored ===
        this.resource.subresources.length
      )
    },
    resourceTags() {
      return [
        ...(this.resource.type ? [this.resource.type] : []),
        ...(this.resource.content_class ? [this.resource.content_class] : []),
        ...(this.resource.organisations || []).map((x) => x.name),
        ...(this.resource.industries || []).map((x) => x.name),
        ...(this.resource.topics?.tags || []).map((x) => x.name)
      ]
    },
    isGenerated() {
      return (
        this.resource?.integrationfile?.integration === 'google_cloud_storage'
      )
    }
  },
  watch: {
    allImagesLoaded() {
      this.$emit(
        this.imagesErrored === this.resource.subresources.length
          ? 'error'
          : 'load'
      )
    }
  },
  methods: {
    onTitleClick() {
      if (!this.titleClickable) return
      this.openResourceUrl()
    },
    openResourceUrl() {
      if (this.isSearch) {
        sendFileClickThroughEvent(this.$route.params.workspace_id, {
          resource_trace_id: this.resource.trace_id,
          tab: this.$route.params.tab || 'all',
          context: eventContext.resource,
          comment: `from ${this.location}`,
          rank: this.rank
        })
      }
      const win = window.open(this.resource.url, '_blank')
      win?.focus()
    },
    async pathClick() {
      if (this.isSearch) {
        sendFolderClickThroughEvent(this.$route.params.workspace_id, {
          resource_trace_id: this.resource.trace_id,
          tab: this.$route.params.tab || 'all',
          context: eventContext.resource,
          comment: `from ${this.location}`,
          rank: this.rank
        })
      }
    },
    async getDownloadProps() {
      return getDocumentDownloadLink(
        this.$route.params.workspace_id,
        this.resource.uuid,
        this.$route.query?.act_as
      )
    }
  }
}
</script>

<style scoped lang="scss">
.resource {
  border-radius: 4px;
  transition: border ease 200ms;
  border: 2px solid transparent;

  &-title {
    display: grid;
    grid-template-columns: repeat(5, auto);
    width: fit-content;
    gap: 1rem;
    align-items: center;

    &-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 0.5rem;
      justify-content: space-between;
    }

    &-text {
      font-weight: 700;
      font-size: 1.15rem;
      color: $primary;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &.clickable {
        cursor: pointer;

        &:hover {
          text-decoration: underline;
          text-decoration-thickness: 2px;
          text-underline-offset: 2px;
        }
      }

      &-wrapper {
        max-width: 100%;
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 0.5rem;
        align-items: center;
      }

      &-container {
        max-width: 100%;
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &-link {
      height: 1rem;
      cursor: pointer;
      filter: brightness(0) saturate(100%) invert(25%) sepia(95%)
        saturate(2915%) hue-rotate(217deg) brightness(97%) contrast(103%);
      transition: transform 0.2s ease;

      &:hover {
        transform: scale(1.1);
      }
    }

    &-tags {
      display: flex;
      flex-flow: row nowrap;
      gap: 0.5rem;
    }

    &-tag {
      padding: 0.3rem 1rem;
      border-radius: 999rem;
      background: #ebf1ff;
      color: $primary;
      font-weight: 700;
      white-space: nowrap;
      user-select: none;
    }

    &-download {
      height: 2rem;
      width: 2rem;
      border-radius: 999rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: background 0.2s ease;

      &:hover {
        background: rgba(#000, 6%);
      }

      &-icon {
        height: 1.2rem;
      }
    }
  }

  &-info {
    width: 100%;
    max-width: 100%;
  }

  &-preview {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    border-radius: 4px;
    transition: all ease 200ms;
    padding: 0.5rem;
    position: relative;
    margin: 1rem 0;

    &.scrimmed {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(#fff, 0.5);
        pointer-events: none;
        cursor: pointer;
      }
    }

    &-list {
      width: 100%;
      max-width: 100%;
      box-sizing: border-box;
      border-radius: 4px;
      background: white;
      display: flex;
      flex-flow: row nowrap;
      gap: 0.5rem;
      overflow-x: auto;
      align-items: center;
      padding: 0.5rem;
      cursor: pointer;
      -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
      transition: all 200ms ease;

      &-item {
        width: 30%;
        min-width: 30%;
        max-width: 30%;
        border-radius: 4px;
        border: 1px solid rgba(#000, 8%);
        user-select: none;
        overflow: hidden;
        position: relative;

        &-img {
          display: block;
        }

        &-btns {
          position: absolute;
          top: 0;
          right: 0;
          display: flex;
          flex-flow: row nowrap;
          gap: 0.5rem;
          padding: 0.5rem;
        }

        &.highlighted {
          border: 1px solid $primary;
          -webkit-box-shadow: 0px 4px 6px 6px rgba($primary, 0.1) !important;
          box-shadow: 0px 4px 6px 6px rgba($primary, 0.1) !important;
        }

        &.inspectable {
          cursor: pointer;
        }
      }
    }
  }

  &-tags {
    display: flex;
    justify-content: space-between;
  }

  &.selected {
    border: 2px solid var(--selection-color);

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(#fff, 0);
      transition: all 200ms ease;
      pointer-events: none;
      cursor: pointer;
    }

    & > .resource-preview-list {
      -webkit-box-shadow: 0px 4px 6px 6px rgba(0, 0, 0, 0.06);
      box-shadow: 0px 4px 6px 6px rgba(0, 0, 0, 0.06);
    }
  }
}
</style>
