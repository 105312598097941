var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "portfolio-item" },
    [
      _vm.header.id === "name"
        ? _c(
            "PortfolioItemName",
            _vm._g(
              { attrs: { item: _vm.item, type: _vm.type } },
              _vm.$listeners
            )
          )
        : _vm.header.id === "owners"
        ? _c("PortfolioItemOwners", {
            attrs: { item: _vm.item, type: _vm.type, "can-edit": _vm.canEdit },
            on: {
              edit: function(item) {
                return _vm.$emit("edit", item)
              }
            }
          })
        : _vm.header.id === "parent"
        ? _c("PortfolioItemParent", {
            attrs: { item: _vm.item, "can-edit": _vm.canEdit },
            on: {
              edit: function(item) {
                return _vm.$emit("edit", item)
              }
            }
          })
        : ["content", "offerings", "references", "inspirations"].includes(
            _vm.header.id
          )
        ? _c("PortfolioItemResources", {
            attrs: { item: _vm.item, header: _vm.header }
          })
        : _vm.header.id === "status"
        ? _c("PortfolioItemStatus", {
            attrs: {
              item: _vm.item,
              header: _vm.header,
              type: _vm.type,
              "can-edit": _vm.canEdit
            },
            on: {
              publish: function(item) {
                return _vm.$emit("edit", item)
              }
            }
          })
        : _vm.header.id.startsWith("attribute")
        ? _c("PortfolioItemAttributes", {
            attrs: {
              item: _vm.item,
              "attribute-id": _vm.header.id.split("--")[1],
              "can-edit": _vm.canEdit,
              type: _vm.type
            },
            on: {
              edit: function(item) {
                return _vm.$emit("edit", item)
              }
            }
          })
        : _vm.header.id === "confidentiality"
        ? _c("PortfolioItemConfidentiality", { attrs: { item: _vm.item } })
        : _c("div", [_vm._v(" " + _vm._s(_vm.header.key(_vm.item)) + " ")])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }