import { render, staticRenderFns } from "./ReferencesOverview.vue?vue&type=template&id=ac102d26&scoped=true&"
import script from "./ReferencesOverview.vue?vue&type=script&lang=js&"
export * from "./ReferencesOverview.vue?vue&type=script&lang=js&"
import style0 from "./ReferencesOverview.vue?vue&type=style&index=0&id=ac102d26&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ac102d26",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/frontend/frontend/src/web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ac102d26')) {
      api.createRecord('ac102d26', component.options)
    } else {
      api.reload('ac102d26', component.options)
    }
    module.hot.accept("./ReferencesOverview.vue?vue&type=template&id=ac102d26&scoped=true&", function () {
      api.rerender('ac102d26', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views-v2/references/ReferencesOverview.vue"
export default component.exports