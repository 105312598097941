<template>
  <div class="overview-category">
    <div v-if="!isSearch" class="overview-category-header">
      <div class="overview-category-header-wrapper">
        <img
          v-if="draggable"
          src="@/assets/icons/drag.svg"
          alt=""
          class="overview-category-header-drag"
          @dragstart.prevent
          @mousedown="startDrag"
          @click="() => endDrag(false)"
        />
        <div
          class="overview-category-header-collapse"
          @click="collapsed = !collapsed"
        >
          <img
            src="@/assets/icons/chevron-down-medium.svg"
            alt=""
            class="overview-category-header-collapse-icon"
            :class="{ collapsed }"
          />
          <p class="overview-category-header-text">
            {{ category.name }}
          </p>
        </div>
        <div v-if="canEdit" class="overview-category-header-btns">
          <b-tooltip
            label="Edit offering category"
            position="is-top"
            type="is-dark"
          >
            <Button icon="edit" type="white" size="s" @click="$emit('edit')" />
          </b-tooltip>
          <b-tooltip
            label="Delete offering category"
            position="is-top"
            type="is-dark"
          >
            <Button icon="bin" type="white" size="s" @click="$emit('remove')" />
          </b-tooltip>
          <Button text="Add offering" size="s" @click="$emit('add')" />
        </div>
      </div>
      <p v-if="category.description" class="overview-category-header-subtext">
        {{ category.description }}
      </p>
      <div
        v-if="dragging"
        class="overview-category-drag-preview"
        :style="{ ...draggedItemOffset }"
      >
        <img
          src="@/assets/icons/drag.svg"
          alt=""
          class="overview-category-drag-preview-handle"
          @mouseup="() => endDrag(true)"
          @dragstart.prevent
          @mousemove="$emit('moving', $event)"
        />
        <img
          src="@/assets/icons/chevron-down-medium.svg"
          alt=""
          class="overview-category-header-collapse-icon"
          :class="{ collapsed }"
        />
        <p class="overview-category-header-text">
          {{ category.name }}
        </p>
      </div>
    </div>
    <DraggableList
      v-if="!collapsed"
      :items="offerings"
      :item-key="category.uuid"
      :draggable="!isSearch && canEdit"
      :handle-bordered="true"
      :handle-overlaps="true"
      :item-style="{ padding: 0, borderRadius: '8px' }"
      :class="{ 'grid-faded': dragging }"
      class="overview-category-list"
      @reorder="(o) => $emit('reorder', o)"
    >
      <template #item="{ item }">
        <OfferingCard
          :key="item.name"
          :offering="item"
          :clickable="true"
          :show-status="canEdit"
          class="overview-category-list-item"
        />
      </template>
    </DraggableList>
  </div>
</template>

<script>
import DraggableList from '@c/library/DraggableList.vue'
import OfferingCard from './OfferingCard.vue'
import Button from '@c/library/Button.vue'

export default {
  name: 'OfferingCategory',
  components: { DraggableList, OfferingCard, Button },
  props: {
    category: {
      type: Object,
      default: () => ({})
    },
    offerings: {
      type: Array,
      default: () => []
    },
    isSearch: {
      type: Boolean,
      default: false
    },
    canEdit: {
      type: Boolean,
      default: false
    },
    draggable: {
      type: Boolean,
      default: true
    },
    dragging: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    collapsed: false,
    dragOffset: {},
    draggedItemOffset: {}
  }),
  methods: {
    startDrag(e) {
      if (!this.draggable) return
      this.$emit('startDrag')

      this.dragOffset = {
        x: e.offsetX,
        y: e.offsetY
      }

      document.addEventListener('mousemove', this.handleDraggedItemMove)
      return
    },
    endDrag(doChange = true) {
      this.$emit('endDrag', doChange)
    },
    handleDraggedItemMove(e) {
      this.draggedItemOffset = {
        left: `${e.pageX - this.dragOffset.x - 14}px`,
        top: `${e.pageY - this.dragOffset.y - 7}px`
      }
    }
  }
}
</script>

<style scoped lang="scss">
.overview-category {
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;

  &-header {
    display: flex;
    flex-flow: column nowrap;
    gap: 0.5rem;

    &-text {
      font-weight: 700;
      user-select: none;
      cursor: pointer;
    }

    &-collapse {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 0.5rem;
      cursor: pointer;

      &-icon {
        height: 1.2rem;
        width: 1.2rem;
        transition: transform 0.2s ease;

        &.collapsed {
          transform: rotate(-90deg);
        }
      }
    }

    &-subtext {
      color: #60666b;
      padding-left: calc(1.2rem + 0.5rem);
    }

    &-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 0.5rem;
      position: relative;
      width: 100%;

      &:hover {
        & > .overview-category-header-drag {
          opacity: 1;
        }
      }
    }

    &-btns {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 0.5rem;
      margin-left: auto;
    }

    &-drag {
      height: 1.2rem;
      width: 1.2rem;
      position: absolute;
      top: 50%;
      left: -1.5rem;
      transform: translateY(-50%);
      opacity: 0;
      transition: opacity 0.2s ease;
      cursor: grab;
    }
  }

  &-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
    gap: 1rem;

    &-item {
      width: 100%;
    }
  }

  &-drag-preview {
    position: fixed;
    z-index: 999;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.5rem;
    padding: 7px 14px;
    border-radius: 8px;
    background: white;
    width: fit-content;
    box-shadow: 0px 4px 8px 0px rgba(#000, 10%);

    &-handle {
      height: 1.2rem;
      width: 1.2rem;
      cursor: grabbing;
    }
  }
}

.grid-faded {
  opacity: 0.5;
}
</style>
