var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "portfolio-tab" }, [
    _c(
      "div",
      { staticClass: "portfolio-tab-top-wrapper" },
      [
        _vm.filters.length
          ? _c(
              "div",
              { staticClass: "portfolio-tab-top" },
              _vm._l(_vm.filters, function(filter) {
                return _c("FilterDropdown", {
                  key: filter.id,
                  attrs: {
                    filter: filter,
                    selected: _vm.activeFilters[filter.id] || [],
                    "empty-text": "Unknown",
                    "max-height": "35vh"
                  },
                  on: {
                    submit: function(selection) {
                      return _vm.selectFilter(filter.id, selection)
                    }
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "item",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            filter.id === "owners"
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "portfolio-tab-top-filter-item"
                                  },
                                  [
                                    item.key
                                      ? _c("Avatar", {
                                          attrs: { user: item.key }
                                        })
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "portfolio-tab-top-filter-item-info"
                                      },
                                      [
                                        _vm._v(" " + _vm._s(item.value) + " "),
                                        item.key && item.key.role
                                          ? _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "portfolio-tab-top-filter-item-info-role"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item.key.role) +
                                                    " "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : filter.id === "parents"
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "portfolio-tab-top-filter-item"
                                  },
                                  [
                                    item.key
                                      ? _c("Avatar", {
                                          attrs: {
                                            user: { avatar: item.key.image },
                                            "fallback-icon": "globe"
                                          }
                                        })
                                      : _vm._e(),
                                    _vm._v(" " + _vm._s(item.value) + " ")
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                })
              }),
              1
            )
          : _vm.consumptionLoading
          ? _c(
              "div",
              { staticClass: "portfolio-tab-top" },
              _vm._l(4, function(idx) {
                return _c("b-skeleton", {
                  key: "portfolio-tab-filter-loading-" + idx,
                  attrs: { height: "2.5rem", width: "10rem" }
                })
              }),
              1
            )
          : _vm._e(),
        _vm.canWriteOri
          ? _c("Button", {
              staticClass: "portfolio-tab-top-export",
              attrs: {
                text: "Export to CSV",
                disabled: _vm.consumptionLoading,
                loading: _vm.exportLoading
              },
              on: { click: _vm.handleExport }
            })
          : _vm._e()
      ],
      1
    ),
    _c("p", { staticClass: "portfolio-tab-count" }, [
      _vm._v(
        " " +
          _vm._s(
            _vm.consumptionLoading ? " " : _vm.paginatorDataCount + " results"
          ) +
          " "
      )
    ]),
    _c(
      "div",
      {
        ref: "portfoliotablewrapper",
        staticClass: "portfolio-tab-table",
        style: Object.assign({}, _vm.maxHeightStyle, _vm.maxWidthStyle),
        attrs: { id: "portfolio-table-wrapper-" + _vm.type },
        on: {
          wheel: function() {
            return _vm.handleScroll("wheel")
          },
          scroll: function() {
            return _vm.handleScroll("scroll")
          }
        }
      },
      [
        _c("Table", {
          key: _vm.tableKey,
          attrs: {
            items: _vm.ori,
            headers: _vm.headers,
            "show-header": !!_vm.ori.length,
            sortable: _vm.sortableHeaders,
            "default-sort-by": _vm.sorting.replace("-", ""),
            "default-sort-desc": _vm.sorting.startsWith("-"),
            "emit-sort": true
          },
          on: { sort: _vm.handleSort },
          scopedSlots: _vm._u([
            {
              key: "item",
              fn: function(ref) {
                var header = ref.header
                var item = ref.item
                return [
                  _c("PortfolioItem", {
                    attrs: { header: header, item: item, type: _vm.type },
                    on: { edit: _vm.handleItemEdit }
                  })
                ]
              }
            },
            {
              key: "header",
              fn: function(ref) {
                var header = ref.header
                return [
                  header.id === "actions"
                    ? _c("PortfolioColumnDropdown", {
                        attrs: {
                          options: _vm.availableHeaders,
                          selected: _vm.selectedHeaders,
                          loading: _vm.consumptionLoading
                        },
                        on: { select: _vm.handleColumnSelection }
                      })
                    : _vm._e()
                ]
              }
            }
          ])
        }),
        _vm.consumptionLoading
          ? _c(
              "div",
              { staticClass: "portfolio-tab-loading" },
              [
                _c("b-loading", {
                  attrs: { active: "", "is-full-page": false }
                })
              ],
              1
            )
          : !_vm.ori.length
          ? _c("div", { staticClass: "portfolio-tab-empty" }, [
              _vm._m(0),
              _c("p", { staticClass: "portfolio-tab-empty-header" }, [
                _vm._v(_vm._s(_vm.oriEmptyTitle))
              ]),
              _c("p", { staticClass: "portfolio-tab-empty-sub" }, [
                _vm._v(_vm._s(_vm.oriEmptySub))
              ])
            ])
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "portfolio-tab-empty-icon" }, [
      _c("img", {
        staticClass: "portfolio-tab-empty-icon-img",
        attrs: { src: require("@/assets/icons/bulb.svg"), alt: "" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }