<template>
  <div class="ori-header">
    <div class="ori-header-column">
      <div
        class="ori-header-image-input"
        :class="{ clickable: canEdit && !disableActions, empty: !image }"
        @click="handleImageClick"
      >
        <img
          v-if="image"
          :src="image"
          alt=""
          class="ori-header-image-input-img"
        />
        <img
          v-else
          :src="
            require(`@/assets/icons/${
              canEdit && !disableActions ? 'plus-circle' : 'globe'
            }.svg`)
          "
          alt=""
          class="ori-header-image-input-icon"
        />
        <Tag
          v-if="image"
          text="Edit"
          type="black"
          size="xs"
          class="ori-header-image-input-edit"
        />
      </div>
    </div>
    <div class="ori-header-column">
      <div class="ori-header-title">
        {{ title }}
        <div v-if="canEdit" class="ori-header-status-wrapper">
          <StatusOrb :type="statusColor" :text="statusText" />
        </div>
      </div>
      <ORITagEdit
        :editable="canEdit"
        :selected="attributes"
        :loading="attributeEditLoading"
        trigger="button"
        @input="$emit('editAttributes', $event)"
      />
    </div>
    <div class="ori-header-btns">
      <QuestionAnswerORI :ori="ori" :type-name="typeName" />
      <router-link
        v-if="
          canEdit && isEmpty && !oriManagementEnabled && draftOriContentEnabled
        "
        :to="draftRoute"
      >
        <Button text="Draft" size="xs" type="primary" />
      </router-link>
      <Button
        v-if="canEdit && (isDraftingWithContent || isPublished)"
        :text="isPublished ? 'Unpublish' : 'Publish'"
        :icon="`${isPublished ? 'un' : ''}shift`"
        size="xs"
        type="white"
        :icon-left="true"
        @click="$emit('publish')"
      />
      <component
        :is="disableActions ? 'div' : 'router-link'"
        v-if="canEdit"
        :to="editRoute"
      >
        <b-tooltip
          :active="disableActions"
          :label="`Disabled until ${typeName} is created`"
          position="is-top"
          type="is-dark"
        >
          <Button
            text="Edit"
            icon="edit"
            type="white"
            size="xs"
            :disabled="disableActions"
          />
        </b-tooltip>
      </component>

      <b-tooltip
        v-if="canEdit"
        :active="disableActions"
        :label="`Disabled until ${typeName} is created`"
        position="is-top"
        type="is-dark"
      >
        <Button
          text="Delete"
          icon="bin"
          type="white"
          size="xs"
          :disabled="disableActions"
          @click="$emit('remove')"
        />
      </b-tooltip>
    </div>
  </div>
</template>

<script>
import Button from '@c/library/Button.vue'
import ORITagEdit from './ORITagEdit.vue'
import Tag from '@c/library/Tag.vue'
import { mapGetters } from 'vuex'
import StatusOrb from '@c/library/StatusOrb.vue'
import QuestionAnswerORI from './QuestionAnswerORI.vue'

export default {
  name: 'ORIHeader',
  components: { Button, ORITagEdit, Tag, StatusOrb, QuestionAnswerORI },
  props: {
    ori: {
      type: Object,
      default: () => ({})
    },
    canEdit: {
      type: Boolean,
      default: false
    },
    disableActions: {
      type: Boolean,
      default: false
    },
    typeName: {
      type: String,
      default: ''
    },
    attributeEditLoading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['oriManagementEnabled', 'draftOriContentEnabled']),
    title() {
      return this.ori?.name || ''
    },
    image() {
      return this.ori?.image || this.ori?.account?.organisation?.logo || ''
    },
    attributes() {
      return this.ori?.attributes || []
    },
    statusText() {
      if (this.isDrafting) return 'Drafting'
      if (this.isPublished) return 'Published'
      return 'No content'
    },
    statusColor() {
      if (this.isDrafting) return 'orange'
      if (this.isPublished) return 'green'
      return 'grey'
    },
    status() {
      return this.ori?.status || ''
    },
    isEmpty() {
      return this.status === ''
    },
    isDrafting() {
      return this.status === 'draft'
    },
    isDraftingWithContent() {
      return (
        this.isDrafting &&
        (!this.oriManagementEnabled || this.ori?.resources?.length)
      )
    },
    isPublished() {
      return this.status === 'published'
    },
    draftRoute() {
      return {
        name: `${this.$route.name}-create`,
        params: { ...this.$route.params }
      }
    },
    editRoute() {
      return {
        name: `${this.$route.name}-edit`,
        params: { ...this.$route.params }
      }
    }
  },
  methods: {
    handleImageClick() {
      if (!this.canEdit || this.disableActions) return
      this.$emit('editImage')
    }
  }
}
</script>

<style lang="scss" scoped>
.ori-header {
  display: flex;
  flex-flow: row nowrap;
  gap: 1.5rem;

  &-column {
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;
  }

  &-image {
    &-input {
      border-radius: 4px;
      overflow: hidden;
      border: 2px solid transparent;

      &.clickable {
        position: relative;
        cursor: pointer;
        transition: box-shadow 0.2s ease, box-shadow 0.2s ease;

        &:hover {
          border: 2px dashed rgba(#000, 0.16);
          box-shadow: 0px 4px 8px 0px rgba(#000, 0.08);

          & .ori-header-image-input-edit {
            opacity: 1;
          }
        }
      }

      &:not(.clickable) {
        pointer-events: none;
      }

      &.empty {
        height: 6rem;
        min-height: 6rem;
        width: 9rem;
        background: #f1f2f3;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &:not(.empty) {
        border: 2px solid rgba(#000, 0.08);
      }

      &-img {
        object-fit: contain;
        height: 6rem;
        min-height: 6rem;
        max-width: 9rem;
        display: block;
      }

      &-icon {
        min-height: 1.5rem;
        opacity: 0.35;
      }

      &-edit {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        transition: opacity 0.2s ease;
      }
    }
  }

  &-title {
    font-size: 1.75rem;
    font-weight: 700;
  }

  &-status-wrapper {
    display: inline-flex;
    padding-left: 0.5rem;
    transform: translateY(0.25rem);
  }

  &-btns {
    margin-left: auto;
    display: flex;
    flex-flow: row nowrap;
    gap: 1rem;
    height: fit-content;
  }
}

@media (max-width: 900px) {
  .ori-header-btns {
    flex-flow: row wrap;
  }
}
</style>
