<template>
  <ORILoading
    :loading="!!loading"
    :header="loadingHeader"
    :text="loadingText"
  />
</template>

<script>
import ORILoading from './ORILoading.vue'

export default {
  name: 'ORIActionsLoading',
  components: { ORILoading },
  props: {
    loading: {
      type: String,
      default: ''
    },
    typeName: {
      type: String,
      default: 'offering'
    }
  },
  computed: {
    loadingHeader() {
      return this.loading
        ? {
            create: `Finishing ${this.typeName} creation...`,
            edit: `Saving ${this.typeName} edit...`
          }[this.loading]
        : ''
    },
    loadingText() {
      return this.loading
        ? {
            create: 'This might take a few minutes',
            edit: ''
          }[this.loading]
        : ''
    }
  }
}
</script>

<style lang="scss" scoped></style>
