var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "views", class: { bordered: _vm.bordered } },
    _vm._l(_vm.views, function(v) {
      return _c(
        "div",
        {
          key: "view-button-" + (v.key || v),
          staticClass: "views-option",
          class: { selected: v.key === _vm.selected },
          on: {
            click: function() {
              return _vm.$emit("view", v)
            }
          }
        },
        [
          _c("img", {
            staticClass: "views-option-icon",
            attrs: {
              src: require("@/assets/view_type/view-" + (v.icon || v) + ".svg"),
              alt: ""
            }
          }),
          _vm.showText
            ? _c("p", { staticClass: "views-option-name" }, [
                _vm._v(" " + _vm._s((v.name || v).capitalize()) + " ")
              ])
            : _vm._e()
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }