var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ori-status-block" }, [
    _c("p", { staticClass: "ori-status-block-header" }, [
      _vm._v(_vm._s(_vm.header))
    ]),
    _c("p", { staticClass: "ori-status-block-subheader" }, [
      _vm._v(_vm._s(_vm.subheader))
    ]),
    _vm.isDrafting || _vm.canEdit
      ? _c(
          "div",
          { staticClass: "ori-status-block-footer" },
          [
            _vm.isDrafting
              ? _c("LoadingDots", { attrs: { animation: "flicker" } })
              : _vm.canEdit && _vm.draftOriContentEnabled
              ? _c(
                  "router-link",
                  { attrs: { to: _vm.createRoute } },
                  [
                    _c("Button", {
                      attrs: { text: "Finish " + _vm.typeName + " setup" }
                    })
                  ],
                  1
                )
              : _vm.canEdit && !_vm.draftOriContentEnabled
              ? _c("Button", {
                  attrs: { text: "Add content to " + _vm.typeName },
                  on: {
                    click: function() {
                      return _vm.$emit("linkResource")
                    }
                  }
                })
              : _vm._e()
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }