<template>
  <div class="ori-pills">
    <div
      v-for="option in extraFilters"
      :key="option.uuid"
      class="ori-pills-section"
    >
      <p class="ori-pills-section-title">{{ option.name }}</p>
      <Button
        v-for="value in option.values"
        :key="Array.isArray(value.uuid) ? value.uuid.join() : value.uuid"
        :text="value.value"
        :type="
          (
            Array.isArray(value.uuid)
              ? value.uuid.every((u) => selected.includes(u))
              : selected.includes(value.uuid)
          )
            ? 'light-solid'
            : 'white'
        "
        size="xs"
        @click="handleExtraPillSelect(option, value)"
      />
    </div>
    <div
      v-for="option in sortedOptions"
      :key="option.uuid"
      class="ori-pills-section"
    >
      <p class="ori-pills-section-title">{{ option.name }}</p>
      <Button
        v-for="value in option.values"
        :key="value.uuid"
        :text="value.value"
        :type="selected.includes(value.uuid) ? 'light-solid' : 'white'"
        size="xs"
        @click="handlePillSelect(value)"
      />
    </div>
  </div>
</template>

<script>
import Button from '@c/library/Button.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ORISectionPills',
  components: { Button },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: 'resources',
      validator: (value) => {
        return ['offerings', 'inspiration', 'references', 'resources'].includes(
          value
        )
      }
    },
    extraFilters: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters(['attributesLoaded']),
    selected() {
      return Object.keys(this.$route.query || {})
        .filter((k) => k.startsWith(`${this.type}-`))
        .reduce((acc, k) => {
          const sel = this.$route.query[k]
          return sel ? [...acc, ...(Array.isArray(sel) ? sel : [sel])] : acc
        }, [])
    },
    options() {
      return this.items.reduce((acc, item) => {
        if (!item.attributes) return acc
        const tags = item.attributes
        tags.forEach((tag) => {
          const idx = acc.findIndex((a) => a.uuid === tag.uuid)
          if (idx === -1) acc.push({ ...tag, values: [...tag.values] })
          else {
            const valueIds = acc[idx].values.map((v) => v.uuid)
            acc[idx].values = [
              ...acc[idx].values,
              ...tag.values.filter((v) => !valueIds.includes(v.uuid))
            ]
          }
        })
        return acc
      }, [])
    },
    sortedOptions() {
      return this.options.map((o) => ({
        ...o,
        values: [
          ...o.values.filter((v) => this.selected.includes(v.uuid)),
          ...o.values.filter((v) => !this.selected.includes(v.uuid))
        ]
      }))
    }
  },
  watch: {
    attributesLoaded(val) {
      if (val) this.submit()
    }
  },
  async created() {
    if (this.attributesLoaded) this.submit()
  },
  methods: {
    submit() {
      this.$emit('select', this.selected)
    },
    handleExtraPillSelect(filter, value) {
      let sel = this.$route.query[`${this.type}-${filter.uuid}`]
      sel = sel ? (Array.isArray(sel) ? sel : [sel]) : []
      const values = Array.isArray(value.uuid) ? value.uuid : [value.uuid]
      values.forEach((v) => {
        if (sel.includes(v)) {
          sel = sel.filter((s) => s !== v)
        } else {
          sel.push(v)
        }
      })
      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          [`${this.type}-${filter.uuid}`]: sel.length ? sel : undefined
        }
      })
      this.submit()
    },
    handlePillSelect(value) {
      let sel = [...(this.$route.query[`${this.type}-labels`] || [])]
      if (sel.includes(value.uuid)) {
        sel = sel.filter((s) => s !== value.uuid)
      } else {
        sel.push(value.uuid)
      }
      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          [`${this.type}-labels`]: sel.length ? sel : undefined
        }
      })
      this.submit()
    }
  }
}
</script>

<style scoped lang="scss">
.ori-pills {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 0.5rem;

  &-section {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 0.5rem;
    border-radius: 6px;
    background: #f1f2f3;
    padding: 0.5rem;

    &-title {
      font-weight: 600;
      font-size: 0.85rem;
      white-space: nowrap;
    }
  }
}
</style>
