var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ori-header" }, [
    _c("div", { staticClass: "ori-header-column" }, [
      _c(
        "div",
        {
          staticClass: "ori-header-image-input",
          class: {
            clickable: _vm.canEdit && !_vm.disableActions,
            empty: !_vm.image
          },
          on: { click: _vm.handleImageClick }
        },
        [
          _vm.image
            ? _c("img", {
                staticClass: "ori-header-image-input-img",
                attrs: { src: _vm.image, alt: "" }
              })
            : _c("img", {
                staticClass: "ori-header-image-input-icon",
                attrs: {
                  src: require("@/assets/icons/" +
                    (_vm.canEdit && !_vm.disableActions
                      ? "plus-circle"
                      : "globe") +
                    ".svg"),
                  alt: ""
                }
              }),
          _vm.image
            ? _c("Tag", {
                staticClass: "ori-header-image-input-edit",
                attrs: { text: "Edit", type: "black", size: "xs" }
              })
            : _vm._e()
        ],
        1
      )
    ]),
    _c(
      "div",
      { staticClass: "ori-header-column" },
      [
        _c("div", { staticClass: "ori-header-title" }, [
          _vm._v(" " + _vm._s(_vm.title) + " "),
          _vm.canEdit
            ? _c(
                "div",
                { staticClass: "ori-header-status-wrapper" },
                [
                  _c("StatusOrb", {
                    attrs: { type: _vm.statusColor, text: _vm.statusText }
                  })
                ],
                1
              )
            : _vm._e()
        ]),
        _c("ORITagEdit", {
          attrs: {
            editable: _vm.canEdit,
            selected: _vm.attributes,
            loading: _vm.attributeEditLoading,
            trigger: "button"
          },
          on: {
            input: function($event) {
              return _vm.$emit("editAttributes", $event)
            }
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ori-header-btns" },
      [
        _c("QuestionAnswerORI", {
          attrs: { ori: _vm.ori, "type-name": _vm.typeName }
        }),
        _vm.canEdit &&
        _vm.isEmpty &&
        !_vm.oriManagementEnabled &&
        _vm.draftOriContentEnabled
          ? _c(
              "router-link",
              { attrs: { to: _vm.draftRoute } },
              [
                _c("Button", {
                  attrs: { text: "Draft", size: "xs", type: "primary" }
                })
              ],
              1
            )
          : _vm._e(),
        _vm.canEdit && (_vm.isDraftingWithContent || _vm.isPublished)
          ? _c("Button", {
              attrs: {
                text: _vm.isPublished ? "Unpublish" : "Publish",
                icon: (_vm.isPublished ? "un" : "") + "shift",
                size: "xs",
                type: "white",
                "icon-left": true
              },
              on: {
                click: function($event) {
                  return _vm.$emit("publish")
                }
              }
            })
          : _vm._e(),
        _vm.canEdit
          ? _c(
              _vm.disableActions ? "div" : "router-link",
              { tag: "component", attrs: { to: _vm.editRoute } },
              [
                _c(
                  "b-tooltip",
                  {
                    attrs: {
                      active: _vm.disableActions,
                      label: "Disabled until " + _vm.typeName + " is created",
                      position: "is-top",
                      type: "is-dark"
                    }
                  },
                  [
                    _c("Button", {
                      attrs: {
                        text: "Edit",
                        icon: "edit",
                        type: "white",
                        size: "xs",
                        disabled: _vm.disableActions
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _vm.canEdit
          ? _c(
              "b-tooltip",
              {
                attrs: {
                  active: _vm.disableActions,
                  label: "Disabled until " + _vm.typeName + " is created",
                  position: "is-top",
                  type: "is-dark"
                }
              },
              [
                _c("Button", {
                  attrs: {
                    text: "Delete",
                    icon: "bin",
                    type: "white",
                    size: "xs",
                    disabled: _vm.disableActions
                  },
                  on: {
                    click: function($event) {
                      return _vm.$emit("remove")
                    }
                  }
                })
              ],
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }