var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "b-modal",
        {
          attrs: { active: _vm.visible, "can-cancel": ["escape", "outside"] },
          on: {
            close: function() {
              return _vm.$emit("close")
            }
          }
        },
        [
          _c("div", { staticClass: "upload-resource" }, [
            _c(
              "div",
              { staticClass: "upload-resource-header" },
              [
                _c("p", { staticClass: "upload-resource-header-title" }, [
                  _vm._v(_vm._s(_vm.header))
                ]),
                _c("Button", {
                  attrs: { icon: "close", type: "grey", size: "xs" },
                  on: {
                    click: function() {
                      return _vm.$emit("close")
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "upload-resource-content" },
              [
                _c("UploadDropzone", {
                  ref: "uploaddropzone",
                  attrs: {
                    disabled: _vm.loading,
                    "min-height": "40vh",
                    filetypes: [
                      "ppt",
                      "pptx",
                      "doc",
                      "docx",
                      "xls",
                      "xlsx",
                      "pdf",
                      "mp4",
                      "mov",
                      "qt"
                    ],
                    "max-size": _vm.maxSize,
                    "show-delete": function(f) {
                      return !_vm.uploadingNames.includes(f.name)
                    }
                  },
                  on: { upload: _vm.handleUpload, remove: _vm.removeFile },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "action",
                        fn: function(ref) {
                          var file = ref.file
                          return [
                            _vm.uploadingNames.includes(file.name)
                              ? _c("ProgressCircle", {
                                  attrs: {
                                    progress: _vm.uploading[file.name],
                                    "done-icon": "check-circle",
                                    size: "xxs"
                                  }
                                })
                              : _vm._e()
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    4254734464
                  )
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "upload-resource-footer" },
              [
                _c("Button", {
                  attrs: {
                    text: "Cancel",
                    type: "white",
                    disabled: _vm.loading
                  },
                  on: {
                    click: function() {
                      return _vm.$emit("close")
                    }
                  }
                }),
                _c("Button", {
                  attrs: {
                    text: "Upload",
                    disabled: !(_vm.files.length || _vm.ifiles.length),
                    loading: _vm.loading
                  },
                  on: { click: _vm.handleSubmit }
                })
              ],
              1
            )
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }