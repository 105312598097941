var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "portfolio-status" },
    [
      _vm.canEdit && (_vm.isDraftingWithContent || _vm.isPublished)
        ? _c("Button", {
            staticClass: "portfolio-status-background",
            attrs: {
              text: ((_vm.isPublished ? "un" : "") + "publish").capitalize(),
              size: "xs",
              loading: _vm.loading
            },
            on: { click: _vm.startPublish }
          })
        : _vm.canEdit &&
          _vm.isEmpty &&
          _vm.oriManagementEnabled &&
          _vm.draftOriContentEnabled
        ? _c(
            "router-link",
            { attrs: { to: _vm.draftRoute } },
            [
              _c("Button", {
                staticClass: "portfolio-status-background",
                attrs: { text: "Draft", size: "xs" }
              })
            ],
            1
          )
        : _c("StatusOrb", {
            staticClass: "portfolio-status-background",
            attrs: {
              type: _vm.statusColor,
              text: _vm.statusText,
              "show-text": true
            }
          }),
      _c("StatusOrb", {
        staticClass: "portfolio-status-hoverable",
        attrs: {
          type: _vm.statusColor,
          text: _vm.statusText,
          "show-text": true
        }
      }),
      _c(
        "b-modal",
        {
          model: {
            value: _vm.showConfirmModal,
            callback: function($$v) {
              _vm.showConfirmModal = $$v
            },
            expression: "showConfirmModal"
          }
        },
        [
          _c("div", { staticClass: "portfolio-status-confirm" }, [
            _c("p", { staticClass: "portfolio-status-confirm-title" }, [
              _vm._v(" Are you sure you want to "),
              _vm.isPublished ? _c("span", [_vm._v("un")]) : _vm._e(),
              _vm._v("publish this " + _vm._s(_vm.typeName) + "? ")
            ]),
            _c("p", { staticClass: "portfolio-status-confirm-sub" }, [
              _vm._v(
                " This will make the " +
                  _vm._s(_vm.typeName) +
                  " " +
                  _vm._s((_vm.isPublished ? "un" : "") + "available") +
                  " to others. "
              )
            ]),
            _c(
              "div",
              { staticClass: "portfolio-status-confirm-btns" },
              [
                _c("Button", {
                  attrs: {
                    text: "Cancel",
                    type: "white",
                    disabled: _vm.loading
                  },
                  on: {
                    click: function() {
                      return (_vm.showConfirmModal = false)
                    }
                  }
                }),
                _c("Button", {
                  attrs: { text: "Confirm", loading: _vm.loading },
                  on: { click: _vm.handlePublish }
                })
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }