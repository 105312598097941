var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: _vm.handleClickaway,
          expression: "handleClickaway"
        }
      ],
      staticClass: "parent-edit"
    },
    [
      _c("p", { staticClass: "parent-edit-title" }, [
        _vm._v("Offering parent")
      ]),
      _vm.parentLoading
        ? _c("b-skeleton", { attrs: { width: "100%", height: "2.5rem" } })
        : _c(
            "div",
            {
              staticClass: "parent-edit-trigger",
              on: { click: _vm.handleToggle }
            },
            [
              _c("span", [_vm._v(_vm._s(_vm.currentSelection))]),
              _c("img", {
                staticClass: "parent-edit-trigger-icon",
                attrs: {
                  src: require("@/assets/icons/chevron-down-medium.svg"),
                  alt: ""
                }
              }),
              _vm.expanded
                ? _c(
                    "div",
                    {
                      staticClass: "parent-edit-content-wrapper",
                      class: { "top-aligned": _vm.alignTop },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                        }
                      }
                    },
                    [
                      !_vm.itemsLoading
                        ? _c("TextInput", {
                            staticClass: "parent-edit-search",
                            attrs: { placeholder: "Find offering or category" },
                            model: {
                              value: _vm.query,
                              callback: function($$v) {
                                _vm.query = $$v
                              },
                              expression: "query"
                            }
                          })
                        : _vm._e(),
                      _vm.itemsLoading
                        ? _c(
                            "div",
                            { staticClass: "parent-edit-content-loading" },
                            [
                              _c("b-loading", {
                                attrs: { "is-full-page": false, active: "" }
                              })
                            ],
                            1
                          )
                        : _vm.visibleItems && _vm.visibleItems.length
                        ? _c("div", { staticClass: "parent-edit-content" }, [
                            _c(
                              "div",
                              _vm._l(_vm.visibleItems, function(item, idx) {
                                return _c(
                                  "div",
                                  {
                                    key: item.name + "-" + idx,
                                    staticClass: "parent-edit-content-item",
                                    class: {
                                      "has-divider":
                                        item.type === "category" &&
                                        _vm.visibleItems[idx + 1] &&
                                        _vm.visibleItems[idx + 1].type ===
                                          "offering"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.$emit("select", item)
                                      }
                                    }
                                  },
                                  [
                                    (idx === 0 && item.type === "category") ||
                                    (item.type === "offering" &&
                                      _vm.visibleItems[idx - 1] &&
                                      _vm.visibleItems[idx - 1].type ===
                                        "category")
                                      ? _c(
                                          "p",
                                          {
                                            staticClass:
                                              "parent-edit-content-item-header"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  item.type === "category"
                                                    ? "Categories"
                                                    : "Offerings"
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "parent-edit-content-item-name",
                                        class: {
                                          selected:
                                            item.uuid === _vm.parentId ||
                                            (_vm.category &&
                                              item.uuid === _vm.category.uuid)
                                        }
                                      },
                                      [_vm._v(" " + _vm._s(item.name) + " ")]
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          ])
                        : _vm.itemsError
                        ? _c("div", { staticClass: "parent-edit-empty" }, [
                            _vm._v(
                              " Something went wrong while trying to load the offerings, please try again later. "
                            )
                          ])
                        : _vm.visibleItems && !_vm.visibleItems.length
                        ? _c("div", { staticClass: "parent-edit-empty" }, [
                            _vm._v(
                              " No offerings or categories could be found, add offerings to your workspace to see them here. "
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ]
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }