var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "addcontent-wrapper" }, [
    _c(
      "div",
      { staticClass: "addcontent" },
      [
        _c("p", { staticClass: "addcontent-header" }, [
          _c("span", [_vm._v("Add content to " + _vm._s(_vm.ori.name))]),
          _c("span", { staticClass: "addcontent-header-sub" }, [
            _c("img", {
              staticClass: "addcontent-header-icon",
              class: { filled: _vm.selectedUUIDs.length },
              attrs: {
                src: require("@/assets/icons/check-circle-filled.svg"),
                alt: ""
              }
            }),
            _vm._v(_vm._s(_vm.selectedUUIDs.length) + " items selected")
          ])
        ]),
        _c("SearchBar", {
          staticClass: "addcontent-searchbar",
          attrs: { placeholder: "Search...", rounded: false },
          on: {
            historyItemClick: _vm.search,
            autocompleteItemClick: _vm.search,
            clear: _vm.handleClear
          },
          nativeOn: {
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              if (
                $event.ctrlKey ||
                $event.shiftKey ||
                $event.altKey ||
                $event.metaKey
              ) {
                return null
              }
              return _vm.search.apply(null, arguments)
            }
          },
          model: {
            value: _vm.searchQuery,
            callback: function($$v) {
              _vm.searchQuery = $$v
            },
            expression: "searchQuery"
          }
        }),
        _c(
          "div",
          { staticClass: "addcontent-pills" },
          _vm._l(_vm.pills, function(pill) {
            return _c("Tag", {
              key: pill.key,
              staticClass: "addcontent-pills-pill",
              attrs: {
                text: pill.text,
                type: pill.key === _vm.selectedPill ? "light" : "white"
              },
              nativeOn: {
                click: function($event) {
                  return _vm.handlePillSelect(pill.key)
                }
              }
            })
          }),
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "infinite-scroll",
                rawName: "v-infinite-scroll",
                value: _vm.handleInfiniteScroll,
                expression: "handleInfiniteScroll"
              }
            ],
            staticClass: "addcontent-list",
            attrs: {
              "infinite-scroll-disabled": _vm.loadingOrDone,
              "infinite-scroll-distance": "100",
              "infinite-scroll-throttle-delay": "500"
            }
          },
          [
            _vm._l(_vm.resources, function(resource, idx) {
              return _c("SelectableResource", {
                key: resource.uuid,
                staticClass: "addcontent-list-item",
                attrs: {
                  resource: resource,
                  rank: idx + 1,
                  "show-slide-collect": false,
                  "title-clickable": false,
                  location: _vm.type + " resource management modal",
                  selected: _vm.selectedUUIDs.includes(resource.uuid)
                },
                nativeOn: {
                  click: function($event) {
                    return _vm.handleResourceClick(resource)
                  }
                }
              })
            }),
            _vm.consumptionLoading
              ? _c(
                  "div",
                  { staticClass: "addcontent-loading" },
                  [
                    _c("b-loading", {
                      attrs: { active: "", "is-full-page": false }
                    })
                  ],
                  1
                )
              : _vm._e()
          ],
          2
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "addcontent-btns" },
      [
        _c("Button", {
          attrs: { text: "Cancel", type: "white" },
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        }),
        _c("Button", {
          attrs: {
            text: "Add resources",
            disabled: _vm.selectedUUIDs.length === 0,
            loading: _vm.loading
          },
          on: { click: _vm.handleSubmit }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }