var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Dropdown", {
    attrs: {
      items: _vm.possibleValues,
      disabled: !_vm.canEdit,
      "visualise-disabled": false
    },
    scopedSlots: _vm._u([
      {
        key: "trigger",
        fn: function() {
          return [
            _c(
              "div",
              {
                staticClass: "portfolio-attributes",
                class: { empty: _vm.attributesEmpty, "can-edit": _vm.canEdit }
              },
              [
                _vm._l(_vm.values, function(value) {
                  return _c("Tag", {
                    key: value.uuid,
                    attrs: { text: value.value, size: "xs", type: "white" }
                  })
                }),
                _vm.attributesEmpty
                  ? _c("img", {
                      staticClass: "portfolio-attributes-add",
                      attrs: {
                        src: require("@/assets/icons/plus-medium.svg"),
                        alt: ""
                      }
                    })
                  : _vm._e(),
                _vm.loading
                  ? _c("div", { staticClass: "portfolio-attributes-loading" }, [
                      _c("img", {
                        staticClass: "portfolio-attributes-loading-icon",
                        attrs: {
                          src: require("@/assets/icons/spinner.svg"),
                          alt: ""
                        }
                      })
                    ])
                  : _vm._e()
              ],
              2
            )
          ]
        },
        proxy: true
      },
      {
        key: "item",
        fn: function(ref) {
          var value = ref.item
          return [
            _c(
              "div",
              {
                staticClass: "portfolio-attributes-item",
                class: { selected: _vm.selected(value) }
              },
              [
                _c("img", {
                  staticClass: "portfolio-attributes-item-icon",
                  attrs: {
                    src: require("@/assets/icons/" +
                      (_vm.selected(value) ? "minus" : "plus") +
                      "-medium.svg"),
                    alt: ""
                  }
                }),
                _vm.selected(value)
                  ? _c("span", [_vm._v("Remove label:")])
                  : _vm._e(),
                _c("span", [_vm._v(_vm._s(value.value))])
              ]
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }