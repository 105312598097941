<template>
  <component :is="clickable ? 'router-link' : 'div'" :to="route">
    <div class="reference" :class="{ clickable }">
      <img
        v-if="image && !imageErrored"
        class="reference-image"
        :src="image"
        alt=""
      />
      <div
        v-else
        class="reference-image"
        :style="{ background: $umodel.user_color(reference) }"
      >
        {{ $umodel.initials(reference.name) }}
      </div>
      <div class="reference-content">
        <div class="reference-name-wrapper">
          <p class="reference-name">
            {{ reference.name }}
          </p>
          <StatusOrb
            v-if="showStatus"
            :type="
              reference.status
                ? reference.status === 'published'
                  ? 'green'
                  : 'orange'
                : 'grey'
            "
            size="xs"
            :text="reference.status.capitalize() || 'No content'"
            class="reference-name-status"
          />
        </div>
        <ORITagEdit
          v-if="attributes.length"
          :selected="attributes"
          :editable="false"
          :overflowing="true"
          tag-size="xxs"
        />
        <p v-if="clickable" class="reference-link">Read more →</p>
      </div>
      <Tag
        :text="confidentiality.capitalize()"
        :type="confidentiality === 'internal' ? 'black' : 'light-solid'"
        :icon="`eye-${confidentiality === 'internal' ? 'close' : 'open'}`"
        :icon-left="true"
        size="xxs"
        class="reference-conf"
      />
    </div>
  </component>
</template>

<script>
import Tag from '@c/library/Tag.vue'
import StatusOrb from '@c/library/StatusOrb.vue'
import ORITagEdit from '../ori/ORITagEdit.vue'

export default {
  name: 'ReferenceListItem',
  components: { ORITagEdit, StatusOrb, Tag },
  props: {
    reference: {
      type: Object,
      required: true
    },
    clickable: {
      type: Boolean,
      default: false
    },
    openExternal: {
      type: Boolean,
      default: false
    },
    showStatus: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    imageErrored: false
  }),
  computed: {
    image() {
      const org = this.reference.account?.organisation
      return this.reference.image || org?.icon || org?.logo || ''
    },
    attributes() {
      return this.reference?.attributes || []
    },
    confidentiality() {
      return this.reference.confidentiality || 'external'
    },
    route() {
      if (!this.clickable) return null
      return {
        name: 'portfolio',
        params: {
          workspace_id: this.$route.params.workspace_id,
          ori_type: 'references',
          ori_id: this.reference.uuid
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.reference {
  display: flex;
  flex-flow: row nowrap;
  gap: 1.75rem;
  padding: 1.15rem 1.75rem;
  border-radius: 8px;
  border: 1px solid rgba(#000, 8%);

  &.clickable {
    cursor: pointer;

    &:hover {
      border: 1px solid rgba(#000, 16%);
      box-shadow: 0px 4px 8px 0px rgba(#000, 10%);
    }
  }

  &-image {
    width: 4rem;
    min-width: 4rem;
    max-width: 4rem;
    height: 4rem;
    min-height: 4rem;
    max-height: 4rem;
    border-radius: 999rem;
    object-fit: cover;
    overflow: hidden;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 1.5rem;
  }

  &-content {
    display: flex;
    flex-flow: column nowrap;
    gap: 0.85rem;
  }

  &-name {
    font-size: 1.25rem;
    font-weight: 600;
    display: inline;

    &-status {
      display: inline-flex;
      transform: translateY(2px);
    }
  }

  &-link {
    color: $primary;
    cursor: pointer;
    width: fit-content;

    &:hover {
      text-decoration: underline;
    }
  }

  &-conf {
    margin-left: auto;
    margin-top: auto;
  }
}
</style>
