var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.clickable ? "router-link" : "div",
    { tag: "component", attrs: { to: _vm.route } },
    [
      _c(
        "div",
        { staticClass: "reference", class: { clickable: _vm.clickable } },
        [
          _vm.image && !_vm.imageErrored
            ? _c("img", {
                staticClass: "reference-image",
                attrs: { src: _vm.image, alt: "" }
              })
            : _c(
                "div",
                {
                  staticClass: "reference-image",
                  style: { background: _vm.$umodel.user_color(_vm.reference) }
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$umodel.initials(_vm.reference.name)) + " "
                  )
                ]
              ),
          _c(
            "div",
            { staticClass: "reference-content" },
            [
              _c(
                "div",
                { staticClass: "reference-name-wrapper" },
                [
                  _c("p", { staticClass: "reference-name" }, [
                    _vm._v(" " + _vm._s(_vm.reference.name) + " ")
                  ]),
                  _vm.showStatus
                    ? _c("StatusOrb", {
                        staticClass: "reference-name-status",
                        attrs: {
                          type: _vm.reference.status
                            ? _vm.reference.status === "published"
                              ? "green"
                              : "orange"
                            : "grey",
                          size: "xs",
                          text:
                            _vm.reference.status.capitalize() || "No content"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm.attributes.length
                ? _c("ORITagEdit", {
                    attrs: {
                      selected: _vm.attributes,
                      editable: false,
                      overflowing: true,
                      "tag-size": "xxs"
                    }
                  })
                : _vm._e(),
              _vm.clickable
                ? _c("p", { staticClass: "reference-link" }, [
                    _vm._v("Read more →")
                  ])
                : _vm._e()
            ],
            1
          ),
          _c("Tag", {
            staticClass: "reference-conf",
            attrs: {
              text: _vm.confidentiality.capitalize(),
              type:
                _vm.confidentiality === "internal" ? "black" : "light-solid",
              icon:
                "eye-" +
                (_vm.confidentiality === "internal" ? "close" : "open"),
              "icon-left": true,
              size: "xxs"
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }