<template>
  <div class="ori-sidebar">
    <div class="ori-sidebar-owners">
      <p class="ori-sidebar-owners-header">
        <span>Owners</span>
        <span
          v-if="canEdit && !disableActions"
          class="ori-sidebar-owners-header-edit"
          @click="$emit('editOwners')"
          >Edit</span
        >
      </p>
      <p v-if="!visibleOwners.length" class="ori-sidebar-owners-empty">
        No owners selected
      </p>
      <div
        v-for="owner in visibleOwners"
        :key="owner.uuid"
        class="ori-sidebar-owners-owner"
      >
        <Avatar :user="owner" size="s" />
        {{ $umodel.full_name(owner) }}
      </div>
    </div>
    <div class="ori-sidebar-overview">
      <p
        v-for="(item, idx) in overview"
        :key="item"
        class="ori-sidebar-overview-item"
        @click="$emit('overviewClick', idx)"
      >
        {{ item }}
      </p>
    </div>
  </div>
</template>

<script>
import Avatar from '@c/library/Avatar.vue'

export default {
  name: 'ORISidebar',
  components: { Avatar },
  props: {
    overview: {
      type: Array,
      default: () => []
    },
    canEdit: {
      type: Boolean,
      default: false
    },
    owners: {
      type: Array,
      default: () => []
    },
    disableActions: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    visibleOwners() {
      return (this.owners || []).filter((o) => !o.is_inherited)
    }
  }
}
</script>

<style lang="scss" scoped>
.ori-sidebar {
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;

  &-overview {
    position: sticky;
    top: 0;

    &-item {
      cursor: pointer;
      color: #8f9399;
      transition: color 0.2s ease;
      border-left: 1px solid rgba(#000, 8%);
      padding: 0.25rem 0 0.25rem 1.75rem;

      &:hover {
        color: $primary;
      }

      &:first-child {
        color: #303032;
        font-size: 1.2rem;
        padding: 0.5rem 0;
        border-left: none;

        &:hover {
          color: $primary;
        }
      }
    }
  }

  &-owners {
    display: flex;
    flex-flow: column nowrap;
    gap: 0.5rem;

    &:hover {
      & .ori-sidebar-owners-header-edit {
        opacity: 1;
      }
    }

    &-header {
      font-weight: 600;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 1rem;

      &-edit {
        opacity: 0;
        transition: opacity 0.2s ease;
        cursor: pointer;
        color: $primary;
        font-weight: 400;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &-empty {
      color: #60666b;
    }

    &-owner {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 0.5rem;
      padding: 0.5rem;
    }
  }
}

@media only screen and (max-width: 80rem) {
  .ori-sidebar {
    flex-flow: row-reverse nowrap;
    justify-content: flex-end;
    gap: 3rem;
  }
  .ori-sidebar-overview-item:first-child {
    display: none;
  }
}
</style>
