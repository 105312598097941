var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "infinite-scroll",
          rawName: "v-infinite-scroll",
          value: _vm.handleInfiniteScroll,
          expression: "handleInfiniteScroll"
        }
      ],
      staticClass: "ori-modal-list",
      attrs: {
        "infinite-scroll-disabled": _vm.disableInfiniteScroll,
        "infinite-scroll-distance": "100",
        "infinite-scroll-throttle-delay": "500"
      }
    },
    [
      _c(
        "div",
        { staticClass: "ori-modal-list-content" },
        _vm._l(_vm.items || [], function(item) {
          return _c(
            "div",
            { key: item.uuid, staticClass: "ori-modal-list-item-wrapper" },
            [
              _c(
                _vm.card,
                _vm._b(
                  { tag: "component", staticClass: "ori-modal-list-item" },
                  "component",
                  _vm.cardProps(item),
                  false
                )
              ),
              _c("Button", {
                staticClass: "ori-modal-list-item-btn",
                attrs: {
                  text:
                    "Add" + (_vm.selectedUuids.includes(item.uuid) ? "ed" : ""),
                  type: _vm.selectedUuids.includes(item.uuid)
                    ? "light-solid"
                    : "primary",
                  loading: _vm.selecting.includes(item.uuid),
                  size: "xs"
                },
                on: {
                  click: function($event) {
                    return _vm.$emit("select", item)
                  }
                }
              })
            ],
            1
          )
        }),
        0
      ),
      _vm.loading
        ? _c(
            "div",
            { staticClass: "ori-modal-list-loading" },
            [_c("b-loading", { attrs: { active: "", "is-full-page": false } })],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }