import Vue from 'vue'

export const MaxWidthMixin = Vue.extend({
  data() {
    return {
      maxWidth: 0
    }
  },

  computed: {
    maxWidthString() {
      return this.maxWidth ? `${this.maxWidth}px` : ''
    },
    maxWidthStyle() {
      return this.maxWidth
        ? { width: this.maxWidthString, maxWidth: this.maxWidthString }
        : {}
    }
  },

  mounted() {
    this.checkMaxWidth()
    window.addEventListener('resize', this.checkMaxWidth)
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.checkMaxWidth)
  },

  methods: {
    getMaxWidthElement() {
      return undefined
    },
    getMaxWidthEndElement() {
      return undefined
    },
    checkMaxWidth() {
      const maxWidthElement = this.getMaxWidthElement()
      if (!maxWidthElement) {
        this.$console.debug(
          'No element found to check max width for, skipping max width check'
        )
        return
      }
      const maxWidthEndElement = this.getMaxWidthEndElement()

      const from = maxWidthElement.getBoundingClientRect()
      const to = maxWidthEndElement
        ? maxWidthEndElement.getBoundingClientRect()
        : document.body.getBoundingClientRect()

      if (!from || !to) {
        this.$console.debug(
          "Couldn't get bounding client rect for elements, skipping max width check"
        )
        return
      }

      this.maxWidth = to.width - (from.left - to.left)
    }
  }
})
