var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.clickable ? "router-link" : "div",
    { tag: "component", attrs: { to: _vm.route } },
    [
      _c(
        "div",
        { staticClass: "offering", class: { clickable: _vm.clickable } },
        [
          _vm.image && !_vm.imageErrored
            ? _c("img", {
                staticClass: "offering-image",
                attrs: { src: _vm.image, alt: "" }
              })
            : _c(
                "div",
                {
                  staticClass: "offering-image",
                  style: { background: _vm.$umodel.user_color(_vm.offering) }
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$umodel.initials(_vm.offering.name)) + " "
                  )
                ]
              ),
          _c(
            "div",
            { staticClass: "offering-content" },
            [
              _c("div", { staticClass: "offering-name" }, [
                _vm._v(" " + _vm._s(_vm.offering.name) + " ")
              ]),
              _vm.attributes.length
                ? _c("ORITagEdit", {
                    attrs: { selected: _vm.attributes, editable: false }
                  })
                : _vm._e(),
              _vm.clickable
                ? _c("p", { staticClass: "offering-link" }, [
                    _vm._v("Read more →")
                  ])
                : _vm._e()
            ],
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }