var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading
    ? _c("b-modal", { attrs: { active: "", "can-cancel": false } }, [
        _c("div", { staticClass: "ori-loading" }, [
          _c("img", {
            staticClass: "ori-loading-image",
            attrs: { src: require("@/assets/ori_draft_loading.svg"), alt: "" }
          }),
          _c("div", { staticClass: "ori-loading-content" }, [
            _c("p", { staticClass: "ori-loading-header" }, [
              _vm._v(_vm._s(_vm.header))
            ]),
            _c("p", { staticClass: "ori-loading-text" }, [
              _vm._v(_vm._s(_vm.text))
            ]),
            _c(
              "div",
              { staticClass: "ori-loading-loader" },
              [
                _c("b-loading", {
                  attrs: { "is-full-page": false, active: "" }
                })
              ],
              1
            )
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }