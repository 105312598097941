<template>
  <div class="portfolio-item">
    <PortfolioItemName
      v-if="header.id === 'name'"
      :item="item"
      :type="type"
      v-on="$listeners"
    />
    <PortfolioItemOwners
      v-else-if="header.id === 'owners'"
      :item="item"
      :type="type"
      :can-edit="canEdit"
      @edit="(item) => $emit('edit', item)"
    />
    <PortfolioItemParent
      v-else-if="header.id === 'parent'"
      :item="item"
      :can-edit="canEdit"
      @edit="(item) => $emit('edit', item)"
    />
    <PortfolioItemResources
      v-else-if="
        ['content', 'offerings', 'references', 'inspirations'].includes(
          header.id
        )
      "
      :item="item"
      :header="header"
    />
    <PortfolioItemStatus
      v-else-if="header.id === 'status'"
      :item="item"
      :header="header"
      :type="type"
      :can-edit="canEdit"
      @publish="(item) => $emit('edit', item)"
    />
    <PortfolioItemAttributes
      v-else-if="header.id.startsWith('attribute')"
      :item="item"
      :attribute-id="header.id.split('--')[1]"
      :can-edit="canEdit"
      :type="type"
      @edit="(item) => $emit('edit', item)"
    />
    <PortfolioItemConfidentiality
      v-else-if="header.id === 'confidentiality'"
      :item="item"
    />
    <div v-else>
      {{ header.key(item) }}
    </div>
  </div>
</template>

<script>
import PortfolioItemName from './PortfolioItemName.vue'
import PortfolioItemOwners from './PortfolioItemOwners.vue'
import PortfolioItemParent from './PortfolioItemParent.vue'
import PortfolioItemStatus from './PortfolioItemStatus.vue'
import PortfolioItemResources from './PortfolioItemResources.vue'
import PortfolioItemAttributes from './PortfolioItemAttributes.vue'
import PortfolioItemConfidentiality from './PortfolioItemConfidentiality.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'PortfolioItem',
  components: {
    PortfolioItemName,
    PortfolioItemOwners,
    PortfolioItemParent,
    PortfolioItemStatus,
    PortfolioItemResources,
    PortfolioItemAttributes,
    PortfolioItemConfidentiality
  },
  props: {
    header: {
      type: Object,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters(['canWriteOri', 'currentWorkspaceMember']),
    isOwner() {
      return (this.item.owners || []).some(
        (owner) => owner.uuid === this.currentWorkspaceMember.uuid
      )
    },
    canEdit() {
      return this.canWriteOri || this.isOwner
    }
  }
}
</script>

<style lang="scss" scoped></style>
