var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.canWriteOri || _vm.isOwner
    ? _c(
        "div",
        {
          ref: "bootstrapcontainer",
          staticClass: "ori-bootstrap",
          style: Object.assign({}, _vm.heightStyle)
        },
        [
          _c(
            "div",
            { staticClass: "ori-bootstrap-content" },
            [
              _vm.title
                ? _c("p", { staticClass: "ori-bootstrap-header" }, [
                    _vm._v(_vm._s(_vm.title))
                  ])
                : _c("b-skeleton", {
                    staticClass: "ori-bootstrap-header",
                    attrs: { width: "20rem", height: "1.75rem" }
                  }),
              _vm.isEdit
                ? _c("ORIEdit", {
                    ref: "oriedit",
                    staticClass: "ori-bootstrap-content-step",
                    attrs: { item: _vm.item, type: _vm.type },
                    on: {
                      submitLoading: function($event) {
                        _vm.loadingSubmit = $event
                      },
                      submit: _vm.editItem
                    }
                  })
                : _c("ORIDraftInput", {
                    ref: "oridraft",
                    staticClass: "ori-bootstrap-content-step",
                    attrs: { type: _vm.type, ori: _vm.item },
                    on: {
                      submitDisabled: function($event) {
                        _vm.disableSubmit = $event
                      },
                      submit: _vm.createItem
                    }
                  })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "ori-bootstrap-footer" },
            [
              _c("Button", {
                attrs: {
                  text: "Cancel",
                  type: "white",
                  disabled: !_vm.title,
                  loading: !!_vm.loading
                },
                on: { click: _vm.handleCancel }
              }),
              _c("Button", {
                attrs: {
                  text: _vm.primaryButtonText,
                  disabled: !_vm.title || _vm.disableSubmit,
                  loading: !!_vm.loading || _vm.loadingSubmit
                },
                on: { click: _vm.handlePrimaryClick }
              })
            ],
            1
          ),
          _c("ORIActionsLoading", {
            attrs: { loading: _vm.loading, "type-name": _vm.typeName }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }