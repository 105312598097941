var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ori-props-bar",
      class: { "contents-full": _vm.contentLength > 3 }
    },
    [
      _vm.hasOrganisation
        ? _c(
            "div",
            { staticClass: "ori-props-bar-org" },
            [
              _c("Avatar", {
                attrs: {
                  user: {
                    avatar: _vm.organisation.icon || _vm.organisation.logo,
                    username: _vm.organisation.name
                  },
                  size: "l"
                }
              }),
              _c(
                "div",
                { staticClass: "ori-props-bar-info" },
                [
                  _c("span", { staticClass: "ori-props-bar-info-title" }, [
                    _vm._v(_vm._s(_vm.organisationTitle))
                  ]),
                  _c(
                    "b-tooltip",
                    {
                      attrs: {
                        label: _vm.shortenedOrganisationDomain,
                        position: "is-top",
                        type: "is-dark",
                        active: !!_vm.organisation.domain
                      }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "ori-props-bar-info-name",
                          class: { unclickable: !_vm.organisation.domain },
                          on: {
                            click: function($event) {
                              return _vm.openExternal(_vm.organisation.domain)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.organisation.name))]
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.hasUrl
        ? _c(
            "div",
            { staticClass: "ori-props-bar-info" },
            [
              _c("span", { staticClass: "ori-props-bar-info-title" }, [
                _vm._v(_vm._s(_vm.urlTitle))
              ]),
              _c(
                "b-tooltip",
                {
                  attrs: {
                    label: _vm.shortenedUrl,
                    position: "is-top",
                    type: "is-dark"
                  }
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "ori-props-bar-info-name",
                      on: {
                        click: function($event) {
                          return _vm.openExternal(_vm.url)
                        }
                      }
                    },
                    [_vm._v("Visit website")]
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.hasConfidentiality
        ? _c(
            "div",
            { staticClass: "ori-props-bar-info" },
            [
              _c("span", { staticClass: "ori-props-bar-info-title" }, [
                _vm._v(_vm._s(_vm.confidentialityTitle))
              ]),
              _c("Tag", {
                attrs: {
                  text: _vm.confidentiality.capitalize(),
                  type: _vm.confidentialityButtonType,
                  icon: _vm.confidentialityIcon,
                  "icon-left": true,
                  size: "xxs"
                }
              })
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }