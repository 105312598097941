<template>
  <div
    v-infinite-scroll="handleInfiniteScroll"
    :infinite-scroll-disabled="disableInfiniteScroll"
    infinite-scroll-distance="100"
    infinite-scroll-throttle-delay="500"
    class="ori-modal-list"
  >
    <div class="ori-modal-list-content">
      <div
        v-for="item in items || []"
        :key="item.uuid"
        class="ori-modal-list-item-wrapper"
      >
        <component
          :is="card"
          v-bind="cardProps(item)"
          class="ori-modal-list-item"
        />
        <Button
          :text="`Add${selectedUuids.includes(item.uuid) ? 'ed' : ''}`"
          :type="selectedUuids.includes(item.uuid) ? 'light-solid' : 'primary'"
          :loading="selecting.includes(item.uuid)"
          class="ori-modal-list-item-btn"
          size="xs"
          @click="$emit('select', item)"
        />
      </div>
    </div>
    <div v-if="loading" class="ori-modal-list-loading">
      <b-loading active :is-full-page="false" />
    </div>
  </div>
</template>

<script>
import OfferingListItem from '../offerings/OfferingListItem.vue'
import ReferenceListItem from '../references/ReferenceListItem.vue'
import InspirationListItem from '../inspirations/InspirationListItem.vue'
import Button from '@c/library/Button.vue'

export default {
  name: 'LinkORIModalContent',
  components: { Button },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: 'offering',
      validator: (value) =>
        ['offering', 'reference', 'inspiration'].includes(value)
    },
    loading: {
      type: Boolean,
      default: false
    },
    selectedUuids: {
      type: Array,
      default: () => []
    },
    disableInfiniteScroll: {
      type: Boolean,
      default: false
    },
    pagesLoaded: {
      type: Number,
      default: 0
    },
    selecting: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    card() {
      return {
        offering: OfferingListItem,
        reference: ReferenceListItem,
        inspiration: InspirationListItem
      }[this.type]
    }
  },
  methods: {
    cardProps(item) {
      return {
        [this.type]: item
      }
    },
    handleInfiniteScroll() {
      this.$emit('loadMore')
    }
  }
}
</script>

<style lang="scss" scoped>
.ori-modal {
  &-list {
    position: relative;
    max-height: 40vh;
    overflow: auto;
    width: 100%;

    &-content {
      display: flex;
      flex-flow: column nowrap;
      gap: 0.5rem;
    }

    &-item {
      &.selected {
        border: 2px solid $primary;
      }

      &-wrapper {
        position: relative;
      }

      &-btn {
        position: absolute;
        top: 1.15rem;
        right: 1.75rem;
      }
    }

    &-loading {
      position: relative;
      min-height: 5rem;
    }
  }
}
</style>
