<template>
  <Dropdown
    :items="possibleValues"
    :disabled="!canEdit"
    :visualise-disabled="false"
  >
    <template #trigger>
      <div
        class="portfolio-attributes"
        :class="{ empty: attributesEmpty, 'can-edit': canEdit }"
      >
        <Tag
          v-for="value in values"
          :key="value.uuid"
          :text="value.value"
          size="xs"
          type="white"
        />
        <img
          v-if="attributesEmpty"
          src="@/assets/icons/plus-medium.svg"
          alt=""
          class="portfolio-attributes-add"
        />
        <div v-if="loading" class="portfolio-attributes-loading">
          <img
            src="@/assets/icons/spinner.svg"
            alt=""
            class="portfolio-attributes-loading-icon"
          />
        </div>
      </div>
    </template>
    <template #item="{ item: value }">
      <div
        class="portfolio-attributes-item"
        :class="{ selected: selected(value) }"
      >
        <img
          :src="
            require(`@/assets/icons/${
              selected(value) ? 'minus' : 'plus'
            }-medium.svg`)
          "
          alt=""
          class="portfolio-attributes-item-icon"
        />
        <span v-if="selected(value)">Remove label:</span>
        <span>{{ value.value }}</span>
      </div>
    </template>
  </Dropdown>
</template>

<script>
import Dropdown from '@c/library/Dropdown.vue'
import Tag from '@c/library/Tag.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'PortfolioItemAttributes',
  components: { Dropdown, Tag },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    attributeId: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'offerings'
    },
    canEdit: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loading: false
  }),
  computed: {
    ...mapGetters(['attributes']),
    values() {
      return this.item.attributes?.find((a) => a.uuid === this.attributeId)
        ?.values
    },
    possibleValues() {
      return this.attributes
        .find((a) => a.uuid === this.attributeId)
        ?.values.map((v) => ({
          ...v,
          callback: () => this.toggleAttributeValue(v)
        }))
    },
    attributesEmpty() {
      return !this.values?.length
    }
  },
  methods: {
    ...mapActions([
      'editOfferingAttributes',
      'editReferenceAttributes',
      'editInspirationAttributes'
    ]),
    selected(value) {
      return this.values?.some((v) => v.uuid === value.uuid)
    },
    async toggleAttributeValue(value) {
      try {
        this.loading = true
        let attribute_value_ids = this.item.attributes.reduce((acc, curr) => {
          acc.push(...curr.values.map((v) => v.uuid))
          return acc
        }, [])
        if (this.selected(value)) {
          attribute_value_ids = attribute_value_ids.filter(
            (v) => v !== value.uuid
          )
        } else {
          attribute_value_ids.push(value.uuid)
        }
        const attributeFunction = {
          offerings: this.editOfferingAttributes,
          references: this.editReferenceAttributes,
          inspirations: this.editInspirationAttributes
        }[this.type]
        const res = await attributeFunction({
          workspace_id: this.$route.params.workspace_id,
          ori_id: this.item.uuid,
          attribute_value_ids,
          act_as: this.$route.query.act_as
        })
        this.$toast.success('Label successfully edited')
        this.$emit('edit', res)
      } catch (e) {
        this.$console.debug('Label editing failed', e)
        this.$toast.error(e, 'editing this label')
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.portfolio-attributes {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;
  width: fit-content;
  border-radius: 4px;
  transition: background 0.2s ease;
  border: 1px solid transparent;

  &.can-edit {
    cursor: pointer;

    &:hover {
      background: rgba(#000, 0.08);
    }

    &.empty {
      &:hover {
        border: 1px dashed rgba(#000, 0.08);

        & .portfolio-attributes-add {
          opacity: 0.5;
        }
      }
    }
  }

  &.empty {
    width: 100%;
    height: 2.5rem;
    min-height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:not(.empty) {
    padding: 0.5rem;
  }

  &-item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;

    &.selected {
      font-weight: 600;
    }

    &-icon {
      height: 1rem;
    }
  }

  &-add {
    opacity: 0;
    transition: opacity 0.2s ease;
    height: 1.35rem;
    width: 1.35rem;
  }

  &-loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(#fff, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;

    &-icon {
      height: 1.5rem;
      animation: spin 1s linear infinite;
      opacity: 0.5;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
