<template>
  <div
    v-if="canWriteOri || isOwner"
    ref="bootstrapcontainer"
    class="ori-bootstrap"
    :style="{ ...heightStyle }"
  >
    <div class="ori-bootstrap-content">
      <p v-if="title" class="ori-bootstrap-header">{{ title }}</p>
      <b-skeleton
        v-else
        width="20rem"
        height="1.75rem"
        class="ori-bootstrap-header"
      />
      <ORIEdit
        v-if="isEdit"
        ref="oriedit"
        :item="item"
        :type="type"
        class="ori-bootstrap-content-step"
        @submitLoading="loadingSubmit = $event"
        @submit="editItem"
      />
      <ORIDraftInput
        v-else
        ref="oridraft"
        :type="type"
        :ori="item"
        class="ori-bootstrap-content-step"
        @submitDisabled="disableSubmit = $event"
        @submit="createItem"
      />
    </div>
    <div class="ori-bootstrap-footer">
      <Button
        text="Cancel"
        type="white"
        :disabled="!title"
        :loading="!!loading"
        @click="handleCancel"
      />
      <Button
        :text="primaryButtonText"
        :disabled="!title || disableSubmit"
        :loading="!!loading || loadingSubmit"
        @click="handlePrimaryClick"
      />
    </div>
    <ORIActionsLoading :loading="loading" :type-name="typeName" />
  </div>
</template>

<script>
import Button from '@c/library/Button.vue'
import ORIDraftInput from './ORIDraftInput.vue'
import { mapActions, mapGetters } from 'vuex'
import ORIEdit from './ORIEdit.vue'
import ORIActionsLoading from './ORIActionsLoading.vue'

export default {
  name: 'ORIActions',
  components: { ORIDraftInput, Button, ORIEdit, ORIActionsLoading },
  props: {
    item: {
      type: Object,
      default: () => undefined
    },
    type: {
      type: String,
      default: 'offering',
      validator: (val) => ['offering', 'reference', 'inspiration'].includes(val)
    }
  },
  data: () => ({
    step: 0,
    loading: '',
    containerHeight: 0,
    disableSubmit: false,
    loadingSubmit: false
  }),
  computed: {
    ...mapGetters([
      'currentWorkspaceMember',
      'canWriteOri',
      'oriManagementEnabled'
    ]),
    isOwner() {
      return (this.item?.owners || []).some(
        (owner) => owner.uuid === this.currentWorkspaceMember.uuid
      )
    },
    isEdit() {
      return this.$route.name.includes('-edit')
    },
    typeName() {
      return {
        offering: 'offering',
        reference: 'case',
        inspiration: 'inspirational content'
      }[this.type]
    },
    title() {
      return this.item?.name || ''
    },
    heightStyle() {
      return {
        height: this.containerHeight ? `${this.containerHeight}px` : 'initial'
      }
    },
    primaryButtonText() {
      return this.isEdit
        ? `Save ${this.typeName} edit`
        : `Finish ${this.typeName} creation`
    }
  },
  watch: {
    item: {
      handler() {
        this.checkPermissions()
      },
      deep: true
    }
  },
  created() {
    if (this.isEdit) {
      this.step = 1
    }
  },
  mounted() {
    this.setContainerHeight()
    window.addEventListener('resize', this.setContainerHeight)
    this.checkPermissions()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setContainerHeight)
  },
  methods: {
    ...mapActions([
      'draftOffering',
      'draftReference',
      'draftInspiration',
      'finishOfferingDraft',
      'finishReferenceDraft',
      'finishInspirationDraft',
      'editOffering',
      'editReference',
      'editInspiration',
      'setOfferingImage',
      'setReferenceImage',
      'setInspirationImage'
    ]),
    checkPermissions() {
      if (this.canWriteOri || !this.item || this.isOwner) return
      this.$router.push({
        name: this.$route.name.split('-')[0],
        params: { workspace_id: this.$route.params.workspace_id }
      })
    },
    setContainerHeight() {
      const container = this.$refs.bootstrapcontainer.getBoundingClientRect()
      const body = document.body.getBoundingClientRect()
      this.containerHeight = body.height - (container.top - body.top)
    },
    gotoORI() {
      this.$router.push({
        name: 'portfolio',
        params: {
          workspace_id: this.$route.params.workspace_id,
          ori_id: this.item.uuid
        }
      })
    },
    async handlePrimaryClick() {
      if (this.isEdit) this.$refs.oriedit.submit()
      else this.$refs.oridraft.submit()
    },
    handleCancel() {
      this.gotoORI()
    },
    async createItem(draft) {
      try {
        this.loading = 'create'
        const finishFunction = {
          offering: this.finishOfferingDraft,
          reference: this.finishReferenceDraft,
          inspiration: this.finishInspirationDraft
        }[this.type]
        const res = await finishFunction({
          workspace_id: this.$route.params.workspace_id,
          ori_id: this.item.uuid,
          draft
        })
        this.$emit('create', res)
        this.gotoORI()
        this.$toast.success(
          `${this.typeName.capitalize()} successfully completed, starting key assets generation.`
        )
      } catch (e) {
        this.$console.debug('Something went wrong while creating item', e)
        this.$toast.error(e, `creating your ${this.typeName}`)
      } finally {
        this.loading = ''
      }
    },
    async editItem(props) {
      try {
        this.loading = 'edit'
        let res = { ...this.item }
        const { image, ...rest } = props
        if (Object.keys(rest).length) {
          const editFunction = {
            offering: this.editOffering,
            reference: this.editReference,
            inspiration: this.editInspiration
          }[this.type]
          res = await editFunction({
            workspace_id: this.$route.params.workspace_id,
            ori_id: this.item.uuid,
            ...props
          })
        }
        if (image !== undefined) {
          const imageFunction = {
            offering: this.setOfferingImage,
            reference: this.setReferenceImage,
            inspiration: this.setInspirationImage
          }[this.type]
          const imgRes = await imageFunction({
            workspace_id: this.$route.params.workspace_id,
            ori_id: this.item.uuid,
            image
          })
          res = { ...res, image: imgRes.image }
        }
        this.$toast.success(
          `${this.typeName.capitalize()} successfully edited.`
        )
        this.$emit('edit', res)
        this.gotoORI()
      } catch (e) {
        this.$console.debug('Something went wrong while editing item', e)
        this.$toast.error(e, `editing your ${this.typeName}`)
      } finally {
        this.loading = ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ori-bootstrap {
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  --bootstrap-content-width: min(45rem, 90vw);

  &-header {
    padding: 1rem 0;
    width: var(--bootstrap-content-width);
    background: white;
    font-size: 1.5rem;
    font-weight: 700;
    position: sticky;
    top: 0;
    z-index: 10;
  }

  &-content {
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    padding-bottom: 3rem;

    &-step {
      width: var(--bootstrap-content-width);
    }
  }

  &-footer {
    border-top: 1px solid rgba(#000, 0.08);
    padding: 1rem 1.75rem;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    gap: 1rem;
  }
}

::v-deep .b-skeleton {
  width: var(--bootstrap-content-width);
  padding: 1rem 0;
}
</style>
