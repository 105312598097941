<template>
  <div class="portfolio-parent-wrapper">
    <div
      class="portfolio-parent"
      :class="{ empty: parentEmpty, 'can-edit': canEdit }"
      @click="startParentEdit"
    >
      <b-tooltip
        v-if="!parentEmpty"
        :label="parent.name"
        position="is-top"
        type="is-dark"
      >
        <component :is="canEdit ? 'div' : 'router-link'" :to="parentRoute">
          <Avatar :user="{ avatar: parent.image }" fallback-icon="globe" />
        </component>
      </b-tooltip>
      <img
        v-else
        src="@/assets/icons/edit.svg"
        alt=""
        class="portfolio-parent-edit"
      />
    </div>
    <ORIParentModal
      :visible="showParentModal"
      :item="item"
      :type="typeSingular"
      @close="() => (showParentModal = false)"
      @edit="handleParentEdit"
    />
  </div>
</template>

<script>
import ORIParentModal from '../ori/ORIParentModal.vue'
import Avatar from '@c/library/Avatar.vue'

export default {
  name: 'PortfolioItemParent',
  components: { ORIParentModal, Avatar },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    type: {
      type: String,
      default: 'offerings'
    },
    canEdit: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    showParentModal: false
  }),
  computed: {
    parent() {
      return this.item.parent
    },
    parentEmpty() {
      return !this.parent
    },
    parentRoute() {
      return {
        name: 'portfolio',
        params: {
          workspace_id: this.$route.params.workspace_id,
          ori_type: 'offerings',
          ori_id: this.parent.uuid
        }
      }
    },
    typeSingular() {
      return {
        offerings: 'offering',
        references: 'reference',
        inspirations: 'inspiration'
      }[this.type]
    }
  },
  methods: {
    startParentEdit() {
      if (this.canEdit) this.showParentModal = true
    },
    handleParentEdit(item) {
      this.showParentModal = false
      this.$emit('edit', item)
    }
  }
}
</script>

<style lang="scss" scoped>
.portfolio-parent {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  margin-left: 0.5rem;
  max-width: 6rem;
  width: fit-content;
  border-radius: 4px;
  transition: background 0.2s ease;
  border: 1px solid transparent;

  &.can-edit {
    cursor: pointer;

    &:hover {
      background: rgba(#000, 0.08);
    }

    &.empty {
      &:hover {
        border: 1px dashed rgba(#000, 0.08);

        & .portfolio-parent-edit {
          opacity: 0.5;
        }
      }
    }
  }

  &.empty {
    width: 100%;
    height: 2.5rem;
    min-height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:not(.empty) {
    padding: 0.5rem 0.75rem;
  }

  &-wrapper {
    margin-left: -0.5rem;
  }

  &-edit {
    opacity: 0;
    transition: opacity 0.2s ease;
    height: 1.35rem;
    width: 1.35rem;
  }
}
</style>
