<template>
  <Tag
    v-if="item.confidentiality"
    :text="item.confidentiality.capitalize()"
    :type="item.confidentiality === 'internal' ? 'black' : 'light-solid'"
    :icon="`eye-${item.confidentiality === 'internal' ? 'close' : 'open'}`"
    :icon-left="true"
    size="xs"
  />
</template>

<script>
import Tag from '@c/library/Tag.vue'

export default {
  name: 'PortfolioItemConfidentiality',
  components: {
    Tag
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped></style>
