<template>
  <component :is="overviewComponent" />
</template>

<script>
import OfferingsOverview from '../offerings/OfferingsOverview.vue'
import ReferencesOverview from '../references/ReferencesOverview.vue'
import InspirationsOverview from '../inspirations/InspirationsOverview.vue'

export default {
  name: 'PortfolioGridTab',
  props: {
    type: {
      type: String,
      required: true,
      validator: (value) =>
        ['offerings', 'references', 'inspirations'].includes(value)
    }
  },
  computed: {
    overviewComponent() {
      return {
        offerings: OfferingsOverview,
        references: ReferencesOverview,
        inspirations: InspirationsOverview
      }[this.type]
    }
  }
}
</script>

<style lang="scss" scoped></style>
