<template>
  <div class="portfolio-status">
    <Button
      v-if="canEdit && (isDraftingWithContent || isPublished)"
      :text="`${isPublished ? 'un' : ''}publish`.capitalize()"
      size="xs"
      :loading="loading"
      class="portfolio-status-background"
      @click="startPublish"
    />
    <router-link
      v-else-if="
        canEdit && isEmpty && oriManagementEnabled && draftOriContentEnabled
      "
      :to="draftRoute"
    >
      <Button text="Draft" size="xs" class="portfolio-status-background" />
    </router-link>
    <StatusOrb
      v-else
      :type="statusColor"
      :text="statusText"
      :show-text="true"
      class="portfolio-status-background"
    />
    <StatusOrb
      :type="statusColor"
      :text="statusText"
      :show-text="true"
      class="portfolio-status-hoverable"
    />
    <b-modal v-model="showConfirmModal">
      <div class="portfolio-status-confirm">
        <p class="portfolio-status-confirm-title">
          Are you sure you want to
          <span v-if="isPublished">un</span>publish this {{ typeName }}?
        </p>
        <p class="portfolio-status-confirm-sub">
          This will make the {{ typeName }}
          {{ `${isPublished ? 'un' : ''}available` }} to others.
        </p>
        <div class="portfolio-status-confirm-btns">
          <Button
            text="Cancel"
            type="white"
            :disabled="loading"
            @click="() => (showConfirmModal = false)"
          />
          <Button text="Confirm" :loading="loading" @click="handlePublish" />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import StatusOrb from '@c/library/StatusOrb.vue'
import Button from '@c/library/Button.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'PortfolioItemStatus',
  components: { StatusOrb, Button },
  props: {
    item: {
      type: Object,
      required: true
    },
    header: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    canEdit: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    showConfirmModal: false,
    loading: false
  }),
  computed: {
    ...mapGetters([
      'oriManagementEnabled',
      'draftOriContentEnabled',
      'currentWorkspaceMember'
    ]),
    typeName() {
      return {
        offerings: 'offering',
        references: 'case',
        inspirations: 'inspirational content'
      }[this.type]
    },
    statusColor() {
      return {
        draft: 'orange',
        published: 'green',
        ['']: 'grey'
      }[this.item.status]
    },
    statusText() {
      return (this.item.status || 'No content').capitalize()
    },
    isEmpty() {
      return this.item.status === ''
    },
    isDraftingWithContent() {
      return this.item.status === 'draft' && this.item.resources?.length > 0
    },
    isPublished() {
      return this.item.status === 'published'
    },
    draftRoute() {
      return {
        name: 'portfolio-create',
        params: {
          workspace_id: this.$route.params.workspace_id,
          ori_type: this.type,
          ori_id: this.item.uuid
        }
      }
    }
  },
  methods: {
    ...mapActions([
      'setOfferingStatus',
      'setReferenceStatus',
      'setInspirationStatus'
    ]),
    startPublish() {
      this.showConfirmModal = true
    },
    async handlePublish() {
      const un = this.isPublished ? 'un' : ''
      try {
        this.loading = true
        const statusFunction = {
          offerings: this.setOfferingStatus,
          references: this.setReferenceStatus,
          inspirations: this.setInspirationStatus
        }[this.type]
        const res = await statusFunction({
          workspace_id: this.$route.params.workspace_id,
          ori_id: this.item.uuid,
          status: this.isPublished ? 'draft' : 'published'
        })
        this.$emit('publish', res)
        this.$toast.success(`Successfully ${un}published ${this.typeName}`)
      } catch (e) {
        this.$console.debug(`Something went wrong while ${un}publishing`, e)
        this.$toast.error(e, `${un}publishing this ${this.typeName}`)
      } finally {
        this.loading = false
        this.showConfirmModal = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.portfolio-status {
  position: relative;

  &:hover {
    & .portfolio-status-hoverable {
      opacity: 0;
      z-index: -1;
    }

    & .portfolio-status-background {
      opacity: 1;
      z-index: 1;
    }
  }

  &-hoverable {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 1;
  }

  &-background {
    position: relative;
    opacity: 0;
  }

  &-confirm {
    padding: 1.5rem 2rem;
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;
    background: white;
    border-radius: 6px;

    &-title {
      font-size: 1.25rem;
      font-weight: 600;
    }

    &-sub {
      color: #60666b;
    }

    &-btns {
      display: flex;
      flex-flow: row nowrap;
      gap: 1rem;
      justify-content: flex-end;
    }
  }
}

::v-deep .modal-content {
  width: unset !important;
}
</style>
