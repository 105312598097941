var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "resource",
      class: { selected: _vm.selected },
      style: "--selection-color: " + _vm.selectionColor + ";",
      on: {
        mouseenter: function($event) {
          _vm.hover = true
        },
        mouseleave: function($event) {
          _vm.hover = false
        }
      }
    },
    [
      _c("div", { staticClass: "resource-title-wrapper" }, [
        _c(
          "div",
          { staticClass: "resource-title" },
          [
            _c("DocumentTag", {
              staticClass: "resource-title-doctag",
              attrs: { mimetype: _vm.resource.integrationfile.mimetype }
            }),
            _c(
              "div",
              {
                staticClass: "resource-title-text-wrapper",
                on: { click: _vm.onTitleClick }
              },
              [
                _c("p", { staticClass: "resource-title-text-container" }, [
                  _c(
                    "span",
                    {
                      staticClass: "resource-title-text",
                      class: { clickable: _vm.titleClickable }
                    },
                    [_vm._v(_vm._s(_vm.resource.title))]
                  )
                ]),
                !_vm.titleClickable
                  ? _c("img", {
                      staticClass: "resource-title-link",
                      attrs: {
                        src: require("@/assets/icons/external-bold.svg"),
                        alt: ""
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.openResourceUrl.apply(null, arguments)
                        }
                      }
                    })
                  : _vm._e()
              ]
            ),
            _c("div", { staticClass: "resource-title-tags" }, [
              _vm.isGenerated
                ? _c("span", { staticClass: "resource-title-tag" }, [
                    _vm._v("Generated")
                  ])
                : _vm._e(),
              _vm.titleTag
                ? _c("span", { staticClass: "resource-title-tag" }, [
                    _vm._v(_vm._s(_vm.titleTag))
                  ])
                : _vm._e()
            ]),
            _vm.downloadFilesEnabled
              ? _c("UploadDownloadDropdown", {
                  attrs: { "props-call": _vm.getDownloadProps }
                })
              : _vm._e()
          ],
          1
        ),
        _c("div", [_vm._t("buttons")], 2)
      ]),
      _c(
        "ResourceInfo",
        _vm._g(
          {
            staticClass: "resource-info",
            attrs: {
              "resource-integration": _vm.resource.integrationfile,
              hover: _vm.hover
            },
            on: { "on-path-click": _vm.pathClick }
          },
          _vm.$listeners
        )
      ),
      _c(
        "div",
        { staticClass: "resource-preview", class: { scrimmed: _vm.showScrim } },
        [
          _c(
            "div",
            { staticClass: "resource-preview-list" },
            _vm._l(_vm.resource.subresources, function(subresource, idx) {
              return _c(
                "div",
                {
                  key: subresource.uuid,
                  staticClass: "resource-preview-list-item",
                  class: {
                    highlighted: _vm.highlightSubresources,
                    inspectable: !_vm.showScrim
                  },
                  on: {
                    click: function($event) {
                      return _vm.$emit("click", subresource.page)
                    }
                  }
                },
                [
                  _c("img", {
                    staticClass: "resource-preview-list-item-img",
                    attrs: { src: subresource.preview, alt: "" },
                    on: {
                      load: function($event) {
                        _vm.imagesLoaded++
                      },
                      error: function($event) {
                        _vm.imagesErrored++
                      }
                    }
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "resource-preview-list-item-btns",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                        }
                      }
                    },
                    [
                      _vm.showSlideCollect
                        ? _c("CollectSlideButton", {
                            attrs: {
                              subresource: subresource,
                              rank: idx + 1,
                              "emit-collect": true,
                              mimetype: _vm.resource.integrationfile.mimetype,
                              "resource-trace-id": _vm.resource.trace_id,
                              "always-show": true
                            },
                            on: {
                              collect: function($event) {
                                return _vm.$emit("subresourceCollect", $event)
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ]
              )
            }),
            0
          )
        ]
      ),
      _c(
        "div",
        { staticClass: "resource-tags" },
        [_c("ResourceTagList", { attrs: { tags: _vm.resourceTags } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }