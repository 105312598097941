<template>
  <div>
    <div
      v-show="progress < 100 || !doneIcon"
      class="progress-circle"
      :class="{ [size]: true, 'below-half': progress < 50 }"
      :style="`--bar-color: ${color}; --progress: ${progress};`"
    ></div>
    <div v-if="doneIcon" class="progress-circle-done">
      <img
        v-show="progress === 100"
        :src="require(`@/assets/icons/${doneIcon}.svg`)"
        alt=""
        class="progress-circle-done-icon"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    progress: {
      type: Number,
      required: true,
      validator: (value) => value >= 0 && value <= 100
    },
    size: {
      type: String,
      default: 's',
      validator: (value) => ['xxs', 'xs', 's', 'm', 'l'].includes(value)
    },
    color: {
      type: String,
      default: '#115efb'
    },
    doneIcon: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped lang="scss">
.progress-circle {
  position: relative;
  border-radius: 50%;
  background: white
    linear-gradient(to right, transparent 50%, var(--bar-color) 0);

  &.xxs {
    width: 1.2rem;
    height: 1.2rem;
    min-width: 1.2rem;
    min-height: 1.2rem;

    &::after {
      inset: 3px;
    }

    & + .progress-circle-done {
      width: 1.2rem;
      height: 1.2rem;
      min-width: 1.2rem;
      min-height: 1.2rem;
    }
  }

  &.xs {
    width: 1.5rem;
    height: 1.5rem;
    min-width: 1.5rem;
    min-height: 1.5rem;

    &::after {
      inset: 4px;
    }

    & + .progress-circle-done {
      width: 1.5rem;
      height: 1.5rem;
      min-width: 1.5rem;
      min-height: 1.5rem;
    }
  }

  &.s {
    width: 2rem;
    height: 2rem;
    min-width: 2rem;
    min-height: 2rem;

    &::after {
      inset: 5px;
    }

    & + .progress-circle-done {
      width: 2rem;
      height: 2rem;
      min-width: 2rem;
      min-height: 2rem;
    }
  }

  &.m {
    width: 2.85rem;
    height: 2.85rem;
    min-width: 2.85rem;
    min-height: 2.85rem;

    &::after {
      inset: 7px;
    }

    & + .progress-circle-done {
      width: 2.85rem;
      height: 2.85rem;
      min-width: 2.85rem;
      min-height: 2.85rem;
    }
  }

  &.l {
    width: 3.45rem;
    height: 3.45rem;
    min-width: 3.45rem;
    min-height: 3.45rem;

    &::after {
      inset: 8px;
    }

    & + .progress-circle-done {
      width: 3.45rem;
      height: 3.45rem;
      min-width: 3.45rem;
      min-height: 3.45rem;
    }
  }

  &::after {
    content: '';
    position: absolute;
    inset: 10%;
    border-radius: 50%;
    background: white;
  }

  &::before {
    content: '';
    display: block;
    height: 100%;
    margin-left: 50%;
    transform-origin: left;
    border-radius: 0 100% 100% 0/50%;

    background: var(--bar-color);
    transform: rotate(calc((var(--progress) - 50) * 0.01turn));
  }

  &.below-half {
    &::before {
      background: white;
      transform: rotate(calc(var(--progress) * 0.01turn));
    }
  }

  &-done {
    &-icon {
      height: 100%;
      width: 100%;
      filter: invert(77%) sepia(18%) saturate(3433%) hue-rotate(96deg)
        brightness(94%) contrast(72%);
      display: block;
    }
  }
}
</style>
