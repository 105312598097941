<template>
  <div class="ori-resource">
    <ResourceCard
      v-if="resource.uuid"
      :resource="resource"
      :rank="rank"
      :location="location"
      :has-overlay="needsReview"
      :highlight="highlight"
      :modal-root-props="{
        needsReview,
        templateId: resource.template_id,
        oriType: parentType,
        oriId: parentId,
        canEdit
      }"
      :modal-callback="modalCallback"
    >
      <template #overlay>
        <div v-if="needsReview" class="ori-resource-review">
          <div class="ori-resource-review-text">
            <p class="ori-resource-review-top">Review content by</p>
            <p class="ori-resource-review-date">{{ reviewTimeLeft }}</p>
          </div>
        </div>
      </template>
      <template #subTitle>
        <div v-if="showTags" class="ori-resource-review-sub">
          <ORITagEdit
            :selected="resource.attributes"
            :editable="canEdit"
            tag-size="xxs"
            @input="att => $emit('editAttributes', att)"
          />
        </div>
      </template>
      <template #tags>
        <div class="ori-resource-tags">
          <Tag
            v-if="canEdit && statusText"
            :icon="statusIcon"
            :text="statusText"
            :icon-left="true"
            :filter-icon="false"
            type="white"
            size="xxs"
          />
        </div>
      </template>
    </ResourceCard>
    <ORIResourceGenerating v-else :resource="resource" v-on="$listeners" />
  </div>
</template>

<script>
import Tag from '@c/library/Tag.vue'
import ORITagEdit from './ORITagEdit.vue'
import ORIResourceGenerating from './ORIResourceGenerating.vue'
import ResourceCard from '@c/shared/molecules/object-visualisations/resource/ResourceCard.vue'

import { formatDate } from '@/util'

export default {
  name: 'ORIResourceCard',
  components: { Tag, ORITagEdit, ORIResourceGenerating, ResourceCard },
  props: {
    resource: {
      type: Object,
      required: true
    },
    rank: {
      type: Number,
      default: 0
    },
    location: {
      type: String,
      default: ''
    },
    canEdit: {
      type: Boolean,
      default: false
    },
    parentType: {
      type: String,
      default: ''
    },
    parentId: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    bc: null
  }),
  computed: {
    needsReview() {
      return this.draft && this.canEdit
    },
    highlight() {
      return this.resource.highlight
    },
    draft() {
      return this.resource.status === 'draft'
    },
    reviewTimeLeft() {
      return formatDate(this.resource.review_deadline)
    },
    showTags() {
      return this.resource.attributes.length || this.canEdit
    },
    statusIcon() {
      let color =
        {
          published: 'green',
          draft: 'orange',
          generating: 'grey'
        }[this.resource.status] || 'grey'
      return `status_${color}`
    },
    statusText() {
      return this.resource.status?.capitalize() || ''
    }
  },
  beforeDestroy() {
    if (this.bc) this.bc.close()
  },
  methods: {
    modalCallback() {
      this.bc = new BroadcastChannel('uman_template_resource_publish')
      this.bc.onmessage = this.handleModalResponse
    },
    handleModalResponse(message) {
      const { event, result } = message.data
      if (
        event === 'published' &&
        result?.resources?.[0]?.uuid === this.resource.uuid
      ) {
        this.$emit('published', result.resources[0])
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ori-resource {
  &-review {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &-text {
      display: flex;
      flex-flow: column nowrap;
      gap: 0.5rem;
      align-items: center;
      padding: 0.75rem 1rem;
      background: rgba(0, 0, 0, 0.6);
      border-radius: 8px;
    }

    &-top {
      color: white;
      font-size: 1.2rem;
    }

    &-date {
      color: white;
      font-size: 1.5rem;
      font-weight: 600;
    }
  }

  &-tags {
    display: flex;
    flex-flow: row nowrap;
    gap: 0.5rem;
    align-items: center;
  }
}
</style>
