<template>
  <b-modal :active="visible" @close="handleClose">
    <div class="ori-modal">
      <div class="ori-modal-header">
        <span class="ori-modal-header-title">{{ title }}</span>
      </div>
      <div class="ori-modal-content">
        <div v-if="linkType !== 'offering'" class="ori-modal-content-block hor">
          <p class="ori-modal-content-block-header">
            Create a new {{ linkedName }}
          </p>
          <Button :text="`Create ${linkedName}`" @click="handleCreateNew" />
        </div>
        <span v-if="linkType !== 'offering'" class="ori-modal-content-or"
          >OR</span
        >
        <div class="ori-modal-content-block centered">
          <p class="ori-modal-content-block-header">
            Select existing {{ linkedNamePlural }}
          </p>
          <SearchBar
            v-model="searchQuery"
            class="ori-modal-searchbar"
            placeholder="Search..."
            :rounded="false"
            @keyup.native.enter.exact="search"
            @historyItemClick="search"
            @autocompleteItemClick="search"
            @clear="handleClear"
          />
          <LinkORIModalContent
            :items="items"
            :selected-uuids="selectionUUIDs"
            :type="linkType"
            :loading="loading"
            :selecting="selecting"
            @select="handleSelect"
          />
        </div>
      </div>
      <div class="ori-modal-footer">
        <div class="ori-modal-footer-btns">
          <Button text="Confirm" @click="handleClose" />
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import SearchBar from '@c/shared/molecules/inputs/SearchBar.vue'
import LinkORIModalContent from './LinkORIModalContent.vue'
import { mapActions } from 'vuex'
import Button from '@c/library/Button.vue'

export default {
  name: 'LinkORIModal',
  components: { LinkORIModalContent, SearchBar, Button },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    type: {
      type: String,
      default: 'offering',
      validator: (value) =>
        ['offering', 'reference', 'inspiration'].includes(value)
    },
    linkType: {
      type: String,
      default: 'reference',
      validator: (value) =>
        ['offering', 'reference', 'inspiration'].includes(value)
    },
    visible: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    searchQuery: '',
    results: [],
    loading: false,
    pageSize: 100,
    selection: [],
    selecting: []
  }),
  computed: {
    itemName() {
      return this.item?.name || this.item?.title || ''
    },
    linkedName() {
      return {
        offering: 'offering',
        reference: 'case',
        inspiration: 'inspiration'
      }[this.linkType]
    },
    linkedNamePlural() {
      return `${this.linkedName}s`
    },
    title() {
      return `Add ${this.linkedNamePlural} to ${this.itemName}`
    },
    selectionUUIDs() {
      return (this.selection || []).map((r) => r.uuid)
    },
    items() {
      const selectedIds = (this.selected || []).map((r) => r.uuid)
      return this.results.filter((r) => !selectedIds.includes(r.uuid))
    }
  },
  mounted() {
    this.search()
  },
  methods: {
    ...mapActions([
      'searchOfferings',
      'searchReferences',
      'searchInspirations',
      'editOffering',
      'editReference',
      'editInspiration'
    ]),
    async handleSelect(item) {
      this.selecting.push(item.uuid)
      let selection = [...this.selection]
      if (this.selectionUUIDs.includes(item.uuid)) {
        selection = selection.filter((r) => r.uuid !== item.uuid)
      } else {
        selection.push(item)
      }
      let ids = [
        ...this.selected.map((s) => s.uuid),
        ...selection.map((s) => s.uuid)
      ]
      try {
        const editFunction = {
          offering: this.editOffering,
          reference: this.editReference,
          inspiration: this.editInspiration
        }[this.type]
        await editFunction({
          workspace_id: this.$route.params.workspace_id,
          ori_id: this.item.uuid,
          [`${this.linkType}_ids`]: ids
        })
        this.selection = selection
      } catch (e) {
        this.$console.debug('Linking ori failed', e)
        this.$toast.error(e, 'creating link')
      } finally {
        this.selecting = this.selecting.filter((s) => s !== item.uuid)
      }
    },
    handleClose() {
      const links = [...this.selected, ...this.selection]
      this.$emit('close', links)
    },
    handleClear() {
      this.searchQuery = ''
      this.search()
    },
    async search() {
      try {
        this.results = []
        this.loading = true
        this.results = await {
          offering: this.searchOfferings,
          reference: this.searchReferences,
          inspiration: this.searchInspirations
        }[this.linkType]({
          workspace_id: this.$route.params.workspace_id,
          search: this.searchQuery,
          page_size: this.pageSize,
          act_as: this.$route.query.act_as
        })
      } catch (e) {
        this.$console.debug('Error in link modal search', e)
        this.$toast.error(e, `searching for ${this.linkedNamePlural}`)
      } finally {
        this.loading = false
      }
    },
    handleCreateNew() {
      this.$emit('createNew', this.linkType)
    }
  }
}
</script>

<style lang="scss" scoped>
.ori-modal {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin: unset;
  background: white;
  border-radius: 8px;

  &-header {
    display: flex;
    flex-flow: row nowrap;
    gap: 1rem;
    align-items: center;
    padding: 1.5rem;
    width: 100%;
    border-bottom: 1px solid rgba(#000, 0.08);

    &-title {
      font-size: 1.25rem;
      font-weight: 700;
      margin-right: auto;
    }
  }

  &-content {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    gap: 1rem;
    padding: 1.5rem;
    width: 100%;

    &-block {
      display: flex;
      flex-flow: column nowrap;
      gap: 0.5rem;
      padding: 1rem;
      border-radius: 6px;
      background: rgba(#000, 0.02);
      width: 100%;

      &.hor {
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
      }

      &-header {
        font-weight: 700;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 0.5rem;

        &-icon {
          height: 1.2rem;
        }
      }
    }

    &-or {
      color: #60666b;
    }
  }

  &-footer {
    display: flex;
    padding: 1.5rem;
    flex-flow: row nowrap;
    align-items: center;
    gap: 1rem;
    border-top: 1px solid rgba(#000, 0.08);
    width: 100%;

    &-btns {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 0.5rem;
      margin-left: auto;
    }
  }
}
</style>
