var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "portfolio-parent-wrapper" },
    [
      _c(
        "div",
        {
          staticClass: "portfolio-parent",
          class: { empty: _vm.parentEmpty, "can-edit": _vm.canEdit },
          on: { click: _vm.startParentEdit }
        },
        [
          !_vm.parentEmpty
            ? _c(
                "b-tooltip",
                {
                  attrs: {
                    label: _vm.parent.name,
                    position: "is-top",
                    type: "is-dark"
                  }
                },
                [
                  _c(
                    _vm.canEdit ? "div" : "router-link",
                    { tag: "component", attrs: { to: _vm.parentRoute } },
                    [
                      _c("Avatar", {
                        attrs: {
                          user: { avatar: _vm.parent.image },
                          "fallback-icon": "globe"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _c("img", {
                staticClass: "portfolio-parent-edit",
                attrs: { src: require("@/assets/icons/edit.svg"), alt: "" }
              })
        ],
        1
      ),
      _c("ORIParentModal", {
        attrs: {
          visible: _vm.showParentModal,
          item: _vm.item,
          type: _vm.typeSingular
        },
        on: {
          close: function() {
            return (_vm.showParentModal = false)
          },
          edit: _vm.handleParentEdit
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }