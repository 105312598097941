var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ori-container" },
    [
      _c("div", { staticClass: "ori-bar" }, [
        _c(
          "div",
          { staticClass: "ori-bar-breadcrumbs" },
          _vm._l(_vm.breadcrumbs, function(breadcrumb) {
            return _c(
              _vm.breadcrumbRoute(breadcrumb.path, breadcrumb.params)
                ? "router-link"
                : "div",
              {
                key: breadcrumb.name,
                tag: "component",
                staticClass: "ori-bar-breadcrumbs-item",
                attrs: {
                  to: _vm.breadcrumbRoute(breadcrumb.path, breadcrumb.params)
                }
              },
              [_vm._v(" " + _vm._s(breadcrumb.name) + " ")]
            )
          }),
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "ori-sidebar-container" },
        [
          _c(
            "div",
            {
              staticClass: "scrollitem ori-header-block",
              attrs: { "data-title": _vm.name }
            },
            [
              _c(
                "ORIHeader",
                _vm._g(
                  {
                    attrs: {
                      ori: _vm.ori,
                      "can-edit": _vm.canWriteOri || _vm.isOwner,
                      "disable-actions": _vm.disableActions,
                      "type-name": _vm.typeName,
                      "attribute-edit-loading": _vm.attributeEditLoading
                    },
                    on: {
                      publish: _vm.handlePublish,
                      editImage: function($event) {
                        _vm.imageModalOpen = true
                      }
                    }
                  },
                  _vm.$listeners
                )
              ),
              _c("ORIPropsBar", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showPropsBar,
                    expression: "showPropsBar"
                  }
                ],
                attrs: {
                  account: _vm.account,
                  url: _vm.url,
                  confidentiality: _vm.confidentiality,
                  "title-mapping": _vm.titleMapping
                }
              })
            ],
            1
          ),
          _c("ORISidebar", {
            staticClass: "ori-sidebar",
            attrs: {
              overview: _vm.overviewItems,
              owners: _vm.owners,
              "can-edit": _vm.canWriteOri || _vm.isOwner,
              "disable-actions": _vm.disableActions
            },
            on: {
              editOwners: function($event) {
                _vm.ownersModalOpen = true
              },
              overviewClick: _vm.handleOverviewItemClick
            }
          }),
          _c("div", { staticClass: "ori-wrapper" }, [
            _c("div", { staticClass: "ori-divider" }),
            _c(
              "div",
              { staticClass: "ori-sections" },
              [
                !_vm.oriManagementEnabled ||
                !_vm.draftOriContentEnabled ||
                _vm.isDraftingWithContent ||
                _vm.isPublished
                  ? _c("div", { staticClass: "ori-wrapper" }, [
                      _vm.hasResourcesKey
                        ? _c(
                            "div",
                            {
                              staticClass: "scrollitem",
                              attrs: { "data-title": _vm.resourceTitle }
                            },
                            [
                              _c("ORISection", {
                                attrs: {
                                  type: "resources",
                                  name: _vm.resourceTitle,
                                  "data-title": _vm.resourceTitle,
                                  items: _vm.resources,
                                  "parent-type": _vm.type,
                                  "is-owner": _vm.isOwner,
                                  ori: _vm.ori
                                },
                                on: {
                                  remove: function(item) {
                                    return _vm.$emit("removeResource", item)
                                  },
                                  add: function($event) {
                                    return _vm.$emit("linkResource")
                                  },
                                  editAttributes: function(att, item) {
                                    return _vm.$emit(
                                      "editResourceAttributes",
                                      att,
                                      item
                                    )
                                  },
                                  reorder: function(pos) {
                                    return _vm.$emit("resourceOrderChange", pos)
                                  },
                                  published: function(item) {
                                    return _vm.$emit("publishResource", item)
                                  },
                                  refresh: function() {
                                    return _vm.$emit("refresh")
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ])
                  : _vm.isEmpty || _vm.isDrafting
                  ? _c(
                      "ORIStatusBlock",
                      _vm._g(
                        {
                          attrs: {
                            type: _vm.type,
                            status: _vm.ori.status || "",
                            "can-edit": _vm.canWriteOri || _vm.isOwner
                          }
                        },
                        _vm.$listeners
                      )
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "scrollitem",
                    attrs: { "data-title": _vm.offeringsTitle }
                  },
                  [
                    _c("ORISection", {
                      attrs: {
                        type: "offerings",
                        name: _vm.offeringsTitle,
                        "data-title": _vm.offeringsTitle,
                        items: _vm.offerings,
                        "has-show-more": _vm.type !== "offering",
                        removable: _vm.type !== "offering",
                        "parent-type": _vm.type,
                        "is-owner": _vm.isOwner,
                        ori: _vm.ori
                      },
                      on: {
                        remove: function(item) {
                          return _vm.$emit("removeOffering", item)
                        },
                        add: function($event) {
                          return _vm.$emit("linkOffering")
                        },
                        reorder: function(pos) {
                          return _vm.$emit("offeringOrderChange", pos)
                        }
                      }
                    })
                  ],
                  1
                ),
                _vm.type === "offering"
                  ? _c(
                      "div",
                      {
                        staticClass: "scrollitem",
                        attrs: { "data-title": _vm.referencesTitle }
                      },
                      [
                        _c("ORISection", {
                          attrs: {
                            type: "references",
                            name: _vm.referencesTitle,
                            "data-title": _vm.referencesTitle,
                            items: _vm.references,
                            "has-show-more": true,
                            "parent-type": _vm.type,
                            "is-owner": _vm.isOwner,
                            ori: _vm.ori
                          },
                          on: {
                            remove: function(item) {
                              return _vm.$emit("removeReference", item)
                            },
                            add: function($event) {
                              return _vm.$emit("linkReference")
                            }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.type === "offering"
                  ? _c(
                      "div",
                      {
                        staticClass: "scrollitem",
                        attrs: { "data-title": _vm.inspirationTitle }
                      },
                      [
                        _c("ORISection", {
                          attrs: {
                            type: "inspiration",
                            name: _vm.inspirationTitle,
                            "data-title": _vm.inspirationTitle,
                            items: _vm.inspiration,
                            "has-show-more": true,
                            "parent-type": _vm.type,
                            "is-owner": _vm.isOwner,
                            ori: _vm.ori
                          },
                          on: {
                            remove: function(item) {
                              return _vm.$emit("removeInspiration", item)
                            },
                            add: function($event) {
                              return _vm.$emit("linkInspiration")
                            }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        ],
        1
      ),
      _c("ORIImageModal", {
        attrs: { item: _vm.ori, type: _vm.type, visible: _vm.imageModalOpen },
        on: {
          edit: _vm.handleModalOutput,
          close: function($event) {
            _vm.imageModalOpen = false
          }
        }
      }),
      _c("ORIOwnersModal", {
        attrs: { item: _vm.ori, type: _vm.type, visible: _vm.ownersModalOpen },
        on: {
          edit: _vm.handleModalOutput,
          close: function($event) {
            _vm.ownersModalOpen = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }