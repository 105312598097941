var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "resource" }, [
    _c("div", { staticClass: "resource-preview" }, [
      _c("img", {
        staticClass: "resource-preview-icon",
        attrs: {
          src: require("@/assets/icons/" +
            (_vm.isGenerated ? "ai" : "upload") +
            ".svg")
        }
      }),
      _c("p", { staticClass: "resource-preview-text" }, [
        _vm._v(
          " " + _vm._s(_vm.isGenerated ? "Generating" : "Uploading") + "... "
        )
      ])
    ]),
    _c(
      "div",
      { staticClass: "resource-info" },
      [
        _c("DocumentTag", {
          staticClass: "resource-info-icon",
          attrs: { mimetype: _vm.resource.integrationfile.mimetype }
        }),
        _c(
          "p",
          {
            staticClass: "resource-info-title",
            attrs: { title: _vm.resource.title }
          },
          [_vm._v(" " + _vm._s(_vm.resource.title) + " ")]
        ),
        _c("div", { staticClass: "resource-info-btns" })
      ],
      1
    ),
    _c("div", { staticClass: "resource-sub" }, [_vm._v(_vm._s(_vm.subTitle))])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }