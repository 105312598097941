var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "conf-edit" }, [
    _c(
      "div",
      { staticClass: "conf-edit-content" },
      [
        _vm.title
          ? _c("p", { staticClass: "conf-edit-title" }, [
              _vm._v(_vm._s(_vm.title))
            ])
          : _vm._e(),
        _c("SegmentedButton", {
          attrs: {
            "text-left": _vm.buttonInfo.left.text,
            "icon-left": _vm.buttonInfo.left.icon,
            "text-right": _vm.buttonInfo.right.text,
            "icon-right": _vm.buttonInfo.right.icon,
            "type-right": _vm.buttonInfo.right.style,
            "type-left": _vm.buttonInfo.left.style
          },
          on: {
            "click-left": function() {
              return _vm.handleClick(_vm.buttonInfo.left.value)
            },
            "click-right": function() {
              return _vm.handleClick(_vm.buttonInfo.right.value)
            }
          }
        })
      ],
      1
    ),
    _c("p", { staticClass: "conf-edit-subtitle" }, [
      _c("img", {
        staticClass: "conf-edit-subtitle-icon",
        attrs: { src: require("@/assets/icons/check-warning.svg"), alt: "" }
      }),
      _vm._v(
        " This setting is permanent and cannot be changed later. The generated key content will have the account information " +
          _vm._s(
            _vm.confidentiality === "internal"
              ? "hidden. Always review if the account information is correctly hidden in the generated documents."
              : "visible."
          ) +
          " "
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }