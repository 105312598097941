var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: { active: _vm.visible },
      on: {
        close: function() {
          return _vm.$emit("close")
        }
      }
    },
    [
      _c("div", { staticClass: "category" }, [
        _c(
          "div",
          { staticClass: "category-wrapper" },
          [
            _c("p", { staticClass: "category-header" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.category ? "Edit" : "Add") +
                  " offering category "
              )
            ]),
            _c("TextInput", {
              attrs: {
                placeholder: "Enter category name",
                title: "Name",
                "max-length": 50
              },
              model: {
                value: _vm.name,
                callback: function($$v) {
                  _vm.name = $$v
                },
                expression: "name"
              }
            }),
            _c("TextArea", {
              attrs: {
                placeholder: "Enter category description",
                title: "Description",
                "min-height": "350px"
              },
              model: {
                value: _vm.description,
                callback: function($$v) {
                  _vm.description = $$v
                },
                expression: "description"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "category-btns" },
          [
            _c("Button", {
              attrs: { text: "Cancel", type: "white" },
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            }),
            _c("Button", {
              attrs: {
                text: "Save category",
                disabled: _vm.submitDisabled,
                loading: _vm.loading
              },
              on: { click: _vm.handleSubmit }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }