<template>
  <div class="views" :class="{ bordered }">
    <div
      v-for="v in views"
      :key="`view-button-${v.key || v}`"
      class="views-option"
      :class="{ selected: v.key === selected }"
      @click="() => $emit('view', v)"
    >
      <img
        :src="require(`@/assets/view_type/view-${v.icon || v}.svg`)"
        alt=""
        class="views-option-icon"
      />
      <p v-if="showText" class="views-option-name">
        {{ (v.name || v).capitalize() }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ViewSwitch',
  props: {
    views: {
      type: Array,
      default: () => [],
      validator: (value) =>
        value.every((v) =>
          ['grid', 'list', 'grid_alt'].includes(v.icon || v.key || v)
        )
    },
    selected: {
      type: String,
      default: ''
    },
    showText: {
      type: Boolean,
      default: false
    },
    bordered: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.views {
  border-radius: 4px;
  display: flex;
  flex-flow: row nowrap;
  overflow: hidden;

  &.bordered {
    border: 1px solid rgba(#000, 0.08);
  }

  &-option {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    cursor: pointer;

    &:hover {
      background: rgba(#000, 0.04);
    }

    &:not(:last-child) {
      border-right: 1px solid rgba(#000, 0.08);
    }

    &.selected {
      background: rgba(#000, 0.04);
    }

    &-icon {
      height: 1.2rem;
    }
  }
}
</style>
