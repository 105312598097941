<template>
  <div class="ori-pills">
    <div
      v-for="option in sortedOptions"
      :key="option.uuid"
      class="ori-pills-section"
    >
      <p class="ori-pills-section-title">{{ option.name }}</p>
      <Button
        v-for="value in option.values"
        :key="value.uuid"
        :text="value.value"
        :type="selected.includes(value.uuid) ? 'light-solid' : 'white'"
        size="xs"
        @click="handlePillSelect(value)"
      />
    </div>
  </div>
</template>

<script>
import Button from '@c/library/Button.vue'
import { mapActions } from 'vuex'

export default {
  name: 'ORIOverviewPills',
  components: { Button },
  props: {
    type: {
      type: String,
      default: 'offerings',
      validator: (value) => {
        return ['offering', 'inspiration', 'reference'].includes(value)
      }
    }
  },
  data: () => ({
    options: []
  }),
  computed: {
    selected() {
      return this.$route.query?.filter
        ? Array.isArray(this.$route.query?.filter)
          ? this.$route.query?.filter
          : [this.$route.query?.filter]
        : []
    },
    sortedOptions() {
      return this.options.map((o) => ({
        ...o,
        values: [
          ...o.values.filter((v) => this.selected.includes(v.uuid)),
          ...o.values.filter((v) => !this.selected.includes(v.uuid))
        ]
      }))
    }
  },
  mounted() {
    this.loadOptions()
  },
  methods: {
    ...mapActions(['getAttributes']),
    async loadOptions() {
      this.options = await this.getAttributes({
        workspace_id: this.$route.params.workspace_id,
        linked_to: this.type
      })
    },
    handlePillSelect(value) {
      let sel = [...(this.selected || [])]
      if (sel.includes(value.uuid)) {
        sel = sel.filter((s) => s !== value.uuid)
      } else {
        sel.push(value.uuid)
      }
      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          filter: sel.length ? sel : undefined
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.ori-pills {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 0.5rem;

  &-section {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 0.5rem;
    border-radius: 6px;
    background: #f1f2f3;
    padding: 0.5rem;

    &-title {
      font-weight: 600;
      font-size: 0.85rem;
    }
  }
}
</style>
