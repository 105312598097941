<template>
  <b-modal :active="visible" @close="() => $emit('close')">
    <div v-if="visible" class="ori-parent-modal">
      <div class="ori-parent-modal-content">
        <p class="ori-parent-modal-title">Change offering parent</p>
        <OfferingParentEdit
          :key="parent ? parent.uuid : category.uuid"
          :offering-id="item.uuid"
          :parent-id="parent ? parent.uuid : ''"
          :category="parent ? undefined : category"
          :align-top="false"
          @select="handleParentSelect"
        />
      </div>
      <div class="ori-parent-modal-footer">
        <Button
          text="Cancel"
          type="white"
          :disabled="loading"
          @click="() => $emit('close')"
        />
        <Button
          text="Save"
          :loading="loading"
          :disabled="!parentChanged"
          @click="saveParent"
        />
      </div>
    </div>
  </b-modal>
</template>

<script>
import Button from '@c/library/Button.vue'
import { mapActions } from 'vuex'
import OfferingParentEdit from '../offerings/OfferingParentEdit.vue'

export default {
  name: 'ORIParentModal',
  components: { Button, OfferingParentEdit },
  props: {
    item: {
      type: Object,
      default: () => undefined
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loading: false,
    parent: undefined,
    category: undefined
  }),
  computed: {
    parentChanged() {
      return (
        this.parent?.uuid !== this.item.parent?.uuid ||
        this.category?.uuid !== this.item.category?.uuid
      )
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.parent = this.item.parent ? { ...this.item.parent } : undefined
        this.category = this.item.category
          ? { ...this.item.category }
          : undefined
      }
    }
  },
  created() {
    this.parent = this.item.parent
    this.category = this.item.category
  },
  methods: {
    ...mapActions(['editOffering']),
    handleParentSelect(parent) {
      if (parent.type === 'category') {
        if (this.category?.uuid === parent.uuid) {
          this.parent = undefined
        }
        this.category = parent
      } else {
        this.parent = parent
      }
    },
    async saveParent() {
      try {
        if (this.loading) return
        this.loading = true
        if (!this.parentChanged) {
          this.$emit('close')
          return
        }
        const res = await this.editOffering({
          workspace_id: this.$route.params.workspace_id,
          ori_id: this.item.uuid,
          ...(this.category?.uuid !== this.item.category?.uuid ||
          this.parent?.uuid === undefined
            ? { category_id: this.category?.uuid }
            : { parent_id: this.parent?.uuid })
        })
        this.$emit('edit', res)
      } catch (e) {
        this.$console.debug('Something went wrong while changing the parent', e)
        this.$toast.error(e, `changing this offering's parent`)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ori-parent-modal {
  background: white;
  border-radius: 6px;
  width: min(50rem, 90vw);

  &-title {
    font-size: 1.25rem;
    font-weight: 700;
    padding-bottom: 1rem;
  }

  &-content {
    padding: 1rem 1.5rem;
  }

  &-footer {
    padding: 1rem 1.5rem;
    border-top: 1px solid rgba(#000, 0.08);
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    gap: 1rem;
    align-items: center;
  }
}

::v-deep .modal-content {
  width: unset !important;
}

::v-deep .b-skeleton {
  width: 100% !important;
}
</style>
