var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    { attrs: { active: _vm.visible }, on: { close: _vm.handleClose } },
    [
      _c("div", { staticClass: "ori-modal" }, [
        _c("div", { staticClass: "ori-modal-header" }, [
          _c("span", { staticClass: "ori-modal-header-title" }, [
            _vm._v(_vm._s(_vm.title))
          ])
        ]),
        _c("div", { staticClass: "ori-modal-content" }, [
          _vm.linkType !== "offering"
            ? _c(
                "div",
                { staticClass: "ori-modal-content-block hor" },
                [
                  _c("p", { staticClass: "ori-modal-content-block-header" }, [
                    _vm._v(" Create a new " + _vm._s(_vm.linkedName) + " ")
                  ]),
                  _c("Button", {
                    attrs: { text: "Create " + _vm.linkedName },
                    on: { click: _vm.handleCreateNew }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.linkType !== "offering"
            ? _c("span", { staticClass: "ori-modal-content-or" }, [
                _vm._v("OR")
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "ori-modal-content-block centered" },
            [
              _c("p", { staticClass: "ori-modal-content-block-header" }, [
                _vm._v(" Select existing " + _vm._s(_vm.linkedNamePlural) + " ")
              ]),
              _c("SearchBar", {
                staticClass: "ori-modal-searchbar",
                attrs: { placeholder: "Search...", rounded: false },
                on: {
                  historyItemClick: _vm.search,
                  autocompleteItemClick: _vm.search,
                  clear: _vm.handleClear
                },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    if (
                      $event.ctrlKey ||
                      $event.shiftKey ||
                      $event.altKey ||
                      $event.metaKey
                    ) {
                      return null
                    }
                    return _vm.search.apply(null, arguments)
                  }
                },
                model: {
                  value: _vm.searchQuery,
                  callback: function($$v) {
                    _vm.searchQuery = $$v
                  },
                  expression: "searchQuery"
                }
              }),
              _c("LinkORIModalContent", {
                attrs: {
                  items: _vm.items,
                  "selected-uuids": _vm.selectionUUIDs,
                  type: _vm.linkType,
                  loading: _vm.loading,
                  selecting: _vm.selecting
                },
                on: { select: _vm.handleSelect }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "ori-modal-footer" }, [
          _c(
            "div",
            { staticClass: "ori-modal-footer-btns" },
            [
              _c("Button", {
                attrs: { text: "Confirm" },
                on: { click: _vm.handleClose }
              })
            ],
            1
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }