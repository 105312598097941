<template>
  <div class="custom-id">
    <div v-if="!open" class="custom-id-open" @click="() => (open = true)">
      Add custom identifier
    </div>
    <div v-else class="custom-id-edit">
      <TextInput
        v-model="customId"
        title="Custom identifier"
        placeholder="Add custom identifier"
      />
    </div>
  </div>
</template>

<script>
import TextInput from '@c/library/TextInput.vue'

export default {
  name: 'ORICustomIdEdit',
  components: { TextInput },
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    customId: '',
    open: false
  }),
  watch: {
    value(v) {
      this.customId = v
    },
    customId(v) {
      if (v !== this.value) this.$emit('input', v)
    }
  },
  created() {
    this.customId = this.value
    if (this.customId) this.open = true
  },
  methods: {
    save() {
      this.$emit('input', this.customId)
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-id {
  &-open {
    color: $primary;
    cursor: pointer;
    width: fit-content;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
