var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "assigned-ori" }, [
    _c(
      "p",
      { staticClass: "assigned-ori-header" },
      [
        _c("span", { staticClass: "assigned-ori-header-title" }, [
          _vm._v("Assigned to you")
        ]),
        _c("Tag", { attrs: { text: "" + _vm.items.length, size: "xxs" } })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "assigned-ori-list" },
      _vm._l(_vm.items, function(item) {
        return _c(
          "router-link",
          { key: item.uuid, attrs: { to: _vm.oriRoute(item) } },
          [
            _c(
              "p",
              {
                staticClass: "assigned-ori-list-item",
                on: {
                  click: function() {
                    return _vm.gotoORI(item)
                  }
                }
              },
              [
                _c("img", {
                  staticClass: "assigned-ori-list-item-icon",
                  attrs: {
                    src: require("@/assets/icons/document.svg"),
                    alt: ""
                  }
                }),
                _c("span", { staticClass: "assigned-ori-list-item-name" }, [
                  _vm._v(_vm._s(item.name))
                ])
              ]
            )
          ]
        )
      }),
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }