var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "overviewcontent",
      staticClass: "overview-content",
      class: { "has-sidebar": _vm.assignedOfferings.length },
      style: _vm.maxHeightStyle
    },
    [
      _c(
        "div",
        { ref: "overviewwrapper", staticClass: "overview-wrapper" },
        [
          _c(
            "div",
            { staticClass: "overview-top" },
            [
              !_vm.offeringsLoading
                ? _c("ORIOverviewPills", {
                    staticClass: "overview-pills",
                    attrs: {
                      items: _vm.searchResults,
                      "fetch-options": !_vm.isSearch,
                      type: "offering"
                    }
                  })
                : _vm._e(),
              _c(
                "div",
                { staticClass: "overview-top-btns" },
                [
                  !_vm.offeringsLoading && _vm.canWriteOri
                    ? _c("Button", {
                        attrs: { text: "Manage labels", type: "white" },
                        on: { click: _vm.handleManageAttributes }
                      })
                    : _vm._e(),
                  !_vm.offeringsLoading &&
                  _vm.canWriteOri &&
                  (!_vm.offeringsEmpty || _vm.isSearch)
                    ? _c("Button", {
                        attrs: { text: "Add offering category" },
                        on: { click: _vm.openAddCategory }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm.isSearch && !_vm.offeringsLoading && _vm.searchCount
            ? _c("p", { staticClass: "overview-count" }, [
                _vm._v(" " + _vm._s(_vm.searchCount) + " results ")
              ])
            : _vm._e(),
          _vm.offeringsLoading
            ? _c(
                "div",
                { staticClass: "overview-loading" },
                [
                  _c("b-loading", {
                    attrs: { "is-full-page": false, active: "" }
                  })
                ],
                1
              )
            : _vm.offeringsEmpty
            ? _c(
                "div",
                { staticClass: "overview-empty" },
                [
                  _vm._m(0),
                  _c("p", { staticClass: "overview-empty-header" }, [
                    _vm._v(_vm._s(_vm.offeringsEmptyTitle))
                  ]),
                  _c("p", { staticClass: "overview-empty-sub" }, [
                    _vm._v(_vm._s(_vm.offeringsEmptySub))
                  ]),
                  _vm.canWriteOri
                    ? _c("Button", {
                        staticClass: "overview-empty-btn",
                        attrs: { text: "Add offering category" },
                        on: { click: _vm.openAddCategory }
                      })
                    : _vm._e()
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "overview" },
                [
                  _vm._l(_vm.isSearch ? [{}] : _vm.categories, function(
                    category,
                    idx
                  ) {
                    return _c(
                      "div",
                      { key: idx, staticClass: "overview-item" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "overview-item-dropzone-anchor",
                            class: { active: _vm.dragOverIdx === idx }
                          },
                          [
                            _vm.dragging
                              ? _c("div", {
                                  staticClass: "overview-item-dropzone",
                                  attrs: { id: "overview-category-" + idx }
                                })
                              : _vm._e()
                          ]
                        ),
                        _c("OfferingCategory", {
                          attrs: {
                            offerings: _vm.isSearch
                              ? _vm.searchResults
                              : category.offerings,
                            category: category,
                            "is-search": _vm.isSearch,
                            "can-edit": _vm.canWriteOri,
                            draggable: !_vm.isSearch && _vm.canWriteOri,
                            dragging: _vm.dragging === category.uuid
                          },
                          on: {
                            edit: function($event) {
                              return _vm.openEditCategory(category)
                            },
                            remove: function($event) {
                              return _vm.openDeleteCategory(category)
                            },
                            add: function($event) {
                              return _vm.openAddOffering(category)
                            },
                            reorder: function(o) {
                              return _vm.handleOrderChange(o, idx)
                            },
                            startDrag: function() {
                              return (_vm.dragging = category.uuid)
                            },
                            endDrag: function(e) {
                              return _vm.endDrag(category, e)
                            },
                            moving: _vm.handleMouseMove
                          }
                        })
                      ],
                      1
                    )
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "overview-item-dropzone-anchor",
                      class: {
                        active: _vm.dragOverIdx === _vm.categories.length
                      }
                    },
                    [
                      _vm.dragging
                        ? _c("div", {
                            staticClass: "overview-item-dropzone",
                            attrs: {
                              id: "overview-category-" + _vm.categories.length
                            }
                          })
                        : _vm._e()
                    ]
                  )
                ],
                2
              ),
          _c("ORIInit", {
            attrs: {
              visible: _vm.showOfferingAdd,
              type: "offering",
              parent: Object.assign({}, _vm.modalCategory, { type: "category" })
            },
            on: { close: _vm.closeAddOffering }
          }),
          _c("OfferingCategoryModal", {
            attrs: { visible: _vm.showCategoryModal },
            on: {
              close: function($event) {
                _vm.showCategoryModal = false
              },
              submit: _vm.handleAddCategory
            }
          }),
          _c("OfferingCategoryModal", {
            attrs: {
              visible: _vm.showEditCategoryModal,
              category: _vm.modalCategory
            },
            on: {
              close: function($event) {
                _vm.showEditCategoryModal = false
              },
              submit: _vm.handleEditCategory
            }
          }),
          _c(
            "b-modal",
            {
              model: {
                value: _vm.showDeleteCategoryModal,
                callback: function($$v) {
                  _vm.showDeleteCategoryModal = $$v
                },
                expression: "showDeleteCategoryModal"
              }
            },
            [
              _c("RemoveORIModal", {
                attrs: {
                  name: _vm.modalCategory.name,
                  type: "category",
                  uuid: _vm.modalCategory.uuid
                },
                on: {
                  remove: _vm.handleDeleteCategory,
                  close: function($event) {
                    _vm.showDeleteCategoryModal = false
                  }
                }
              })
            ],
            1
          ),
          _c(
            "b-modal",
            {
              attrs: { "can-cancel": false },
              model: {
                value: _vm.endDragLoading,
                callback: function($$v) {
                  _vm.endDragLoading = $$v
                },
                expression: "endDragLoading"
              }
            },
            [
              _c("div", { staticClass: "overview-item-drop-loading" }, [
                _c("img", {
                  staticClass: "overview-item-drop-loading-icon",
                  attrs: { src: require("@/assets/icons/ai.svg"), alt: "" }
                }),
                _vm._v(" Changing position... ")
              ])
            ]
          ),
          _c(
            "b-modal",
            {
              model: {
                value: _vm.showManageAttributes,
                callback: function($$v) {
                  _vm.showManageAttributes = $$v
                },
                expression: "showManageAttributes"
              }
            },
            [_c("AttributesModal")],
            1
          )
        ],
        1
      ),
      _vm.assignedOfferings.length
        ? _c("AssignedORI", {
            staticClass: "overview-sidebar",
            attrs: { items: _vm.assignedOfferings, type: "offerings" }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "overview-empty-icon" }, [
      _c("img", {
        staticClass: "overview-empty-icon-img",
        attrs: { src: require("@/assets/icons/read.svg"), alt: "" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }