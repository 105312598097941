var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    { attrs: { active: _vm.visible }, on: { close: _vm.closeModal } },
    [
      _c("div", { staticClass: "ori-init" }, [
        _c("p", { staticClass: "ori-init-header" }, [
          _vm._v(_vm._s(_vm.header))
        ]),
        _c("div", [
          !_vm.step
            ? _c(
                "div",
                { staticClass: "ori-init-content" },
                [
                  _c("TextInput", {
                    attrs: { title: "Name", placeholder: _vm.placeholder },
                    model: {
                      value: _vm.name,
                      callback: function($$v) {
                        _vm.name = $$v
                      },
                      expression: "name"
                    }
                  }),
                  _c("ORICustomIdEdit", {
                    model: {
                      value: _vm.customId,
                      callback: function($$v) {
                        _vm.customId = $$v
                      },
                      expression: "customId"
                    }
                  }),
                  _vm.type === "reference"
                    ? _c("ORIConfidentialityEdit", {
                        attrs: {
                          value: _vm.confidentiality,
                          title: "Confidentiality"
                        },
                        on: {
                          submit: function($event) {
                            _vm.confidentiality = $event
                          }
                        }
                      })
                    : _vm._e(),
                  _vm.type === "reference"
                    ? _c("OrganisationSearch", {
                        staticClass: "ori-init-content-org",
                        attrs: {
                          organisation: _vm.account
                            ? _vm.account.organisation
                            : undefined,
                          title: "Organisation"
                        },
                        on: {
                          input: _vm.handleAccountInput,
                          remove: _vm.handleAccountRemove,
                          loading: function(l) {
                            return (_vm.accountLoading = l)
                          }
                        }
                      })
                    : _vm._e(),
                  _c("ORIOwnersEdit", {
                    attrs: {
                      title: "Owners",
                      "default-owners": _vm.defaultOwners
                    },
                    on: {
                      submit: function($event) {
                        _vm.owners = $event
                      }
                    }
                  })
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "ori-init-content centered" },
                [
                  _c("p", { staticClass: "ori-init-content-header" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.typeName.capitalize()) +
                        " created successfully "
                    )
                  ]),
                  _c("p", { staticClass: "ori-init-content-subheader" }, [
                    _vm._v(
                      " Do you want to add content to your " +
                        _vm._s(_vm.typeName) +
                        " now? "
                    )
                  ]),
                  _c(
                    _vm.cardComponent,
                    _vm._b(
                      {
                        tag: "component",
                        staticClass: "ori-init-content-item"
                      },
                      "component",
                      _vm.cardProps,
                      false
                    )
                  )
                ],
                1
              )
        ]),
        _c(
          "div",
          { staticClass: "ori-init-footer" },
          [
            _c("Button", {
              attrs: {
                text: _vm.cancelButtonText,
                type: "white",
                disabled: _vm.loading
              },
              on: { click: _vm.closeModal }
            }),
            _c("Button", {
              attrs: {
                text: _vm.submitButtonText,
                disabled: !_vm.name,
                loading: _vm.loading || _vm.accountLoading
              },
              on: { click: _vm.submit }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }