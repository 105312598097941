var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.item.confidentiality
    ? _c("Tag", {
        attrs: {
          text: _vm.item.confidentiality.capitalize(),
          type:
            _vm.item.confidentiality === "internal" ? "black" : "light-solid",
          icon:
            "eye-" +
            (_vm.item.confidentiality === "internal" ? "close" : "open"),
          "icon-left": true,
          size: "xs"
        }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }