<template>
  <div v-on-clickaway="handleClickaway" class="parent-edit">
    <p class="parent-edit-title">Offering parent</p>
    <b-skeleton v-if="parentLoading" width="100%" height="2.5rem" />
    <div v-else class="parent-edit-trigger" @click="handleToggle">
      <span>{{ currentSelection }}</span>
      <img
        src="@/assets/icons/chevron-down-medium.svg"
        alt=""
        class="parent-edit-trigger-icon"
      />
      <div
        v-if="expanded"
        class="parent-edit-content-wrapper"
        :class="{ 'top-aligned': alignTop }"
        @click.stop
      >
        <TextInput
          v-if="!itemsLoading"
          v-model="query"
          placeholder="Find offering or category"
          class="parent-edit-search"
        />
        <div v-if="itemsLoading" class="parent-edit-content-loading">
          <b-loading :is-full-page="false" active />
        </div>
        <div
          v-else-if="visibleItems && visibleItems.length"
          class="parent-edit-content"
        >
          <div>
            <div
              v-for="(item, idx) in visibleItems"
              :key="`${item.name}-${idx}`"
              :class="{
                'has-divider':
                  item.type === 'category' &&
                  visibleItems[idx + 1] &&
                  visibleItems[idx + 1].type === 'offering'
              }"
              class="parent-edit-content-item"
              @click="$emit('select', item)"
            >
              <p
                v-if="
                  (idx === 0 && item.type === 'category') ||
                  (item.type === 'offering' &&
                    visibleItems[idx - 1] &&
                    visibleItems[idx - 1].type === 'category')
                "
                class="parent-edit-content-item-header"
              >
                {{ item.type === 'category' ? 'Categories' : 'Offerings' }}
              </p>
              <p
                class="parent-edit-content-item-name"
                :class="{
                  selected:
                    item.uuid === parentId ||
                    (category && item.uuid === category.uuid)
                }"
              >
                {{ item.name }}
              </p>
            </div>
          </div>
        </div>
        <div v-else-if="itemsError" class="parent-edit-empty">
          Something went wrong while trying to load the offerings, please try
          again later.
        </div>
        <div
          v-else-if="visibleItems && !visibleItems.length"
          class="parent-edit-empty"
        >
          No offerings or categories could be found, add offerings to your
          workspace to see them here.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway'
import TextInput from '@c/library/TextInput.vue'

export default {
  name: 'OfferingParentEdit',
  components: { TextInput },
  mixins: [clickaway],
  props: {
    parentId: {
      type: String,
      default: ''
    },
    category: {
      type: [Object, undefined],
      default: () => ({})
    },
    offeringId: {
      type: String,
      default: () => ({})
    },
    alignTop: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    expanded: false,
    parent: undefined,
    parentLoading: false,
    dropdownItems: [],
    itemsLoading: false,
    itemsError: false,
    query: ''
  }),
  computed: {
    currentSelection() {
      return this.category?.name || this.parent?.name || ''
    },
    visibleItems() {
      return this.dropdownItems.filter(
        (item) =>
          item.uuid !== this.offeringId &&
          (item.name || '')
            .toLowerCase()
            .includes((this.query || '').toLowerCase())
      )
    }
  },
  mounted() {
    this.checkParentInfo()
  },
  methods: {
    ...mapActions([
      'getOfferingInfo',
      'searchOfferings',
      'getOfferingCategories'
    ]),
    async checkParentInfo() {
      try {
        if (!this.category?.name) {
          this.parentLoading = true
          this.parent = await this.getOfferingInfo({
            workspace_id: this.$route.params.workspace_id,
            ori_id: this.parentId
          })
          this.parentLoading = false
        }
      } catch (e) {
        this.$console.debug('Error while getting offering parent', e)
      }
    },
    handleToggle() {
      this.expanded = !this.expanded
      if (this.expanded && !this.dropdownItems.length) {
        this.loadDropdownItems()
      }
    },
    async loadDropdownItems() {
      try {
        this.itemsLoading = true
        const offerings = await this.searchOfferings({
          workspace_id: this.$route.params.workspace_id,
          search: '',
          page_size: 1000
        })
        const categories = await this.getOfferingCategories({
          workspace_id: this.$route.params.workspace_id
        })
        this.dropdownItems = [
          ...categories.map((c) => ({ ...c, type: 'category' })),
          ...offerings.map((o) => ({ ...o, type: 'offering' }))
        ]
      } catch (e) {
        this.$console.debug('Error while getting offerings', e)
        this.itemsError = false
      } finally {
        this.itemsLoading = false
      }
    },
    handleClickaway() {
      this.expanded = false
    }
  }
}
</script>

<style scoped lang="scss">
.parent-edit {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;

  &-trigger {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    padding: 0.5rem 0.85rem;
    background: #f1f2f3;
    border: #e9ebed 1px solid;
    border-radius: 0.5rem;
    cursor: pointer;

    &-icon {
      height: 1.2rem;
    }
  }

  &-content {
    &-wrapper {
      position: absolute;
      left: 0;
      right: 0;
      border-radius: 0.5rem;
      border: 1px solid rgba(#000, 8%);
      background: white;
      box-shadow: 0 0.25rem 0.5rem rgba(#000, 8%);
      padding: 0.5rem 0;
      z-index: 99;
      max-height: 15rem;
      overflow-y: auto;

      &.top-aligned {
        bottom: calc(100% - 0.5rem);
      }

      &:not(.top-aligned) {
        top: calc(100% + 0.5rem);
      }
    }

    &-item {
      &-header {
        padding: 0.5rem;
        font-weight: 700;
      }

      &-name {
        padding: 0.5rem 0.85rem;
        &:hover {
          background: rgba(#000, 8%);
        }

        &.selected {
          background: rgba(#000, 4%);
        }
      }

      &.has-divider {
        padding-bottom: 1rem;
        border-bottom: 1px solid rgba(#000, 8%);
      }
    }

    &-loading {
      position: relative;
      min-height: 5rem;
    }
  }

  &-search {
    margin: 0 0.5rem;
  }

  &-title {
    font-weight: 600;
    color: #303032;
  }

  &-empty {
    padding: 0.5rem 0.85rem;
  }
}

::v-deep .b-skeleton {
  padding: 0 !important;
}
</style>
