<template>
  <div class="assigned-ori">
    <p class="assigned-ori-header">
      <span class="assigned-ori-header-title">Assigned to you</span>
      <Tag :text="`${items.length}`" size="xxs" />
    </p>
    <div class="assigned-ori-list">
      <router-link v-for="item in items" :key="item.uuid" :to="oriRoute(item)">
        <p class="assigned-ori-list-item" @click="() => gotoORI(item)">
          <img
            src="@/assets/icons/document.svg"
            alt=""
            class="assigned-ori-list-item-icon"
          />
          <span class="assigned-ori-list-item-name">{{ item.name }}</span>
        </p>
      </router-link>
    </div>
  </div>
</template>

<script>
import Tag from '@c/library/Tag.vue'

export default {
  name: 'AssignedORI',
  components: { Tag },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      required: true
    }
  },
  methods: {
    oriRoute(item) {
      return {
        name: 'portfolio',
        params: {
          workspace_id: this.$route.params.workspace_id,
          ori_type: this.type,
          ori_id: item.uuid
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.assigned-ori {
  background: #f9f9fa;
  border-radius: 8px;
  padding: 1.15rem;
  display: flex;
  flex-flow: column nowrap;
  gap: 1.25rem;
  height: fit-content;

  &-header {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 0.75rem;

    &-title {
      font-size: 1.15rem;
      font-weight: 600;
    }
  }

  &-list {
    display: flex;
    flex-flow: column nowrap;
    gap: 0.85rem;

    &-item {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 0.5rem;

      &-icon {
        height: 1.2rem;
      }

      &-name {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
</style>
