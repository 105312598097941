var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ori-draft-input" }, [
    _c("span", { staticClass: "ori-draft-input-header" }, [
      _c("img", {
        staticClass: "ori-draft-input-header-icon",
        attrs: { src: require("@/assets/icons/bootstrap.svg"), alt: "" }
      }),
      _c("div", { staticClass: "ori-draft-input-header-text" }, [
        _c("span", { staticClass: "ori-draft-input-header-title" }, [
          _vm._v("Generate a draft")
        ]),
        _c("p", { staticClass: "ori-draft-input-header-explanation" }, [
          _vm._v(
            " Automatically create a first draft for your " +
              _vm._s(_vm.typeName) +
              " based on existing material by selecting relevant documents, adding a link to a webpage or pasting any data about the " +
              _vm._s(_vm.typeName) +
              " as a note. "
          )
        ])
      ])
    ]),
    _c(
      "div",
      { staticClass: "ori-draft-input-section" },
      [
        _c("span", { staticClass: "ori-draft-input-section-title" }, [
          _vm._v("Link")
        ]),
        _c("span", { staticClass: "ori-draft-input-section-subtitle" }, [
          _vm._v(
            "Relevant webpages to help understand your " + _vm._s(_vm.typeName)
          )
        ]),
        _vm._l(_vm.urls, function(url, idx) {
          return _c("TextInput", {
            key: "urlinput-" + idx,
            attrs: {
              value: url,
              placeholder: "Add a url",
              "left-icon": _vm.urlIcon[idx],
              validate: true,
              "validation-function": function() {
                return url ? (_vm.invalidUrl[idx] ? 0 : 1) : -1
              }
            },
            on: {
              input: function(val) {
                return _vm.handleUrlInput(val, idx)
              }
            }
          })
        })
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "ori-draft-input-section" },
      [
        _c("span", { staticClass: "ori-draft-input-section-title" }, [
          _vm._v("Documents")
        ]),
        _c("UploadDropzone", {
          ref: "uploaddropzone",
          attrs: {
            "min-height": "5rem",
            "max-size": 5000,
            "max-size-for-total": true,
            "max-count": 10,
            "show-delete": function(f) {
              return (
                !_vm.uploadingNames.includes(f.name) ||
                _vm.uploading[f.name] === 100 ||
                _vm.uploadingError.includes(f.name)
              )
            }
          },
          on: {
            upload: function(f, isDrive) {
              return isDrive ? _vm.handleDriveUpload(f) : _vm.handleUpload(f)
            },
            remove: _vm.removeFile
          },
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function(ref) {
                var file = ref.file
                return [
                  _vm.uploadingNames.includes(file.name) &&
                  !_vm.uploadingError.includes(file.name)
                    ? _c("ProgressCircle", {
                        attrs: {
                          progress: _vm.uploading[file.name],
                          "done-icon": "check-circle",
                          size: "xxs"
                        }
                      })
                    : _c("img", {
                        staticClass: "ori-draft-input-error",
                        attrs: {
                          src: require("@/assets/icons/check-warning.svg"),
                          alt: ""
                        }
                      })
                ]
              }
            }
          ])
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ori-draft-input-section" },
      [
        _c("span", { staticClass: "ori-draft-input-section-title" }, [
          _vm._v("Notes")
        ]),
        _c("span", { staticClass: "ori-draft-input-section-subtitle" }, [
          _vm._v("Text to help understand your " + _vm._s(_vm.typeName))
        ]),
        _c("TextArea", {
          attrs: {
            placeholder:
              _vm.editingNote === -1 ? "Add a note" : "Edit your note",
            "button-text": _vm.noteSaveButtonText,
            "has-cancel-button": _vm.editingNote !== -1
          },
          on: { submit: _vm.handleNoteSave, cancel: _vm.cancelNoteEdit },
          model: {
            value: _vm.currentNote,
            callback: function($$v) {
              _vm.currentNote = $$v
            },
            expression: "currentNote"
          }
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "ori-draft-input-section-notes" },
          _vm._l(_vm.notes, function(note, idx) {
            return _c(
              "div",
              { key: idx, staticClass: "ori-draft-input-section-notes-note" },
              [
                _c("Button", {
                  staticClass: "ori-draft-input-section-notes-note-edit",
                  attrs: { icon: "edit", type: "white", size: "xs" },
                  on: {
                    click: function() {
                      return _vm.handleNoteEdit(idx)
                    }
                  }
                }),
                _vm._v(" " + _vm._s(note) + " "),
                _vm.editingNote === idx
                  ? _c("b-loading", {
                      attrs: { active: "", "is-full-page": false }
                    })
                  : _vm._e()
              ],
              1
            )
          }),
          0
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }