var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "portfolio-resources" },
    [
      _vm.showModal
        ? _c(
            "b-modal",
            {
              model: {
                value: _vm.showModal,
                callback: function($$v) {
                  _vm.showModal = $$v
                },
                expression: "showModal"
              }
            },
            [
              _c("div", { staticClass: "portfolio-resources-list-wrapper" }, [
                _c(
                  "div",
                  { staticClass: "portfolio-resources-list-header" },
                  [
                    _c(
                      "p",
                      { staticClass: "portfolio-resources-list-header-title" },
                      [_vm._v(_vm._s(_vm.title))]
                    ),
                    _c("Button", {
                      attrs: { icon: "close", type: "grey", size: "xs" },
                      on: {
                        click: function() {
                          return (_vm.showModal = false)
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "portfolio-resources-list" },
                  _vm._l(_vm.items, function(i, idx) {
                    return _c(
                      _vm.cardComponent,
                      _vm._b(
                        { key: "inspect-modal-" + i.uuid, tag: "component" },
                        "component",
                        _vm.cardProps(i, idx),
                        false
                      )
                    )
                  }),
                  1
                )
              ])
            ]
          )
        : _vm._e(),
      _vm.count !== -1
        ? _c("Button", {
            staticClass: "portfolio-resources-trigger",
            class: { "is-disabled": !_vm.count },
            attrs: {
              text:
                _vm.count +
                " " +
                (_vm.header.id === "content" ? "files" : "items"),
              type: "white"
            },
            on: {
              click: function() {
                return (_vm.showModal = true)
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }