<template>
  <div class="ori-status-block">
    <p class="ori-status-block-header">{{ header }}</p>
    <p class="ori-status-block-subheader">{{ subheader }}</p>
    <div v-if="isDrafting || canEdit" class="ori-status-block-footer">
      <LoadingDots v-if="isDrafting" animation="flicker" />
      <router-link
        v-else-if="canEdit && draftOriContentEnabled"
        :to="createRoute"
      >
        <Button :text="`Finish ${typeName} setup`" />
      </router-link>
      <Button
        v-else-if="canEdit && !draftOriContentEnabled"
        :text="`Add content to ${typeName}`"
        @click="() => $emit('linkResource')"
      />
    </div>
  </div>
</template>

<script>
import Button from '@c/library/Button.vue'
import LoadingDots from '@c/library/LoadingDots.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ORIStatusBlock',
  components: { LoadingDots, Button },
  props: {
    type: {
      type: String,
      default: 'offering',
      validator: (val) => ['offering', 'reference', 'inspiration'].includes(val)
    },
    status: {
      type: String,
      default: 'draft',
      validator: (val) => ['', 'draft', 'published'].includes(val)
    },
    canEdit: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    draftPoller: undefined
  }),
  computed: {
    ...mapGetters(['draftOriContentEnabled']),
    isDrafting() {
      return this.status === 'draft'
    },
    typeName() {
      return {
        offering: 'offering',
        reference: 'case',
        inspiration: 'inspirational content'
      }[this.type]
    },
    header() {
      return this.isDrafting
        ? `Creating key assets for ${this.typeName}`
        : `${this.typeName.capitalize()} not completed`
    },
    subheader() {
      return this.isDrafting
        ? `We're generating the key documents for this ${this.typeName}. Check back in a few minutes to see the results.`
        : this.draftOriContentEnabled
        ? `Looks like we are still missing some information on this ${this.typeName}.`
        : `Add some content to this ${this.typeName} to get started. This will allow you to publish it to the rest of your team.`
    },
    createRoute() {
      return {
        name: `${this.$route.name}-create`,
        params: this.$route.params
      }
    }
  },
  watch: {
    isDrafting(val) {
      if (val) {
        this.startDraftPoll()
      } else {
        clearInterval(this.draftPoller)
        this.draftPoller = undefined
      }
    }
  },
  created() {
    if (this.isDrafting) {
      this.startDraftPoll()
    }
  },
  methods: {
    startDraftPoll() {
      this.draftPoller = setInterval(() => this.$emit('refresh'), 10 * 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
.ori-status-block {
  border-radius: 8px;
  background: #f9f9fa;
  padding: 2.25rem;
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;
  align-items: center;

  &-header {
    font-size: 1.15rem;
    font-weight: 700;
  }

  &-subheader {
    color: #60666b;
  }

  &-footer {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.5rem;
    margin-top: 0.5rem;
  }
}
</style>
