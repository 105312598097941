<template>
  <div
    ref="overviewcontent"
    class="overview-content"
    :class="{ 'has-sidebar': assignedReferences.length }"
    :style="maxHeightStyle"
  >
    <div
      ref="overviewwrapper"
      v-infinite-scroll="handleInfiniteScroll"
      :infinite-scroll-disabled="loadingOrDone"
      infinite-scroll-distance="100"
      infinite-scroll-throttle-delay="500"
      class="overview-wrapper"
    >
      <div class="overview-top">
        <ORIOverviewPills type="reference" class="overview-pills" />
        <div class="overview-top-btns">
          <Button
            v-if="!referencesLoading && canWriteOri"
            text="Manage labels"
            type="white"
            @click="handleManageAttributes"
          />
          <Button
            v-if="
              !referencesLoading &&
              canWriteOri &&
              (!referencesEmpty || isSearch)
            "
            text="Add case"
            @click="openAddReference"
          />
        </div>
      </div>
      <p
        v-if="isSearch && !referencesLoading && paginatorDataCount"
        class="overview-count"
      >
        {{ paginatorDataCount }} results
      </p>
      <div v-if="referencesLoading" class="overview-loading">
        <b-loading :is-full-page="false" active />
      </div>
      <div v-else-if="referencesEmpty" class="overview-empty">
        <div class="overview-empty-icon">
          <img
            src="@/assets/icons/archive.svg"
            alt=""
            class="overview-empty-icon-img"
          />
        </div>
        <p class="overview-empty-header">{{ referencesEmptyTitle }}</p>
        <p class="overview-empty-sub">{{ referencesEmptySub }}</p>
        <Button
          v-if="canWriteOri"
          text="Add case"
          class="overview-empty-btn"
          @click="openAddReference"
        />
      </div>
      <div class="overview">
        <ReferenceListItem
          v-for="reference in references"
          :key="reference.name"
          :reference="reference"
          :clickable="true"
          :show-status="canWriteOri"
          class="overview-item"
        />
      </div>
      <ORIInit
        :visible="showReferenceAdd"
        type="reference"
        @close="showReferenceAdd = false"
      />
      <b-modal v-model="showManageAttributes">
        <AttributesModal />
      </b-modal>
    </div>
    <AssignedORI
      v-if="assignedReferences.length"
      :items="assignedReferences"
      type="references"
      class="overview-sidebar"
    />
  </div>
</template>

<script>
import { PaginatorConsumer } from '@/mixins/PaginatorConsumer'
import { mapActions, mapGetters } from 'vuex'
import ReferenceListItem from './ReferenceListItem.vue'
import ORIOverviewPills from '../ori/ORIOverviewPills.vue'
import Button from '@c/library/Button.vue'
import AttributesModal from '../offerings/AttributesModal.vue'
import ORIInit from '../ori/ORIInit.vue'
import AssignedORI from '../ori/AssignedORI.vue'
import { isEqual } from 'lodash'
import { MaxHeightMixin } from '@/mixins/MaxHeightMixin'

export default {
  name: 'ReferencesOverview',
  components: {
    ReferenceListItem,
    ORIOverviewPills,
    Button,
    AttributesModal,
    ORIInit,
    AssignedORI
  },
  mixins: [PaginatorConsumer, MaxHeightMixin],
  data: () => ({
    showReferenceAdd: false,
    assignedReferences: [],
    assignedReferencesLoading: false,
    showManageAttributes: false
  }),
  computed: {
    ...mapGetters(['currentWorkspaceMember', 'canWriteOri', 'attributes']),
    references() {
      return this.paginatorData || []
    },
    referencesLoading() {
      return this.consumptionLoading || this.assignedReferencesLoading
    },
    referencesEmpty() {
      return !this.referencesLoading && !this.references.length
    },
    referencesEmptyTitle() {
      return this.isSearch ? 'No cases found' : 'No cases yet'
    },
    referencesEmptySub() {
      return this.isSearch
        ? 'Please try rephrasing your search with keywords, or disabling some filters.'
        : this.canWriteOri
        ? 'Click the button below to get started with creating your first case.'
        : 'When your workspace admin adds cases, they will appear here.'
    },
    queryFromRoute() {
      return this.$route.query?.query || ''
    },
    filterFromRoute() {
      return this.$route.query?.filter
        ? Array.isArray(this.$route.query?.filter)
          ? this.$route.query?.filter
          : [this.$route.query?.filter]
        : []
    },
    isSearch() {
      return !!this.queryFromRoute || this.filterFromRoute?.length
    },
    loadingOrDone() {
      return this.referencesLoading || this.isDone
    }
  },
  watch: {
    queryFromRoute() {
      this.resetPaginatorConsumer()
    },
    filterFromRoute(newVal, oldVal) {
      if (!isEqual(newVal, oldVal)) this.resetPaginatorConsumer()
    },
    attributes() {
      if (this.filterFromRoute?.length) this.resetPaginatorConsumer()
    }
  },
  created() {
    this.checkLinkNew()
  },
  methods: {
    ...mapActions(['getSearchReferencesPaginator', 'searchReferences']),
    getMaxHeightElement() {
      return this.$refs.overviewcontent
    },
    checkLinkNew() {
      if (this.$route.query?.link_new) {
        this.openAddReference()
      }
    },
    getPaginator() {
      this.loadAssignedReferences()
      return this.getSearchReferencesPaginator({
        workspace_id: this.$route.params.workspace_id,
        page_size: 10,
        ...(this.isSearch
          ? {
              search: this.queryFromRoute,
              attribute_value_ids: this.filterFromRoute
            }
          : {})
      })
    },
    async loadAssignedReferences() {
      try {
        this.assignedReferencesLoading = true
        this.assignedReferences = await this.searchReferences({
          workspace_id: this.$route.params.workspace_id,
          search: this.queryFromRoute,
          owner_id: this.currentWorkspaceMember.uuid
        })
      } catch (e) {
        this.$console.debug('Assigned references retrieval failed', e)
      } finally {
        this.assignedReferencesLoading = false
      }
    },
    openAddReference() {
      this.showReferenceAdd = true
    },
    handleManageAttributes() {
      this.showManageAttributes = true
    },
    handleInfiniteScroll() {
      this.loadPage()
    }
  }
}
</script>

<style scoped lang="scss">
.overview {
  display: flex;
  flex-flow: column nowrap;
  gap: 1.15rem;

  &-content {
    padding: 1.5rem 2.5rem 4rem !important;
    overflow-y: auto;
    position: relative;

    &.has-sidebar {
      display: grid;
      grid-template-columns: 1fr min(20rem, 17.5vw);
      gap: 3rem;
    }
  }

  &-sidebar {
    position: sticky;
    top: 0;
  }

  &-count {
    color: #60666b;
    padding: 1rem 0;
  }

  &-empty {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    padding-top: 2rem;

    &-icon {
      background: #e9ebed;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 4rem;
      width: 4rem;
      margin-bottom: 1rem;

      &-img {
        height: 3rem;
      }
    }

    &-header {
      font-size: 1.25rem;
    }

    &-sub {
      color: #60666b;
      width: min(40rem, 90vw);
      text-align: center;
    }

    &-btn {
      margin-top: 1rem;
    }
  }

  &-header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    padding: 1.15rem 0;

    &-text {
      font-size: 1.75rem;
      font-weight: 700;
    }
  }

  &-top {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding-bottom: 1rem;

    &:has(.overview-pills:empty):has(.overview-top-btns:empty) {
      display: none;
    }

    &-btns {
      margin-left: auto;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: flex-end;
      gap: 0.5rem;
    }
  }

  &-wrapper {
    position: relative;
  }

  &-loading {
    height: 10rem;
    position: relative;
  }
}
</style>
