var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "overviewcontent",
      staticClass: "overview-content",
      class: { "has-sidebar": _vm.assignedReferences.length },
      style: _vm.maxHeightStyle
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "infinite-scroll",
              rawName: "v-infinite-scroll",
              value: _vm.handleInfiniteScroll,
              expression: "handleInfiniteScroll"
            }
          ],
          ref: "overviewwrapper",
          staticClass: "overview-wrapper",
          attrs: {
            "infinite-scroll-disabled": _vm.loadingOrDone,
            "infinite-scroll-distance": "100",
            "infinite-scroll-throttle-delay": "500"
          }
        },
        [
          _c(
            "div",
            { staticClass: "overview-top" },
            [
              _c("ORIOverviewPills", {
                staticClass: "overview-pills",
                attrs: { type: "reference" }
              }),
              _c(
                "div",
                { staticClass: "overview-top-btns" },
                [
                  !_vm.referencesLoading && _vm.canWriteOri
                    ? _c("Button", {
                        attrs: { text: "Manage labels", type: "white" },
                        on: { click: _vm.handleManageAttributes }
                      })
                    : _vm._e(),
                  !_vm.referencesLoading &&
                  _vm.canWriteOri &&
                  (!_vm.referencesEmpty || _vm.isSearch)
                    ? _c("Button", {
                        attrs: { text: "Add case" },
                        on: { click: _vm.openAddReference }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm.isSearch && !_vm.referencesLoading && _vm.paginatorDataCount
            ? _c("p", { staticClass: "overview-count" }, [
                _vm._v(" " + _vm._s(_vm.paginatorDataCount) + " results ")
              ])
            : _vm._e(),
          _vm.referencesLoading
            ? _c(
                "div",
                { staticClass: "overview-loading" },
                [
                  _c("b-loading", {
                    attrs: { "is-full-page": false, active: "" }
                  })
                ],
                1
              )
            : _vm.referencesEmpty
            ? _c(
                "div",
                { staticClass: "overview-empty" },
                [
                  _vm._m(0),
                  _c("p", { staticClass: "overview-empty-header" }, [
                    _vm._v(_vm._s(_vm.referencesEmptyTitle))
                  ]),
                  _c("p", { staticClass: "overview-empty-sub" }, [
                    _vm._v(_vm._s(_vm.referencesEmptySub))
                  ]),
                  _vm.canWriteOri
                    ? _c("Button", {
                        staticClass: "overview-empty-btn",
                        attrs: { text: "Add case" },
                        on: { click: _vm.openAddReference }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "overview" },
            _vm._l(_vm.references, function(reference) {
              return _c("ReferenceListItem", {
                key: reference.name,
                staticClass: "overview-item",
                attrs: {
                  reference: reference,
                  clickable: true,
                  "show-status": _vm.canWriteOri
                }
              })
            }),
            1
          ),
          _c("ORIInit", {
            attrs: { visible: _vm.showReferenceAdd, type: "reference" },
            on: {
              close: function($event) {
                _vm.showReferenceAdd = false
              }
            }
          }),
          _c(
            "b-modal",
            {
              model: {
                value: _vm.showManageAttributes,
                callback: function($$v) {
                  _vm.showManageAttributes = $$v
                },
                expression: "showManageAttributes"
              }
            },
            [_c("AttributesModal")],
            1
          )
        ],
        1
      ),
      _vm.assignedReferences.length
        ? _c("AssignedORI", {
            staticClass: "overview-sidebar",
            attrs: { items: _vm.assignedReferences, type: "references" }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "overview-empty-icon" }, [
      _c("img", {
        staticClass: "overview-empty-icon-img",
        attrs: { src: require("@/assets/icons/archive.svg"), alt: "" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }