<template>
  <div class="ori-draft-input">
    <span class="ori-draft-input-header">
      <img
        src="@/assets/icons/bootstrap.svg"
        alt=""
        class="ori-draft-input-header-icon"
      />
      <div class="ori-draft-input-header-text">
        <span class="ori-draft-input-header-title">Generate a draft</span>
        <p class="ori-draft-input-header-explanation">
          Automatically create a first draft for your {{ typeName }} based on
          existing material by selecting relevant documents, adding a link to a
          webpage or pasting any data about the {{ typeName }} as a note.
        </p>
      </div>
    </span>
    <div class="ori-draft-input-section">
      <span class="ori-draft-input-section-title">Link</span>
      <span class="ori-draft-input-section-subtitle"
        >Relevant webpages to help understand your {{ typeName }}</span
      >
      <TextInput
        v-for="(url, idx) in urls"
        :key="`urlinput-${idx}`"
        :value="url"
        placeholder="Add a url"
        :left-icon="urlIcon[idx]"
        :validate="true"
        :validation-function="() => (url ? (invalidUrl[idx] ? 0 : 1) : -1)"
        @input="(val) => handleUrlInput(val, idx)"
      />
    </div>
    <div class="ori-draft-input-section">
      <span class="ori-draft-input-section-title">Documents</span>
      <UploadDropzone
        ref="uploaddropzone"
        min-height="5rem"
        :max-size="5000"
        :max-size-for-total="true"
        :max-count="10"
        :show-delete="
          (f) =>
            !uploadingNames.includes(f.name) ||
            uploading[f.name] === 100 ||
            uploadingError.includes(f.name)
        "
        @upload="
          (f, isDrive) => (isDrive ? handleDriveUpload(f) : handleUpload(f))
        "
        @remove="removeFile"
      >
        <template #action="{ file }">
          <ProgressCircle
            v-if="
              uploadingNames.includes(file.name) &&
              !uploadingError.includes(file.name)
            "
            :progress="uploading[file.name]"
            done-icon="check-circle"
            size="xxs"
          />
          <img
            v-else
            src="@/assets/icons/check-warning.svg"
            alt=""
            class="ori-draft-input-error"
          />
        </template>
      </UploadDropzone>
    </div>
    <div class="ori-draft-input-section">
      <span class="ori-draft-input-section-title">Notes</span>
      <span class="ori-draft-input-section-subtitle"
        >Text to help understand your {{ typeName }}</span
      >
      <TextArea
        v-model="currentNote"
        :placeholder="editingNote === -1 ? 'Add a note' : 'Edit your note'"
        :button-text="noteSaveButtonText"
        :has-cancel-button="editingNote !== -1"
        @submit="handleNoteSave"
        @cancel="cancelNoteEdit"
      />
      <div class="ori-draft-input-section-notes">
        <div
          v-for="(note, idx) in notes"
          :key="idx"
          class="ori-draft-input-section-notes-note"
        >
          <Button
            icon="edit"
            class="ori-draft-input-section-notes-note-edit"
            type="white"
            size="xs"
            @click="() => handleNoteEdit(idx)"
          />
          {{ note }}
          <b-loading v-if="editingNote === idx" active :is-full-page="false" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextInput from '@c/library/TextInput.vue'
import TextArea from '@c/library/TextArea.vue'
import Button from '@c/library/Button.vue'
import UploadDropzone from '@c/library/UploadDropzone.vue'
import ProgressCircle from '@c/library/ProgressCircle.vue'
import { mapActions } from 'vuex'

export default {
  name: 'ORIDraftInput',
  components: {
    TextInput,
    TextArea,
    Button,
    UploadDropzone,
    ProgressCircle
  },
  props: {
    type: {
      type: String,
      default: 'offering',
      validator: (val) => ['offering', 'reference', 'inspiration'].includes(val)
    },
    ori: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    urls: [''],
    notes: [],
    currentNote: '',
    editingNote: -1,
    files: [],
    ifiles: [],
    uploading: {},
    uploadStates: {},
    uploadingError: [],
    uploadFinished: [],
    progressKey: 0,
    urlValidationKey: 0
  }),
  computed: {
    typeName() {
      return {
        offering: 'offering',
        reference: 'case',
        inspiration: 'inspirational content'
      }[this.type]
    },
    validUrl() {
      this.urlValidationKey
      return this.urls.map((u) => {
        let url
        try {
          url = new URL(u)
        } catch (e) {
          return false
        }
        return (
          url.href?.includes('.') &&
          (url.protocol === 'http:' || url.protocol === 'https:')
        )
      })
    },
    documentUrl() {
      this.urlValidationKey
      return this.urls.map((u) => {
        if (!u) return false
        const googleDriveRegex = /^https:\/\/drive\.google\.com\/.*/
        const googleDocsRegex = /^https:\/\/docs\.google\.com\/.*/
        const sharepointRegex = /^https:\/\/.*\.sharepoint\.com\/.*/
        return (
          googleDriveRegex.test(this.url) ||
          googleDocsRegex.test(this.url) ||
          sharepointRegex.test(this.url)
        )
      })
    },
    invalidUrl() {
      this.urlValidationKey
      return this.urls.map((u, idx) => {
        return u && (!this.validUrl[idx] || this.documentUrl[idx])
      })
    },
    urlIcon() {
      return this.urls.map((u, idx) => {
        return !u
          ? ''
          : this.invalidUrl[idx]
          ? 'close-circle-filled'
          : 'check-circle-filled'
      })
    },
    uploadingNames() {
      this.progressKey
      return Object.keys(this.uploading)
    },
    noteSaveButtonText() {
      return this.editingNote === -1 ? 'Add note' : 'Save note'
    },
    submitEnabled() {
      return (
        this.files.length === this.uploadFinished.length &&
        (this.urls.filter(
          (u, idx) => this.validUrl[idx] && !this.documentUrl[idx]
        ).length > 0 ||
          this.notes.length > 0 ||
          this.files.length > 0 ||
          this.ifiles.length > 0)
      )
    }
  },
  watch: {
    submitEnabled(val) {
      this.$emit('submitDisabled', !val)
    }
  },
  created() {
    this.$emit('submitDisabled', true)
  },
  methods: {
    ...mapActions([
      'getOfferingResourceUploadProps',
      'getReferenceResourceUploadProps',
      'getInspirationResourceUploadProps'
    ]),
    showError(e) {
      this.$console.debug('Error uploading file', e)
      this.$toast.error(e, 'uploading your file')
    },
    emitDraft(submit = false) {
      this.$emit(submit ? 'submit' : 'updateDraft', {
        webpage_urls: this.urls.filter(
          (u, idx) => this.validUrl[idx] && !this.documentUrl[idx]
        ),
        text: this.notes.join('\n'),
        files: Object.values(this.uploadStates),
        integration_file_ids: this.ifiles.map((f) => f.uuid)
      })
    },
    submit() {
      this.emitDraft(true)
    },
    uploadPropsCall(file) {
      const uploadPropFunction = {
        offering: this.getOfferingResourceUploadProps,
        reference: this.getReferenceResourceUploadProps,
        inspiration: this.getInspirationResourceUploadProps
      }[this.type]
      return uploadPropFunction({
        workspace_id: this.$route.params.workspace_id,
        ori_id: this.ori.uuid,
        name: file.name,
        mimetype: file.type,
        content_length: file.size,
        act_as: this.$route.query.act_as
      })
    },
    handleUpload(files) {
      const f = Array.from(files)
      this.files = [...this.files, ...f]
      f.forEach(async (file) => {
        this.uploadingError = this.uploadingError.filter((f) => f !== file.name)
        this.uploading[file.name] = 0
        this.progressKey++
        try {
          const { upload_url, state } = await this.uploadPropsCall(file)
          this.uploading[file.name] = 20
          this.progressKey++
          await this.uploadFile(file, upload_url)
          this.uploadStates[file.name] = state
          this.uploading[file.name] = 100
          this.uploadFinished.push(file.name)
          this.progressKey++
        } catch (e) {
          delete this.uploading[file.name]
          this.progressKey++
          this.uploadingError.push(file.name)
          this.showError(e)
        }
      })
    },
    handleDriveUpload(files) {
      files.forEach((f) => (this.uploading[f.name] = 100))
      this.ifiles = [...this.ifiles, ...files]
      this.progressKey++
    },
    async uploadFile(file, url) {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest()
        const name = file.name
        xhr.open('PUT', url, true)

        xhr.upload.onprogress = (e) => {
          var fileSize = file.size
          this.uploading[name] = 20 + Math.round((e.loaded / fileSize) * 60)
          this.progressKey++
        }

        xhr.upload.onerror = (e) => {
          delete this.uploading[file.name]
          this.progressKey++
          this.showError(e)
          reject()
        }
        xhr.setRequestHeader('Content-Type', file.type)

        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4) {
            this.uploading[name] = 95
            this.progressKey++
            resolve()
          }
        }

        xhr.send(file)
        this.progressKey++
      })
    },
    removeFile(file, isDrive = false) {
      if (isDrive) {
        const index = this.ifiles.findIndex((f) => f.name === file.name)
        delete this.uploading[file.name]
        this.ifiles.splice(index, 1)
        return
      }
      delete this.uploadStates[file.name]
      const index = this.files.findIndex((f) => f.name === file.name)
      this.files.splice(index, 1)
      this.uploadFinished = this.uploadFinished.filter((f) => f !== file.name)
    },
    handleNoteSave() {
      if (this.editingNote === -1) {
        this.notes.push(this.currentNote)
      } else {
        this.notes[this.editingNote] = this.currentNote
      }
      this.currentNote = ''
      this.editingNote = -1
      this.emitDraft()
    },
    cancelNoteEdit() {
      this.currentNote = ''
      this.editingNote = -1
    },
    handleNoteEdit(idx) {
      this.editingNote = idx
      this.currentNote = this.notes[idx]
    },
    handleUrlInput(val, idx) {
      this.urls[idx] = val
      if (idx === this.urls.length - 1 && val) {
        this.urls.push('')
      }
      this.urlValidationKey++
      this.emitDraft()
    }
  }
}
</script>

<style scoped lang="scss">
.ori-draft-input {
  display: flex;
  flex-flow: column nowrap;
  border-radius: 6px;
  border: 1px solid rgba(#000, 0.08);

  & > *:not(:last-child) {
    border-bottom: 1px solid rgba(#000, 0.08);
  }

  &-header {
    padding: 1.75rem 1.15rem;
    display: flex;
    flex-flow: row nowrap;
    gap: 0.85rem;
    align-items: center;

    &-icon {
      height: 5.5rem;
    }

    &-text {
      display: flex;
      flex-flow: column nowrap;
      gap: 0.85rem;
    }

    &-title {
      font-size: 1.25rem;
      font-weight: 700;
    }

    &-explanation {
      color: #60666b;
    }
  }

  &-error {
    height: 1.2rem;
    filter: brightness(0) saturate(100%) invert(24%) sepia(98%) saturate(5070%)
      hue-rotate(347deg) brightness(93%) contrast(113%);
  }

  &-section {
    padding: 1.35rem 1.15rem;
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    gap: 0.5rem;

    &-title {
      font-weight: 600;
    }

    &-subtitle {
      color: #60666b;
    }

    &-notes {
      display: flex;
      flex-flow: column nowrap;
      gap: 0.5rem;
      padding: 0.5rem 0;

      &-note {
        position: relative;
        padding: 1rem;
        border-radius: 6px;
        border: 1px solid rgba(#000, 0.08);
        background: rgba(#000, 0.02);

        &:hover {
          background: rgba(#000, 0.04);

          & .ori-draft-input-section-notes-note-edit {
            opacity: 1;
          }
        }

        &-edit {
          position: absolute;
          right: 0.5rem;
          top: 0.5rem;
          opacity: 0;
          transition: opacity 0.15s ease-in-out;
        }
      }
    }
  }
}

.filter-green {
  &::v-deep img {
    filter: invert(77%) sepia(18%) saturate(3433%) hue-rotate(96deg)
      brightness(94%) contrast(72%);
  }
}

.filter-red {
  &::v-deep img {
    filter: invert(24%) sepia(98%) saturate(5070%) hue-rotate(347deg)
      brightness(93%) contrast(113%);
  }
}
</style>
