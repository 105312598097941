var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "oridetail",
      staticClass: "detail",
      class: { padded: !(_vm.isCreate || _vm.isEdit) },
      style: !(_vm.isCreate || _vm.isEdit) ? _vm.maxHeightStyle : {}
    },
    [
      !_vm.oriLoading && _vm.ori.name && !(_vm.isCreate || _vm.isEdit)
        ? _c("ORI", {
            key: _vm.oriKey,
            attrs: {
              ori: _vm.ori,
              type: _vm.type,
              "key-mapping": _vm.isOffering
                ? {
                    subofferings: "offerings",
                    inspirations: "inspiration"
                  }
                : {},
              "title-mapping": _vm.isOffering
                ? {
                    offerings: "Subofferings"
                  }
                : {},
              "attribute-edit-loading": _vm.attributeEditLoading
            },
            on: {
              linkOffering: _vm.handleLinkOffering,
              removeOffering: _vm.handleRemoveOffering,
              linkReference: function() {
                return (_vm.showORILink = "reference")
              },
              removeReference: _vm.handleRemoveReference,
              linkInspiration: function() {
                return (_vm.showORILink = "inspiration")
              },
              removeInspiration: _vm.handleRemoveInspiration,
              linkResource: function() {
                return (_vm.showResourceModal = true)
              },
              removeResource: _vm.handleRemoveResource,
              regenerateResource: _vm.handleRegenerateResource,
              offeringOrderChange: _vm.handleEditOfferingPosition,
              resourceOrderChange: _vm.handleEditResourcePosition,
              editAttributes: _vm.handleEditAttributes,
              editResourceAttributes: _vm.handleEditResourceAttributes,
              remove: function($event) {
                _vm.showOriDeleteModal = true
              },
              refresh: function() {
                return _vm.loadOri(true)
              },
              edit: function(o) {
                return (_vm.ori = o)
              },
              publish: function(o) {
                return (_vm.ori = o)
              },
              publishResource: _vm.handlePublishResource
            }
          })
        : _vm._e(),
      _vm.isCreate || _vm.isEdit
        ? _c("ORIActions", {
            attrs: { item: _vm.ori, type: _vm.type },
            on: {
              create: function(o) {
                return (_vm.ori = o)
              },
              edit: function(o) {
                return (_vm.ori = o)
              }
            }
          })
        : _vm._e(),
      !!_vm.showORICreate
        ? _c("ORIInit", {
            attrs: {
              visible: !!_vm.showORICreate,
              type: _vm.showORICreate,
              parent:
                _vm.showORICreate === "offering"
                  ? Object.assign({}, _vm.ori, { type: "offering" })
                  : undefined,
              link:
                _vm.showORICreate === "offering"
                  ? undefined
                  : { offering_ids: [_vm.ori.uuid] }
            },
            on: {
              close: function() {
                return (_vm.showORICreate = "")
              }
            }
          })
        : _vm._e(),
      _vm.oriLoading
        ? _c(
            "div",
            { staticClass: "detail-loading" },
            [_c("b-loading", { attrs: { "is-full-page": false, active: "" } })],
            1
          )
        : _vm._e(),
      _c(
        "b-modal",
        {
          model: {
            value: _vm.showResourceModal,
            callback: function($$v) {
              _vm.showResourceModal = $$v
            },
            expression: "showResourceModal"
          }
        },
        [
          _vm.useResourceUploadModal
            ? _c("ORIUploadResourceModal", {
                attrs: {
                  visible: _vm.showResourceModal,
                  "props-call": _vm.uploadContentProps,
                  callback: _vm.uploadContentCallback,
                  "max-size": 5000
                },
                on: {
                  close: function($event) {
                    _vm.showResourceModal = false
                  },
                  done: _vm.finishContentUpload
                }
              })
            : _c("AddResourceToORIModal", {
                attrs: { ori: _vm.ori, type: _vm.type },
                on: {
                  close: function($event) {
                    _vm.showResourceModal = false
                  },
                  add: _vm.handleAddContent
                }
              })
        ],
        1
      ),
      !!_vm.showORILink
        ? _c("LinkORIModal", {
            attrs: {
              item: _vm.ori,
              type: _vm.type,
              "link-type": _vm.showORILink,
              visible: !!_vm.showORILink,
              selected: _vm.linkSelected
            },
            on: {
              close: function(items) {
                return _vm.handleCloseLinkingModal(_vm.showORILink, items)
              },
              createNew: _vm.handleCreateNew
            }
          })
        : _vm._e(),
      _c(
        "b-modal",
        {
          model: {
            value: _vm.showOriDeleteModal,
            callback: function($$v) {
              _vm.showOriDeleteModal = $$v
            },
            expression: "showOriDeleteModal"
          }
        },
        [
          _c("RemoveORIModal", {
            attrs: { name: _vm.ori.name, type: _vm.type, uuid: _vm.ori.uuid },
            on: {
              close: function($event) {
                _vm.showOriDeleteModal = false
              },
              remove: _vm.handleRemoveOri
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }